import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import MVBLink from "../../../component/MVBLink";
import SubNavigationBar from "../../../component/SubNavigationBar";
import SubNavigationBarLayout1 from "../../../component/SubNavigationBarLayout1";
import ContentTypeEnum from "../../../enum/ContentTypeEnum";


const Items = ({ payload, items, onClick }) => {
    console.log("NavigationBarLayout1.Item: ", payload);

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    function isMenuActive ( value, categoryId, pageId ) {
        if ( value.contentTypeName === ContentTypeEnum.CATEGORY ) {
            return value.contentId === categoryId;
        } else if ( value.contentTypeName === ContentTypeEnum.PAGE ) {
            return value.contentId === pageId;
        }
    }

    return (
        <React.Fragment>
            <nav id={"top-bar__navigation"} className={"top-bar__navigation navigation"} role={"navigation"}>
                <ul>
                    {
                        items.map( item => {
                            const _items = payload.filter(x=>x.parentId === item.id);
                            const _isActive = isMenuActive(item, categoryId, pageId);
                            return (
                                <li key={item.id} className={`${_items.length>0?"has-submenu":''} ${_isActive?"active":''}`}>
                                    <MVBLink
                                        title={item?.languageVersion?.name}
                                        isNavigationDestination={item.isNavigationDestination}
                                        redirectUrl={item?.languageVersion?.redirectUrl}

                                        redirectType={item?.redirectType}

                                        languageCode={language}
                                        
                                        categoryId={item?.categoryId}
                                        categoryRouteName={item?.categoryRouteName}
                                        contentId={item?.contentId}
                                        contentRouteName={item?.languageVersion?.routeName}
                                        contentTypeName={item?.contentTypeName}

                                        isMatchActive={false}
                                        onClick={onClick}
                                    />
                                    <SubNavigationBar Component={SubNavigationBarLayout1} payload={payload} items={_items} language={language} onClick={onClick}/>
                                </li>
                            );
                        })
                    }
                </ul>
            </nav>
        </React.Fragment>
    );
}

Items.propTypes = {
    payload: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
}

Items.defaultProps = {
    payload: [],
    items: [],
}

export default Items;