
const monthNames = {
    "en": [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
    "tr": [ "Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara" ],
    "ru": [ "Янв", "Фев", "Мар", "Апр", "May", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек" ]
}

export default ( value, languageCode ) => {
    if ( !value ) return null;

    const _monthNames = languageCode && monthNames[languageCode] ? monthNames[languageCode] : monthNames["en"];
    const _date = (value.split("T")[0]).split("-");
    
    return {
        day: _date[2],
        month: _date[1],
        monthName: _monthNames[_date[1]-1],
        year: _date[0]
    }
}