import {default as _ClientCategoriesAction}  from "../../../cms/action/service/ClientCategoriesAction";

export { name }  from "../../../cms/action/service/ClientCategoriesAction";
const pageName = "PAGE"

export default class ClientCategoriesAction extends _ClientCategoriesAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}