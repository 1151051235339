import { combineReducers } from 'redux';
import ReducerBase from "../../../core/reducer/ReducerBase";
import { name as serviceName } from '../../service/ClientMenuItemService'


export default function ClientMenuItemsReducer( pageName ) {
    const baseReducer = new ReducerBase(pageName, serviceName);

    return combineReducers({
        ...baseReducer.LIST()
    });
}
