import {default as _ClientMainPageItemsAction}  from "../../../action/service/ClientMainPageItemsAction";

export { name }  from "../../../action/service/ClientMainPageItemsAction";
const pageName = "MNPG_003"

export default class ClientMainPageItemsAction extends _ClientMainPageItemsAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}