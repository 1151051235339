import React, {useEffect} from 'react';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import Lodash from 'lodash';
import MVBNavLink from "../../../component/MVBNavLink";


const Items = ({ payload, onClick }) => {

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    return (
        <React.Fragment>
            <div className={"top-bar__contacts"}>
                {
                    payload.map(item => (
                        <span key={item.id}>
                            <MVBNavLink
                                title={item.name}

                                languageCode={item.twoLetterISOName}
                                categoryRouteName={categoryName}
                                contentRouteName={pageName}
                                categoryId={categoryId}
                                contentId={pageId}

                                isNavigationDestination={true}
                                isMatchActive={true}
                                redirectType={"StayAtCurrentWebSite"}

                                activeClassName={'font-weight-bold'}
                                onClick={onClick}
                            />
                        </span>
                    ))
                }
            </div>
        </React.Fragment>
    );
}

Items.propTypes = {
    payload: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
}

Items.defaultProps = {
    payload: [],
    onClick: () => {}
}

export default Items;