const ResourceTypeEnum = {

    IMAGE: "Image",
    OUTER_IMAGE: "OutterImage",
    INNER_IMAGE: "InnerImage",
    DATA: "Data",
    PARALLAX: "Parallax"

}

export default ResourceTypeEnum;