import {default as _ClientContentAction}  from "../../../action/service/ClientContentAction";

export { name }  from "../../../action/service/ClientContentAction";
const pageName = "PAGE"

export default class ClientContentAction extends _ClientContentAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}