import React from 'react';
import MVBLink from "../../../component/MVBLink";
import {CoreDefault} from "../../../../core/CoreDefault";
import ResourceTypeEnum from "../../../enum/ResourceTypeEnum";
import {MVBImage} from "../../../component";

const Content = ({ leftContent, rightContent, resources, language, languageVersion, categoryId, categoryRouteName, contentId, redirectType, isNavigationDestination, verboseResources }) => {
    const readMoretext = verboseResources?.find(item => item.key === "ShowAll")?.value;

    return (
        <React.Fragment>
            <div className="d-none d-lg-block">
                <img id="bg-img-1" className="img-fluid" src={"assets/img/eco-rcc.png"} alt="ECO-RCC"/>
            </div>

            <div className="container">
                <div className="row align-items-center">

                    <div className="col-12 col-lg-4">
                        <div>
                            <div className="section-heading">
                                <div className={"ck-content"} dangerouslySetInnerHTML={{__html: leftContent}}/>
                            </div>

                            <p className="d-none d-sm-block">
                                <MVBLink
                                    title={readMoretext}
                                    isNavigationDestination={isNavigationDestination}
                                    redirectUrl={languageVersion?.redirectUrl}

                                    redirectType={redirectType}

                                    languageCode={language}
                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}
                                    contentId={contentId}
                                    contentRouteName={languageVersion?.routeName}
                                    className={"custom-btn custom-btn--small custom-btn--style-1"}
                                />
                            </p>
                        </div>
                    </div>

                    <div className="col-12 my-3 d-lg-none"/>

                    <div className="col-12 col-lg-4  text-center">
                        <div>
                            <MVBImage className="img-fluid" payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.IMAGE} />
                        </div>
                    </div>

                    <div className="col-12 my-3 d-lg-none"/>

                    <div className="col-12 col-lg-4">
                        <div className={"ck-content"} dangerouslySetInnerHTML={{__html: rightContent}}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Content;