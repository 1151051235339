import React from "react";
import PropTypes from "prop-types";


const HomeSection = ({children, accessory, backgroundColor}) => (
    <React.Fragment>
        <section className={`start-screen-1 section section--${backgroundColor}-bg`} data-scroll-discover="true">
            <div className="container">
                { children }
            </div>
            { accessory }
        </section>
    </React.Fragment>
);

export default HomeSection;

HomeSection.propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.element.isRequired,
    accessory: PropTypes.element
}

HomeSection.defaultProps = {
    backgroundColor: "gray",
    children: null,
    accessory: null
}