import React, {useCallback, useEffect, useState, useReducer} from "react";
import { connect } from "react-redux";
import Lodash from 'lodash';
// layouts
// action
import ContactUsAction, { name as contactUsName } from "./actions/ContactUsAction";
// reducer
import reducer, { initState, initializer } from '../../../core/reducer/SimpleReducer';

function MVBContactForm({ labelSend, labelSending, labelMessageHasBeenSendSuccessfully, labelGetInTouch, labelNameSurname, labelEmail, labelTitle, labelDescription }) {
    console.log("MVBContactForm.rendered");

    // contact us
    const [contactUsState, contactUsDispatch] = useReducer(new reducer("ContactUs", contactUsName), {...initState, post: {...initState.post, isLoading: false}}, initializer);
    const contactUsAction = new ContactUsAction(contactUsDispatch);
    
    const [ disabled, setDisabled ] = useState(false);
    const [ formData, setFormData ] = useState({
        nameSurname: {value: "", errorMessage: null},
        email: {value: "", errorMessage: null},
        message: {value: "", errorMessage: null},
        title: {value: "", errorMessage: null}
    });
    
    const sendMessage = useCallback(
        (e) => {
            e.preventDefault();

            contactUsAction.post({
                serviceData: {
                    nameSurname: formData.nameSurname.value,
                    email: formData.email.value,
                    message: formData.message.value,
                    title: formData.title.value,
                }
            });
        },
        [ formData ]
    );

    useEffect(
        () => {
            console.log("MVBContactForm.useEffect.1", contactUsState.post);
            
            if ( !Lodash.isEmpty(contactUsState?.post?.error) ) {
                const nameSurname = contactUsState?.post?.error?.find(item => item.propertyName === "nameSurname")?.errorMessage;
                const email = contactUsState?.post?.error?.find(item => item.propertyName === "email")?.errorMessage;
                const message = contactUsState?.post?.error?.find(item => item.propertyName === "message")?.errorMessage;
                const title = contactUsState?.post?.error?.find(item => item.propertyName === "title")?.errorMessage;
                
                console.log("ttt: ", {nameSurname, email, message, title});
    
                setFormData(prevState => ({
                    nameSurname: { value: prevState.nameSurname.value, errorMessage: nameSurname },
                    email: { value: prevState.email.value, errorMessage: email },
                    message: { value: prevState.message.value, errorMessage: message },
                    title: { value: prevState.title.value, errorMessage: title }
                }));
                
                contactUsAction.cleanPost();   
            }
        },
        [ contactUsState.post.isLoading ]
    )

    return (
        <div className="container">
            <div className="row justify-content-end">
                <div className="col-12 col-md-6">

                    <div className="row justify-content-end">
                        <div className="col-md-11">
                            <div className="section-heading section-heading--white">
                                <h2 className="__title"><span>{labelGetInTouch}</span></h2>
                            </div>
                            <form className="contact-form js-contact-form" onSubmit={sendMessage} >
                                <div className="input-wrp">
                                    <input className="textfield"
                                           name="name"
                                           type="text"
                                           placeholder={labelNameSurname}
                                           disabled={disabled}
                                           onChange={(e) => { 
                                               setFormData(prevState => ({ ...prevState, nameSurname: {value: e.target.value, errorMessage: null} })) 
                                           }}
                                    />
                                    {
                                        formData.nameSurname.errorMessage ? 
                                            <p className={"text-danger"}>{ formData.nameSurname.errorMessage }</p> : null
                                    }
                                </div>

                                <div className="input-wrp">
                                    <input className="textfield"
                                           name="email"
                                           type="text"
                                           placeholder={labelEmail}
                                           disabled={disabled}
                                           onChange={(e) => { 
                                                setFormData(prevState => ({ ...prevState, email: {value: e.target.value, errorMessage: null} })) 
                                           }}
                                    />
                                    {
                                        formData.email.errorMessage ? 
                                            <p className={"text-danger"}>{ formData.email.errorMessage }</p> : null
                                    }
                                </div>

                                <div className="input-wrp">
                                    <input className="textfield"
                                           name="title"
                                           type="text"
                                           placeholder={labelTitle}
                                           disabled={disabled}
                                           onChange={(e) => { 
                                                setFormData(prevState => ({ ...prevState, title: {value: e.target.value, errorMessage: null} })) 
                                           }}
                                    />
                                    {
                                        formData.title.errorMessage ? 
                                            <p className={"text-danger"}>{ formData.title.errorMessage }</p> : null
                                    }
                                </div>

                                <div className="input-wrp">
                                    <textarea className="textfield"
                                              name="message"
                                              placeholder={labelDescription}
                                              disabled={disabled}
                                              onChange={(e) => { 
                                                setFormData(prevState => ({ ...prevState, message: {value: e.target.value, errorMessage: null} })) 
                                              }}
                                    />
                                    {
                                        formData.message.errorMessage ? 
                                            <p className={"text-danger"}>{ formData.message.errorMessage }</p> : null
                                    }
                                </div>

                                <button className="custom-btn custom-btn--medium custom-btn--style-3 wide" type="submit" role="button" disabled={contactUsState.post.isLoading || !Lodash.isEmpty(contactUsState.post.payload?.id)}>
                                    { !Lodash.isEmpty(contactUsState.post.payload?.id) ? labelMessageHasBeenSendSuccessfully : "" }
                                    { Lodash.isEmpty(contactUsState.post.payload?.id) ? contactUsState.post.isLoading ? `${labelSending}...` : labelSend : "" }
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}


const mapStateToProps = function (state, props) {
    console.log("MVBContactForm.mapStateToProps", state, props);

    const labelSend = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "Send")?.value;
    const labelSending = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "Sending")?.value;
    const labelMessageHasBeenSendSuccessfully = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "MessageHasBeenSendSuccessfully")?.value;

    const labelGetInTouch = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "GetInTouch")?.value;
    const labelNameSurname = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "NameSurname")?.value;
    const labelEmail = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "Email")?.value;
    const labelTitle = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "Title")?.value;
    const labelDescription = state.CoreReducer.VerboseResourcesReducer.list?.payload?.find(item => item.key === "Description")?.value;

    return {
        labelSend,
        labelSending,
        labelMessageHasBeenSendSuccessfully,

        labelGetInTouch,
        labelNameSurname,
        labelEmail,
        labelTitle,
        labelDescription
    }
}

const Component = React.memo(MVBContactForm, (prevProps, nextProps) => {
    console.log("---- MVBContactForm.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    console.log("MVBContactForm.doRender");
    return false;
});

export default connect(mapStateToProps, null)(Component);