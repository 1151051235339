import React from 'react';

const NavigationBarLayout2 = ( props ) => {

    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default NavigationBarLayout2;