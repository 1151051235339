import * as ReactGA from "react-ga";

class IAnalytics {
    constructor() {
        this.isActive = false;
    }
    init(config) {}
    sendWebVitalsData(metric) {}
    sendPageView(route) {}
}

class AnalyticsExtension {

    constructor() {
        this.googleAnalytics = new GoogleAnalytics();
        this.apiAnalytics = new ApiAnalytics();
        this.serializeAnalytics = new SerializeAnalytics();
    }

    initGoogleAnalytics(config) {
        this.googleAnalytics.init(config);
    }

    initApiAnalytics(config) {
        this.apiAnalytics.init(config)
    }

    initSerializeAnalytics(config) {
        this.serializeAnalytics.init(config)
    }

    sendWebVitals(metric) {
        this.googleAnalytics.sendWebVitalsData(metric);
        this.apiAnalytics.sendWebVitalsData(metric);
        this.serializeAnalytics.sendWebVitalsData(metric);
    }

    sendPageView(route) {
        this.googleAnalytics.sendPageView(route);
        this.apiAnalytics.sendPageView(route);
        this.serializeAnalytics.sendPageView(route);
    }

    send(webVitalMetric, route) {
        this.sendWebVitals(webVitalMetric);
        this.sendPageView(route);
    }

}

class GoogleAnalytics extends IAnalytics {

    init(config) {
        super.init(config);
        ReactGA.initialize(JSON.parse(config));
        this.isActive = true;
    }

    sendPageView(route) {
        super.sendPageView(route);
        if ( this.isActive ) {
            ReactGA.pageview(route);
        }
    }

    sendWebVitalsData(metric) {
        super.sendWebVitalsData(metric);
        if ( this.isActive && metric !== null ) {
            ReactGA.event({
                eventCategory: 'Web Vitals',
                eventAction: metric.name,
                eventValue: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
                eventLabel: metric.id, // id unique to current page load
                nonInteraction: true, // avoids affecting bounce rate
            });
        }
    }

}

class ApiAnalytics extends IAnalytics {

    init(config) {
        super.init(config);
    }

    sendWebVitalsData(metric) {
        super.sendWebVitalsData(metric);
    }

    sendPageView(route) {
        super.sendPageView(route);
    }

}

class SerializeAnalytics extends IAnalytics {

    init(config) {
        super.init(config);
        this.isActive = true;
    }

    sendWebVitalsData(metric) {
        super.sendWebVitalsData(metric);
        console.table(JSON.parse(JSON.stringify(metric)));
    }

    sendPageView(route) {
        super.sendPageView(route);
        console.info(`Sent analytics for \"${route}\"`)
    }

}

export default new AnalyticsExtension();