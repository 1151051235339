import React from 'react';
import MVBLink from "../../../component/MVBLink";
// highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapDataAsia from '@highcharts/map-collection/custom/asia.geo.json';
require("highcharts/modules/map")(Highcharts);

// static
const contry_links = {
    "tr": null,
    "ir": null,
    "az": null,
    "kz": null,
    "kg": null,
    "pk": null,
    "tj": null,
    "tm": null,
    "uz": null,
    "af": null
}
const mapOptions = {
    chart: {
        map: 'custom/asia',
        spacingBottom: 20,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: "#e5e5e5",
        borderWidth: 1,
        height: "600px"
    },

    title: {
        text: '',
        style: {
            display: 'none'
        }
    },
    subtitle: {
        text: '',
        style: {
            display: 'none'
        }
    },

    legend: {
        enabled: false
    },
    mapNavigation: {
        enabled: true,
        enableDoubleClickZoomTo: true
    },

    plotOptions: {
        map: {
            allAreas: false,
            // joinBy: ['iso-a2', 'code'],
            dataLabels: {
                enabled: true,
                color: "#000",
                style: {
                    textOutline: 0
                },
                // Only show dataLabels for areas with high label rank
                format: null,
                formatter: function () {
                    if (this.point.properties) {
                        return this.point.properties['name'];
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}'
            }
        },
        series: {
            events: {
                click: function (e) {
                    if ( e.point.options.value["url"] ) {
                        window.location.href = e.point.options.value["url"];
                    }
                }
            }
        }
    },

    series: [{
        mapData: mapDataAsia,
        name: 'ECO-RCC',
        color: '#fcdb5a',
        data: ['tr', 'ir', 'az', 'kz', 'kg', 'pk', 'tj', 'tm', 'uz', 'af'].map(function (code) {
            return [code, {url: contry_links[code]}];
        })
    }]

};

const Content = ({ categoryId, categoryRouteName, contentId, isNavigationDestination, redirectType, language, languageVersion }) => (
    <React.Fragment>
         <MVBLink
            isNavigationDestination={isNavigationDestination}
            redirectUrl={languageVersion?.redirectUrl}

            redirectType={redirectType}

            languageCode={language}
            categoryId={contentId}
            categoryRouteName={languageVersion?.routeName}
         >
             <div className="section-heading section-heading--left ck-content" dangerouslySetInnerHTML={{__html: `${languageVersion?.description}`}} />
         </MVBLink>

         <div>
             <HighchartsReact
                 options={mapOptions}
                 constructorType={"mapChart"}
                 highcharts={Highcharts}
             />
         </div>
    </React.Fragment>
);

export default Content;