import { EndpointRoute } from './EndpointRoute';

export const CoreDefault = {

    endpointRoutes: EndpointRoute,
    acceptableHttpStatus: [200, 201, 400, 401, 406, 500],
    fetchSettings: {
        language: 'en-US',
        protocol: 'odata'
    },
    googleMaps: {
        apiKey: "AIzaSyC18L1VDXVL4bVUvaEGCnfCpFZ64oLKKsM",
        defaultZoom: 16,
        defaultCenter: {
            lat: 39.906001,
            long: 32.748110
        }
    },
    defaultRoutes: {
        home: ["/", "/index", "/home"],
        error: ["404"]
    },
    tokenKeyName: "ecorccfsToken"
};
