import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Lodash from "lodash";
// actions

function Error (props) {

    useEffect (
        () => {
            console.log("App.useEffect.1:", props);

            return function cleanup() {

            }
        },
        []
    );

    return (
        <React.Fragment>
            <div
                id={"error-screen"}
                className={`align-self-center my-auto`}>
                <div className={"container"}>
                    <div className={"logo"} />
                    <div>
                        <h1>404</h1>
                        <h3>Oops! Page Not Be Found</h3>
                        <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                        <Link to={`/${props.currentLanguage?.twoLetterISOName}`}>Back to homepage</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapStateToProps = function (state) {
    console.log("Error.mapStateToProps: ", state.CoreReducer.LanguageReducer);

    const languages = state.CoreReducer.LanguageReducer.list.payload;

    // user local language resources
    const languageFromLocalStorage = localStorage.getItem('accept-language');
    const browserLanguage = navigator.language?.slice(0,2);

    let currentLanguage = null;
    // language from local storage ( user selected )
    if ( Lodash.isEmpty(currentLanguage) && !Lodash.isEmpty(languageFromLocalStorage) ) {
        currentLanguage = languages?.find(x => x.twoLetterISOName === currentLanguage);
    }
    // language from browser ( browser default )
    if ( Lodash.isEmpty(currentLanguage) && !Lodash.isEmpty(browserLanguage) ) {
        currentLanguage = languages?.find(x => x.twoLetterISOName === browserLanguage);
    }
    // all language resources fail ( default )
    if ( Lodash.isEmpty(currentLanguage) ) {
        currentLanguage = Lodash.get(languages,"[0]");
    }

    return {
        currentLanguage
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("Error.mapDispatchToProps");

    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);