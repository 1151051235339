import React, {useEffect, useReducer} from "react";
import {useParams} from "react-router-dom";
import Lodash from "lodash";
// component
import Loader from "./components/loader";
import Content from "./components/content";
// actions
import ClientMainPageItemsAction, { name as mainPageItemsServiceName } from "./actions/ClientMainPageItemsAction";
// reducers
import reducer, {initializer, initState} from "../../../core/reducer/SimpleReducer";

export default function MNPG_005({ index, id, verboseResources, currentLanguage }) {
    console.log("MNPG_005.rendered");

    // constants
    const { language } = useParams();

    // mainPageItems
    const [mainPageItemsState, mainPageItemsDispatch] = useReducer(new reducer("MNPG_005", mainPageItemsServiceName), initState, initializer);
    const mainPageItemsAction = new ClientMainPageItemsAction(mainPageItemsDispatch);

    const removeUnnecessaryLine = function () {

        const interval = setInterval(() => {
            const element = document.getElementsByClassName("highcharts-credits");
            if ( element?.length > 0 ) {
                element[0].remove();
                clearInterval(interval);
            }
        }, 500);

    }

    useEffect(
        () => {
            console.log("MNPG_005.useEffect.1: ");
            if ( !Lodash.isEmpty(id) ) {
                mainPageItemsAction.details({ serviceData: id });
            }

            removeUnnecessaryLine();

            return function cleanup() {

            }
        },
        [ currentLanguage ]
    )

    return (
        <React.Fragment>
            {
                mainPageItemsState.details?.isLoading ?
                    <Loader isDesktop={true} isDarkMode={index%2===1} /> :
                    <Content
                        {...mainPageItemsState.details?.payload}
                        language={language}
                        verboseResources={verboseResources}
                    />
            }
        </React.Fragment>
    );
}


// import React, {useEffect, useState} from 'react';
// import {connect} from "react-redux";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
//
// // load Highcharts data
// import mapDataAsia from '@highcharts/map-collection/custom/asia.geo.json'
// // Load Highcharts modules
// require("highcharts/modules/map")(Highcharts);
// // static
// const contry_links = {
//     "tr": null,
//     "ir": null,
//     "az": null,
//     "kz": null,
//     "kg": null,
//     "pk": null,
//     "tj": null,
//     "tm": null,
//     "uz": null,
//     "af": null
// }
// const mapOptions = {
//     chart: {
//         map: 'custom/asia',
//         spacingBottom: 20,
//         backgroundColor: 'rgba(0,0,0,0)',
//         borderColor: "#e5e5e5",
//         borderWidth: 1,
//         height: "600px"
//     },
//
//     title: {
//         text: '',
//         style: {
//             display: 'none'
//         }
//     },
//     subtitle: {
//         text: '',
//         style: {
//             display: 'none'
//         }
//     },
//
//     legend: {
//         enabled: false
//     },
//     mapNavigation: {
//         enabled: true,
//         enableDoubleClickZoomTo: true
//     },
//
//     plotOptions: {
//         map: {
//             allAreas: false,
//             // joinBy: ['iso-a2', 'code'],
//             dataLabels: {
//                 enabled: true,
//                 color: "#000",
//                 style: {
//                     textOutline: 0
//                 },
//                 // Only show dataLabels for areas with high label rank
//                 format: null,
//                 formatter: function () {
//                     if (this.point.properties) {
//                         return this.point.properties['name'];
//                     }
//                 }
//             },
//             tooltip: {
//                 headerFormat: '',
//                 pointFormat: '{point.name}'
//             }
//         },
//         series: {
//             events: {
//                 click: function (e) {
//                     if ( e.point.options.value["url"] ) {
//                         window.location.href = e.point.options.value["url"];
//                     }
//                 }
//             }
//         }
//     },
//
//     series: [{
//         mapData: mapDataAsia,
//         name: 'ECO-RCC',
//         color: '#fcdb5a',
//         data: ['tr', 'ir', 'az', 'kz', 'kg', 'pk', 'tj', 'tm', 'uz', 'af'].map(function (code) {
//             return [code, {url: contry_links[code]}];
//         })
//     }]
//
// };
//
// const removeUnnecessaryLine = function () {
//
//     const interval = setInterval(() => {
//         const element = document.getElementsByClassName("highcharts-credits");
//         if ( element?.length > 0 ) {
//             element[0].remove();
//             clearInterval(interval);
//         }
//     }, 500);
//
// }
//
// const MNPG_005 = ( props ) => {
//     console.log("MNPG_005.rendered");
//
//     let itemsPerRow = 3;
//     let itemCount = 0;
//     const [ loading, setLoading ] = useState(true);
//
//     useEffect(
//         () => {
//             removeUnnecessaryLine();
//         },
//         []
//     );
//
//     return (
//         <section className={`section ${props.layoutStyle}`}>
//             <div className="container">
//                 <div className="section-heading section-heading--left ck-content" dangerouslySetInnerHTML={{__html: `${props.layoutTitle}${props.layoutBody}`}} />
//
//                 <div>
//                     <HighchartsReact
//                         options={mapOptions}
//                         constructorType={"mapChart"}
//                         highcharts={Highcharts}
//                     />
//                 </div>
//             </div>
//
//         </section>
//     );
//
// }
//
//
// const mapStateToProps = function (state, props) {
//     console.log("MNPG_004.mapStateToProps", props.componentKey);
//
//     let layoutStyle = props.style ? 'section--gray-bg' : '';
//     let layoutTitle = props?.layoutDataSource?.languageVersion?.description || "";
//     let layoutBody = props?.layoutDataSource?.languageVersion?.body || "";
//     let layoutLink = "#";
//
//     let dataSource = null;
//
//     return {
//         layoutStyle,
//         layoutTitle,
//         layoutBody,
//         layoutLink,
//
//         dataSource,
//     }
// }
//
//
// const Component = React.memo(MNPG_005, (prevProps, nextProps) => {
//     console.log("---- React.memo.MNPG_005 " + nextProps.componentKey + " ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);
//
//     console.log("doNotRender.MNPG_005");
//     return true;
// });
//
// export default connect(mapStateToProps, null)(Component);