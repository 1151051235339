import Axios            from 'axios';
import { CoreDefault } from "./CoreDefault";
import Cookies from "universal-cookie";

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const cookies = new Cookies();

/**
 * Request header composer
 * @param params { Object }
 * @param params.url { string }
 * @param params.method {string}
 * @param params.language {string}
 * @param params.contentType {string|null}
 * @returns {Object}
 */
let requestInit = (params) => {
    return {
        url: params.url,
        method: params.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': params.contentType || 'application/json',
            'Accept-Language': params.language || CoreDefault.fetchSettings.language,
        },
        responseType: 'json',
        responseEncoding: 'utf8',
        validateStatus: function (status) {
            return CoreDefault.acceptableHttpStatus.indexOf(status) > -1;
        }
    }
}

/**
 *
 * @param baseUrl { string }
 * @param queryOptions { OdataQueryOptions|null }
 * @return { string }
 */
function OdataQueryComposer( baseUrl, queryOptions ) {
    if ( queryOptions == null ) return baseUrl;

    const queryList = [];
    let url = baseUrl;

    if (queryOptions?.filter?.length > 0) {
        queryList.push(`$filter=${queryOptions.filter.join(' ')}`);
    }
    if (queryOptions?.skip >= 0) {
        queryList.push(`$skip=${queryOptions.skip}`);
    }
    if (queryOptions?.top >= 0) {
        queryList.push(`$top=${queryOptions.top}`);
    }
    if (queryOptions?.orderBy?.length > 0) {
        queryList.push(`$orderby=${queryOptions.orderBy.join(',')}`);
    }
    if (queryOptions?.expands?.length > 0) {
        queryList.push(`$expand=${queryOptions.expands.join(',')}`);
    }

    if (queryList.length > 0) {
        url = `${baseUrl}?${queryList.join('&')}`;
    }

    return url;
}

/**
 * Api Request Sender
 * @param params { Object }
 * @param params.method {string}
 * @param params.sourcePath {string}
 * @param params.token {string|null}
 * @param params.body {Object|null}
 * @param params.formData {Object|null}
 * @param params.contentType {string|null}
 * @param params.queryOptions {OdataQueryOptions|null}
 * @param params.language {string}
 * @param params.protocol {string}
 */
export async function ApiFetch ( params ) {
    // console.log("ApiFetch.params-1: ", params);

    // console.log("process.env: ", process.env);

    const baseUrl = `${CoreDefault.endpointRoutes.api}/${params.protocol||CoreDefault.fetchSettings.protocol}/${params.sourcePath}`;
    const url = OdataQueryComposer(baseUrl, params.queryOptions);

    const token = cookies.get(CoreDefault.tokenKeyName, { path: "/" });

    params["language"] = localStorage.getItem("accept-language-code");

    let _requestInit = requestInit( { ...params, url, language: params.language||CoreDefault.fetchSettings.language } );
    // Authorization operations
    if ( token ) { _requestInit['headers']['Authorization'] = `Bearer ${ token }`; }
    // Patch and Post operations
    _requestInit['data'] = params.formData ? params.formData : JSON.stringify(params.body);

    // -- debug
    // console.log("ApiFetch.params-2: ", params);
    // console.log("ApiFetch.requestInit: ", _requestInit);

    // await sleep(3000);

    return Axios( _requestInit );
}

/**
 * Api Request Sender
 * @param params { Object }
 * @param params.url {string}
 * @param params.method {string}
 */
export async function Download ( params ) {
    // console.log("Download.params: ", params);

    // await sleep(5000);

    return Axios({
        url: params.url,
        method: params.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': params.contentType || 'application/json',
            'Accept-Language': params.language || CoreDefault.fetchSettings.language
        },
        responseType: 'json',
        responseEncoding: 'utf8',
        validateStatus: function (status) {
            return CoreDefault.acceptableHttpStatus.indexOf(status) > -1;
        }
    });
}
