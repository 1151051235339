import React from 'react';
import {connect} from "react-redux";

const Footer_002 = (props) => (
    <React.Fragment>

    </React.Fragment>
);

const mapStateToProps = function (state, props) {
    console.log("Footer_002.mapStateToProps: ", state.CMSReducer);

    return {

    }
}

const Component = React.memo(Footer_002, (prevProps, nextProps) => {
    console.log("---- Footer_002.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    console.log("Footer_002.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);