import { combineReducers } from 'redux';

// Store
import LanguageReducer from '../service/language/LanguageReducer';

// Static
const pageName = "APP";

export default {
    LanguageReducer: new LanguageReducer(pageName),
};
