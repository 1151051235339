import ReadOnlyActionBase from "../../../core/action/ReadOnlyActionBase";
import ClientMainPageItemsService from "../../service/ClientMainPageItemsService";
export { name } from '../../service/ClientMainPageItemsService'

export default class ClientMainPageItemsAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientMainPageItemsService, pageName);
    }

}