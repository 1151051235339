import React from "react";
import PropTypes from "prop-types";


const Content = ({ copyrightString, sitemapTitle, sitemapUrl, privacyPolicyTitle, privacyPolicyUrl }) => (
    <React.Fragment>

        <div className={"col-12 col-lg-6"}>
            <div className={"footer__item"}>
                <span className={"__copy"}>{ copyrightString }</span>
                <span style={{color: "#2a2a2a", marginLeft: "12px"}}>Powered By <a style={{color: "#2a2a2a"}} href="https://www.armadorteknoloji.com">ARMADOR Yazılım ve Teknoloji A.Ş.</a></span>
            </div>
        </div>

        {
            sitemapUrl && privacyPolicyUrl ?
                <div className={"col-12 col-lg-6  text-lg-right"} >
                    <div className={"footer__item"}>
                        <span className={"__copy"}>
                            <React.Fragment>
                            {
                                sitemapTitle && sitemapUrl ?
                                    <a href={privacyPolicyUrl}>{ privacyPolicyTitle }</a> : null
                            }
                            {
                                sitemapTitle && privacyPolicyTitle ? " | " : null
                            }
                            {
                                privacyPolicyTitle && privacyPolicyUrl ?
                                    <a href={sitemapUrl}>{ sitemapTitle }</a> : null
                            }
                                </React.Fragment>
                        </span>
                    </div>
                </div> : null
        }

    </React.Fragment>
);

Content.propTypes = {
    copyrightString: PropTypes.string.isRequired,

    sitemapTitle: PropTypes.string,
    sitemapUrl: PropTypes.string,

    privacyPolicyTitle: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
}

Content.defaultProps = {
    copyrightString: "",

    sitemapTitle: "",
    sitemapUrl: "#",

    privacyPolicyTitle: "",
    privacyPolicyUrl: "#",
}

export default Content;