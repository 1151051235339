import React, {useState} from "react";

function GotoTop ( props ) {

    const [ gotoTopBtnVisible, setGotoTopBtnVisible ] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setGotoTopBtnVisible(true)
        }
        else if (scrolled <= 300){
            setGotoTopBtnVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div id="btn-to-top-wrap" style={{display: gotoTopBtnVisible ? 'inline' : 'none'}}>
            <a id="btn-to-top" className="circled" onClick={scrollToTop} />
        </div>
    );

}

export default GotoTop