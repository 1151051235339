import CoreActionBase from "../../../core/action/CoreActionBase";
import ClientSliderItemService from "../../service/ClientSliderItemService";
export { name } from '../../service/ClientSliderItemService'

export default class ClientSliderItemAction extends CoreActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientSliderItemService, pageName);
    }

}