import React from 'react';
import {ShowroomPageItem3} from "../../../component";

const Content = ({ payload, language, readMoreText }) => {
    return (
        <React.Fragment>
            {
                payload.map(item => <ShowroomPageItem3 {...item} language={language} readMoreText={readMoreText} /> )
            }
        </React.Fragment>
    );
}

export default Content;