import React, {useState} from 'react';
import {connect} from "react-redux";
import { useParams } from 'react-router';
import Lodash from "lodash";
// defaults
import {CoreDefault} from "../../../core/CoreDefault";
// components
import Logo from './components/Logo';
import NavigationBar from "../NavigationBar";
import LanguageNavigationBar from "../LanguageNavigationBar";

const Header_001 = ( props ) => {
    console.log("Header_001.rendered: ", props);

    const [ isMobilMenuOpen, setIsMobilMenuOpen ] = useState(false);

    return (
        <React.Fragment>
            <header id="top-bar" className={`${isMobilMenuOpen ? "is-expanded" : null} top-bar top-bar--style-2`}>
                <div className={"top-bar__bg"} style={{backgroundColor: "#FFF"}} />

                <div className={"container position-relative"} style={{marginTop: "10px"}}>

                    <div className={"row justify-content-between no-gutters"}>

                        <div className={"align-self-center"} style={{width: "25%"}}>
                            <Logo isLoading={props.settingsIsLoading} alt={"ECO-RCCFS"} payload={props.logoDocumentPath} language={props.currentLanguage?.twoLetterISOName} />
                        </div>

                        <a id={"top-bar__navigation-toggler"}
                           className={`top-bar__navigation-toggler top-bar__navigation-toggler--dark ${isMobilMenuOpen ? "is-active" : null}`}
                           href={"#"}
                           onClick={(e) => {
                               setIsMobilMenuOpen(!isMobilMenuOpen);
                           }}
                        >
                            <span />
                        </a>

                        <div id={"top-bar__inner"} className={"top-bar__inner text-lg-right d-none d-sm-none d-md-block d-lg-block d-xl-block"}>
                            <div className={"d-lg-flex flex-lg-row align-items-lg-start"} style={{width: "100%"}}>
                                <LanguageNavigationBar layout={{ layoutName: "LNB_001" }}/>
                                <Logo isLoading={props.settingsIsLoading} alt={"ECO-RCCFS"} payload={"/assets/img/logo/logo_eco.jpg"} language={props.currentLanguage?.twoLetterISOName} />
                            </div>
                        </div>

                    </div>

                    <div className={"top-bar__inner text-lg-right"}>
                        <div className={"d-lg-flex flex-lg-column-reverse align-items-lg-center"} style={{width: "100%"}}>
                            <NavigationBar layout={{ layoutName: "NB_001" }} />
                            <div className={"d-block d-sm-none"}>
                                <LanguageNavigationBar layout={{ layoutName: "LNB_001" }}/>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        </React.Fragment>
    );
}

const mapStateToProps = function (state, props) {
    console.log("Header_001.mapStateToProps: ", state);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;

    const settings = Lodash.get(state.CMSReducer.SiteSettingsReducer.list.payload, "[0]", {});
    const settingsIsLoading = state.CMSReducer.SiteSettingsReducer.list.isLoading;

    const logoDocumentPath = `${CoreDefault.endpointRoutes.cdn}${settings.logoDocumentPath}`;

    return {
        currentLanguage,

        settings,
        settingsIsLoading,

        logoDocumentPath
    }
}

const Component = React.memo(Header_001, (prevProps, nextProps) => {
    console.log("---- Header_001.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.currentLanguage?.id !== nextProps.currentLanguage?.id || prevProps.settingsIsLoading !== nextProps.settingsIsLoading) {
        console.log("Header_001.doRender");
        return false;
    }

    console.log("Header_001.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);