import React from 'react';
import SocialMediaLinks from './socialMediaLinks';
import InformationLoader from './informationLoader';


const Informations = ({ logoUrl, siteName, address, phoneNumber, email, socialMedia, socialMediaIsLoading, isLoading, language }) => (
    <React.Fragment>
        <div className={"col-12 col-sm-auto"} >
            <div className={"footer__item"} >
                <a style={{display: "initial"}} href={`/${language}`} >
                    <img
                        style={{background: "white", borderRadius: "5px"}}
                        className={"img-fluid p-3"}
                        src={logoUrl}
                        alt={"ECO-RCC"} />
                </a>
            </div>
        </div>

        <div className={"col-12 col-md col-lg-4"} >
            <div className={"footer__item"} >
                {
                    isLoading ?
                        <InformationLoader isDesktop={true} /> :
                        <React.Fragment>
                            <h6 className={"footer__item__title h6"}>{ siteName }</h6>
                            <address>
                                <p>{ address }</p>
                                <p>{ phoneNumber }</p>
                                <p><a href={`mailto:${email}`}>{ email }</a></p>
                            </address>
                        </React.Fragment>
                }
                <SocialMediaLinks payload={socialMedia} isLoading={socialMediaIsLoading} />
            </div>
        </div>
    </React.Fragment>
)

export default Informations;