import { CMSDefault }	from '../../CMSDefault';
import ReadOnlyOdataServiceBase from "../../../core/service/ReadOnlyOdataServiceBase";


export const name = "YOABMY";
export default class SiteSettingsService extends ReadOnlyOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.siteSetting.prefix,
            name: name,
        });
    }

}
