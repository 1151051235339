import ClientSliderItemAction  from "../../action/service/ClientSliderItemAction";
import { pageName } from './default'
export {name}  from "../../action/service/ClientSliderItemAction";


export default class CarouselAction extends ClientSliderItemAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}