import { CMSDefault }	from '../CMSDefault';
import CoreOdataServiceBase from "../../core/service/CoreOdataServiceBase";

export const name = "PSJPDG";
export default class ClientSliderItemService extends CoreOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.clientSliderItems.prefix,
            name: name
        });
    }

}
