import {
    FETCHING,
    FETCH_SUCCESS,
    FETCH_FAIL,
    FETCH_CLEAN,
    TOGGLE
} from '../type/ActionResultTypes'

export const initState = {
    isLoading: true,
    message: null,
    payload: null,
    error: null
}

/**
 *
 * @param pageName { string }
 * @param serviceName { string }
 * @param protocol { string }
 * @param isAccount { boolean }
 * @param isInfo { boolean }
 * @param initialState { Object||null }
 * @returns {(function(*=, *): ({isLoading: boolean, payload: *, message: null, error: null}))|*}
 */
export default ( pageName, serviceName, protocol, isAccount = false, isInfo = false, initialState = null ) => {

    return function reducer(state = {...initState, ...initialState}, action) {
        switch (action.type) {
            // Success
            case `${pageName}${serviceName}${protocol}${FETCH_SUCCESS}`:
                if ( isAccount ) localStorage.setItem("token", action.payload);
                return {
                    ...state,
                    payload: action.payload,
                    error: null,
                    isLoading: false
                };

            // Fail
            case `${pageName}${serviceName}${protocol}${FETCH_FAIL}`:
                if ( isAccount || isInfo ) localStorage.clear();
                return {
                    ...state,
                    payload: null,
                    error: action.payload,
                    isLoading: false
                };

            // Clean
            case `${pageName}${serviceName}${protocol}${FETCH_CLEAN}`:
                if ( isAccount || isInfo ) localStorage.clear();
                return {
                    ...state,
                    payload: null,
                    error: null,
                    isLoading: false
                };

            // Fetching
            case `${pageName}${serviceName}${protocol}${FETCHING}`:
                return {
                    ...state,
                    isLoading: true
                };

            // Toggle
            case `${pageName}${serviceName}${protocol}${TOGGLE}`:
                return {
                    ...state,
                    payload: !state.payload,
                    error: null,
                    isLoading: false
                };

            default:
                return state;
        }

    }

}