import { combineReducers } from "redux";

// - new
import SiteSettingsReducer from "./app/SiteSettingsReducer";
import SocialMediaReducer from "./app/SocialMediaReducer";
import ClientMenuItemsReducer from "./app/ClientMenuItemsReducer";
import ClientMainPageItemsReducer from "./screen/home/ClientMainPageItemsReducer";

export default combineReducers({
    // - new
    ...ClientMenuItemsReducer,
    ...SiteSettingsReducer,
    ...SocialMediaReducer,
    ...ClientMainPageItemsReducer,
});
