import { CMSDefault }	from '../CMSDefault';
import ServiceBase      from "../../core/service/ServiceBase";
import { Download }     from '../../core/FetchRequest';
import { ApiProtocolEnum } from '../../core/enum/ApiProtocolEnum';


export const name = "WYZURY";
export default class ClientContentService extends ServiceBase {

    /**
     *
     */
    constructor () {

        super({
            sourcePath: CMSDefault.endpointRoutes.clientContents.prefix,
            name: name
        });
    }

    /**
	 * Download
	 * @param params {object}
     * @param params.url {string}
	 */
    async download( params ) {
        return Download( {
            url: params.url,
            method: ApiProtocolEnum.GET,
        });
    }

}
