import CoreApiServiceBase from "./CoreApiServiceBase";
import {ApiFetch} from "../FetchRequest";
import {ApiProtocolEnum} from "../enum/ApiProtocolEnum";

export default class ApiServiceBase extends CoreApiServiceBase {

	/**
	 * get
	 * @param params.sourcePath {string|null}
	 * @param params.key {string|null}
	 * @returns {AxiosPromise<any>}
	 */
	async get(params) {
		return ApiFetch( {
			method: ApiProtocolEnum.GET,
			sourcePath: this._sourcePath,
			language: this._lang,
			protocol: 'api',
			...params
		});
	}

}




