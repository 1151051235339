import { combineReducers } from 'redux';
import ReducerBase from "../../../../core/reducer/ReducerBase";
import { name as serviceName } from '../../../service/setting/SiteSettingsService'


export default function SiteSettingsReducer( pageName ) {
    const baseReducer = new ReducerBase(pageName, serviceName);

    return combineReducers({
        ...baseReducer.LIST()
    });
}
