import React, {useEffect, useReducer} from 'react';
import { connect } from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Lodash from "lodash";
// actions
import ClientPagesAction, { name as clientPagesName } from "./actions/ClientPagesAction";
import ClientCategoriesAction, { name as clientCategoriesName } from "./actions/ClientCategoriesAction";
// layouts
import reducer, { initState, initializer } from '../../core/reducer/SimpleReducer';
// layouts
import InnerPage from "../../cms/layout/innr";
import ParallaxHeader from "../../cms/component/ParallaxHeader";
// others
import AppAction from "../../core/action/app/AppAction";
import AnalyticsExtension from '../../core/extension/AnalyticsExtension';

function Page ( props ) {
    console.log("Page.rendered: ", props);

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    // categories
    const [clientCategoriesState, clientCategoriesDispatch] = useReducer(new reducer("PAGE", clientCategoriesName), initState, initializer);
    const clientCategoriesAction = new ClientCategoriesAction(clientCategoriesDispatch);

    // client pages
    const [clientPagesState, clientPagesDispatch] = useReducer(new reducer("PAGE", clientPagesName), initState, initializer);
    const clientPagesAction = new ClientPagesAction(clientPagesDispatch);

    useEffect(
        () => {
            console.log("Page.useEffect1: ", pageId);
            if ( window.location?.href ) {
                AnalyticsExtension.sendPageView(window.location.href);
            }
            props.doSetLoading(true);
            clientPagesAction.details({ serviceData: pageId });
            clientCategoriesAction.details({ serviceData: categoryId });
        },
        [pageId, language]
    )

    useEffect(
        () => {
            console.log("Page.useEffect2: ", language);
            props.doChangeLanguage(language);
        },
        [language]
    )

    useEffect(
        () => {
            if ( !Lodash.isEmpty(clientPagesState.details.payload) ) {
                setTimeout(() => { props.doSetLoading(false); }, 300);
            }
        },
        [ clientPagesState.details.isLoading ]
    )

    return (
        <React.Fragment>
            <ParallaxHeader resources={clientCategoriesState.details.payload?.resources}
                            isLoading={clientCategoriesState.details.isLoading}
                            description={
                                clientCategoriesState.details.payload?.languageVersion?.description ||
                                clientPagesState.details.payload?.languageVersion?.description
                                }
            />
            <InnerPage payload={clientPagesState.details.payload}
                       isLoading={clientPagesState.details.isLoading}
                       language={language}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = function (dispatch) {
    console.log("Page.mapDispatchToProps");
    const appAction = new AppAction(dispatch);

    return {
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
        doChangeLanguage: ( language ) => {
            appAction.changeLanguage( language );
        },
    }
}

const Component = React.memo(Page, (prevProps, nextProps) => {
    console.log("---- Page.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    console.log("Page.doRender");
    return true;
})

export default connect(null, mapDispatchToProps)(Component);