import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";

// redux
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";

// css
import 'bootstrap/scss/bootstrap.scss';
import './index.css';
import './style/critical.scss';
import './style/style.scss';
// SPA entry point
import App from './app';
// analytics
import reportWebVitals from './reportWebVitals';
import AnalyticsExtension from "./core/extension/AnalyticsExtension";
// reducer
import mvbSoftReducer from './app-reducer';

// constants
const store = createStore(mvbSoftReducer, composeWithDevTools(applyMiddleware(thunk)));
const rootElement = document.getElementById('app');

// init
AnalyticsExtension.initGoogleAnalytics(process.env.GA);

ReactDOM.render(
    <React.StrictMode>
        <ParallaxProvider>
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </ParallaxProvider>
    </React.StrictMode>,
    rootElement
);

reportWebVitals((metric) => { AnalyticsExtension.sendWebVitals(metric) });
