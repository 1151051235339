import React from "react";
import ContentLoader from "react-content-loader";

const phone = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"500px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="290" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="580" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="870" y="100" rx="3" ry="3" width="280" height="380" />
    </ContentLoader>
);

const tablet = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"500px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="290" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="580" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="870" y="100" rx="3" ry="3" width="280" height="380" />
    </ContentLoader>
);

const desktop = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"500px"}
        backgroundColor={isDarkMode ? "#e0e0e0": "#f3f3f3"}
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="290" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="580" y="100" rx="3" ry="3" width="280" height="380" />
        <rect x="870" y="100" rx="3" ry="3" width="280" height="380" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @param isDarkMode { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop, isDarkMode = false} ) {

    if ( isPhone ) return phone(isDarkMode);
    else if ( isTablet ) return tablet(isDarkMode);
    else if ( isDesktop ) return desktop(isDarkMode);

}