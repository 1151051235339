import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loader from "./components/loader";
import Content from "./components/content";

export default function ParallaxHeader({ isLoading, speed, resources, description, className }) {

    return (
        <React.Fragment>
            {
                isLoading ?
                    <div className={"hero"}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-12 col-lg-7 ck-content"}>
                                <Loader isDesktop={true} />
                                </div>
                            </div>
                        </div>
                    </div> :
                    <Content resources={resources} description={description} />
                    
            }
        </React.Fragment>
    );
}

ParallaxHeader.propTypes = {
    speed: PropTypes.number,
    className: PropTypes.string,
    resources: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
}

ParallaxHeader.defaultProps = {
    speed: 0.6,
    className: "",
    resources: [],
    description: "",
}