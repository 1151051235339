import React from 'react';
import Helmet from 'react-helmet';
// enums
import LayoutTypeEnum from "../../enum/LayoutTypeEnum";
// layouts
import INNR_001 from "../innr_001";
import INNR_002 from "../innr_002";
import INNR_003 from "../innr_003";
import INNR_004 from "../innr_004";
import INNR_005 from "../innr_005";

const Items = {
    INNR_001,
    INNR_002,
    INNR_003,
    INNR_004,
    INNR_005,
}

const InnerPage = ({ payload, isLoading, language, currentLanguage, verboseResources }) => {
    const layout = payload?.layouts?.find(item => item.layoutTypeName === LayoutTypeEnum.INNER);
    const Component = Items[layout?.layoutName];
    return Component ?
        <React.Fragment>
            <Helmet>
                <title>{ payload.languageVersion?.name }</title>
                {/*<meta name="description" content={} />*/}
                {/*<meta name="twitter:card" content="summary_large_image" />*/}
                {/*<meta name="twitter:site" content="@user" />*/}
                {/*<meta name="twitter:creator" content="@user" />*/}
                {/*<meta name="twitter:title" content="Pets - Products" />*/}
                {/*<meta name="twitter:description" content="Best Products for your pet" />*/}
                {/*<meta name="twitter:image" content="url_to_image"/>*/}
                {/*<meta property="og:title" content="Pets - Products" />*/}
                {/*<meta property="og:description" content="Best Products for your pet" />*/}
                {/*<meta property="og:image" content="url_to_image"/>*/}
                {/*<meta property="og:url" content="pets.abc" />*/}
                {/*<meta property="og:site_name" content="Pets - Products" />*/}
                {/*<meta property="og:locale" content="en_US" />*/}
                {/*<meta property="og:type" content="article" />*/}
                {/*<meta property="fb:app_id" content="ID_APP_FACEBOOK" />*/}
            </Helmet>
            <Component {...payload} isLoading={isLoading} language={language} currentLanguage={currentLanguage} verboseResources={verboseResources} />
        </React.Fragment> : null
}

export default InnerPage;
// TODO: INNR layoutların loader fonksiyonları yazılacak.