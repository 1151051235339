import React from 'react';
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import PropTypes from "prop-types";
import {CoreDefault} from "../../../core/CoreDefault";

const MVBImage = ({ payload, alt, resourceTypeName, style = null, className = null }) => {
    // TODO: Burada imageSrc boş ise default resim gelecek.
    let imageSrc = payload?.find(item => item.resourceTypeName === resourceTypeName)?.path;
    imageSrc = imageSrc ? `${CoreDefault.endpointRoutes.cdn}${imageSrc}` : null;
    const imageAlt = alt || "";

    return (
        <img src={imageSrc} alt={imageAlt} style={style} className={className} />
    );
}

MVBImage.propTypes = {
    payload: PropTypes.array.isRequired,
    resourceTypeName: PropTypes.oneOf(Object.values(ResourceTypeEnum))
}

MVBImage.defaultProps = {
    payload: [],
    resourceTypeName: null
}

export default MVBImage;