import React from 'react';
import Lodash from "lodash";
import {connect} from "react-redux";
// components
import { FooterInformations, FooterLinks, FooterNavigationMenu, Copyright } from "../../component";

const Footer_001 = ({ copyRight, privacyPolicyTitle, sitemapTitle, isLoading }) => (
    <React.Fragment>
        <footer id={"footer"} className={"footer footer--style-2"} >
            <div className={"container"} >

                <div className={"row"} >
                    <FooterInformations />

                    <div className={"col-12 col-sm col-md-auto col-lg-6"} >
                        <div className={"footer__item"} >
                            <nav id={"footer__navigation"} className={"navigation"} >
                                <div className={"row"} >
                                    <FooterNavigationMenu />
                                    <FooterLinks />
                                </div>
                            </nav>
                        </div>
                    </div>

                </div>

                <Copyright
                    copyrightString={copyRight}
                    privacyPolicyUrl={"#"}
                    privacyPolicyTitle={privacyPolicyTitle}
                    sitemapUrl={"#"}
                    sitemapTitle={sitemapTitle}
                    isLoading={isLoading}
                />

            </div>
        </footer>
    </React.Fragment>
);


const mapStateToProps = function (state, props) {
    console.log("Footer.L001.mapStateToProps: ", state.CMSReducer);

    const settings = Lodash.get(state.CMSReducer.SiteSettingsReducer.list.payload, "[0]");
    const settingsIsLoading = state.CMSReducer.SiteSettingsReducer.list.isLoading;

    const verboseResources = state.CoreReducer.VerboseResourcesReducer.list.payload;
    const verboseResourcesIsLoading = state.CoreReducer.VerboseResourcesReducer.list.isLoading;

    const copyRight = settings?.copyRight;
    const privacyPolicyTitle = verboseResources?.find(x=>x.key === "PrivacyPolicy")?.value;
    const sitemapTitle = verboseResources?.find(x=>x.key === "Sitemap")?.value;

    const isLoading = settingsIsLoading || verboseResourcesIsLoading;

    console.log("Miw: ", settingsIsLoading, verboseResourcesIsLoading);

    return {
        copyRight,
        privacyPolicyTitle,
        sitemapTitle,

        settingsIsLoading,
        verboseResourcesIsLoading,
        isLoading,
    }
}

const Component = React.memo(Footer_001, (prevProps, nextProps) => {
    console.log("---- Footer.L001.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if (
        prevProps.settingsIsLoading !== nextProps.settingsIsLoading ||
        prevProps.verboseResourcesIsLoading !== nextProps.verboseResourcesIsLoading
    ) {
        console.log("Footer.L001.doRender");
        return false;
    }

    console.log("Footer.L001.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);