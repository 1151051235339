import { CMSDefault }	from '../CMSDefault';
import CoreOdataServiceBase from "../../core/service/CoreOdataServiceBase";


export const name = "YADQRG";
export default class ClientMenuItemService extends CoreOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.clientMenuItems.prefix,
            name: name
        });
    }

}
