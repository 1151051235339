import React from 'react';
import Loader from './components/loader';
import Content from './components/content';
import {connect} from "react-redux";
import {CMSDefault} from "../../CMSDefault";


const FooterNavigationMenu = ({ language, title, payload, isLoading }) => (
    <React.Fragment>
        <div className={"col-6 col-sm-4"} >
            {
                isLoading ?
                    <Loader isDesktop={true}/> :
                    <Content language={language} title={title} payload={payload} />
            }
        </div>
    </React.Fragment>
);

const mapStateToProps = function (state, props) {
    console.log("FooterNavigationMenu.mapStateToProps: ", state.CoreReducer.AppReducer);

    const language = state.CoreReducer.AppReducer.appLanguage.payload?.twoLetterISOName;

    const payload = state.CMSReducer.ClientMenuItemsReducer.list.payload?.filter(x => x.routeName !== null && x.mapLayoutName === "MNGRP_003" && x.contentTypeName === CMSDefault.defaultContentTypeName.Category);
    const _titlePayload = state.CMSReducer.ClientMenuItemsReducer.list.payload?.find(x=>x.mapLayoutName === "MNGRP_003" && x.parentId === null);
    const title = _titlePayload?.languageVersion?.name;

    const isLoading = state.CMSReducer.ClientMenuItemsReducer.list.isLoading;

    return {
        language,

        title,
        payload,
        isLoading
    }
}

const Component = React.memo(FooterNavigationMenu, (prevProps, nextProps) => {
    console.log("---- FooterNavigationMenu.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.isLoading !== nextProps.isLoading) {
        console.log("FooterNavigationMenu.doRender");
        return false;
    }

    console.log("FooterNavigationMenu.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);