import React from 'react';
import PropTypes from "prop-types";

const Accessory1 = (props) => (
    <React.Fragment>

    </React.Fragment>
);

export default Accessory1;

Accessory1.propTypes = {
    backgroundColor: PropTypes.string.isRequired
}

Accessory1.defaultProps = {
    backgroundColor: "gray"
}