import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function FullScreenLoader ({ isActive }) {
    console.log("FullScreenLoading.rendered:", isActive);

    useEffect(
        () => {
            console.log("FullScreenLoading.useEffect:", isActive);
            if ( isActive ) {
                window.scrollTo(0, 0);
                document.body.style.setProperty("overflow", "hidden");
            } else {
                document.body.style.removeProperty("overflow");
            }
        },
        [isActive]
    )

    return (
        <div
            id={"full-screen-loader"}
            className={`align-self-center my-auto ${ !isActive ? 'full-screen-loader-fadeout':'' }`}
        >
            <div className={"container"}>
                <div className={"loader-container"}>
                    <div className="loader"/>
                </div>
                {/* <div className={"message"}>Loading</div> */}
            </div>
        </div>
    );
}

FullScreenLoader.propTypes = {
    isActive: PropTypes.bool.isRequired,
}

FullScreenLoader.defaultProps = {
    isActive: false,
}