import React from "react";
import PropTypes from "prop-types";
import Content from "./components/content";
import Loader from "./components/loader";


const Copyright = ({ copyrightString, sitemapTitle, sitemapUrl, privacyPolicyTitle, privacyPolicyUrl, isLoading }) => (
    <React.Fragment>
        <div className={"row justify-content-lg-between copyright"}>
            {
                isLoading ?
                    <Loader isDesktop={true} /> :
                    <Content
                        copyrightString={copyrightString}
                        sitemapTitle={sitemapTitle}
                        sitemapUrl={sitemapUrl}
                        privacyPolicyTitle={privacyPolicyTitle}
                        privacyPolicyUrl={privacyPolicyUrl}
                    />
            }
        </div>
    </React.Fragment>
)

Copyright.propTypes = {
    copyrightString: PropTypes.string.isRequired,

    sitemapTitle: PropTypes.string,
    sitemapUrl: PropTypes.string,

    privacyPolicyTitle: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,

    isLoading: PropTypes.bool.isRequired,
}

Copyright.defaultProps = {
    copyrightString: "",

    sitemapTitle: "",
    sitemapUrl: "#",

    privacyPolicyTitle: "",
    privacyPolicyUrl: "#",

    isLoading: true,
}

export default Copyright