import ActionBaseWithDispatcher from "./ActionBaseWithDispatcher";
import {
    FETCHING,
    FETCH_SUCCESS,
    FETCH_CLEAN,
    FETCH_FAIL
} from "../type/ActionResultTypes";
import {
    LIST
} from "../type/ActionProtocolTypes";


export default class CoreActionBase extends ActionBaseWithDispatcher{

    /**
     * odata service GET action
     * @param params { Object|null }
     * @param params.sourcePath { string|null }
     * @param params.serviceData { OdataQueryOptionsModel }
     * @param params.args { Object }
     */
    async get( params) {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCHING}` });
        this._run( this.service.get.bind(this.service), Object.assign({}, params, {args:{...params.args, actionType: LIST}}) );
    }

    /**
     * clean GET action
     */
    cleanGet () {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_CLEAN}` });
    }

    callback200(response, args) {
        super.callback200(response, args);

        if ( args.actionType === LIST ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_SUCCESS}`, payload: response.value });
        }
    }

    callback400(response, args) {
        super.callback400(response, args);

        if ( args.actionType === LIST ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_FAIL}`, payload: response });
        }
    }

}

