import React from 'react';
import {CoreDefault} from "../../../../core/CoreDefault";
import PropTypes from "prop-types";
import MVBLink from '../../MVBLink';

const Item1 = ({ categoryId, categoryRouteName, contentId, resource, categoryName, languageVersion, isNavigationDestination, redirectType, languageCode }) => (
    <React.Fragment>
        <div className="start-screen__slide">
            <div className="start-screen__bg" style={{
                backgroundImage: `url(${CoreDefault.endpointRoutes.cdn}${resource?.path})`,
                backgroundPosition: "top 50% right 50%"
            }} />
            <div className="start-screen__content__item align-items-end">
                <div className="container">
                    <div className="row mb-5" style={{borderRadius: "5px", backgroundColor: "rgba(252, 219, 90, .9)"}}>
                        <div className="col-12 mt-5 mb-5">
                            {/*Desktop*/}
                            <h6 className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                <MVBLink
                                    title={categoryName}
                                    isNavigationDestination={isNavigationDestination}

                                    redirectType={redirectType}

                                    languageCode={languageCode}

                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}

                                />
                                {/* <a href={urlSlashCorrection(categoryRouteName)}>categoryName</a> */}
                            </h6>
                            <h3 style={{marginBottom: 0, textTransform: "none"}}
                                className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                                <MVBLink
                                    title={languageVersion?.name}
                                    isNavigationDestination={isNavigationDestination}
                                    redirectUrl={languageVersion?.redirectUrl}

                                    redirectType={redirectType}

                                    languageCode={languageCode}
                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}
                                    contentId={contentId}
                                    contentRouteName={languageVersion?.routeName}
                                />
                            </h3>
                            {/*Mobile*/}
                            <h6 className="d-block d-sm-none text-center mb-2 mt-0">
                                <MVBLink
                                    title={categoryName}
                                    isNavigationDestination={isNavigationDestination}

                                    redirectType={redirectType}

                                    languageCode={languageCode}
                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}
                                />
                            </h6>
                            <p style={{textTransform: "none", fontWeight: "normal"}}
                               className="d-block d-sm-none text-center mt-0 mb-0">
                                <MVBLink
                                    title={languageVersion?.name}
                                    isNavigationDestination={isNavigationDestination}
                                    redirectUrl={languageVersion?.redirectUrl}

                                    redirectType={redirectType}

                                    languageCode={languageCode}
                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}
                                    contentId={contentId}
                                    contentRouteName={languageVersion?.routeName}
                                />
                            </p>
                            {/*Content*/}
                            <p style={{marginTop: 0}} className="d-none d-sm-block d-md-block ck-content"
                               dangerouslySetInnerHTML={{__html: languageVersion?.description}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)

export default Item1;

Item1.propTypes = {
    resource: PropTypes.object.isRequired,
    categoryName: PropTypes.string.isRequired,
    languageVersion: PropTypes.object.isRequired,
    isNavigationDestination: PropTypes.bool.isRequired,
    routeName: PropTypes.string.isRequired,
    categoryRouteName: PropTypes.string.isRequired,
    redirectType: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
}

Item1.defaultProps = {
    resource: {},
    categoryName: "",
    languageVersion: {}, 
    isNavigationDestination: false, 
    routeName: "",
    categoryRouteName: "", 
    redirectType: "",
    languageCode: "",
}