import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import Cookies from "universal-cookie";
import { useHistory, useLocation, useParams } from "react-router-dom";

// defaults
import { CoreDefault } from "./core/CoreDefault";
import {CMSDefault} from "./cms/CMSDefault";
// pages
import Home from "./screen/home";
import Error from "./screen/error";
import Category from "./screen/category";
import Page from "./screen/page";
// layouts
import { Header, Footer } from "./cms/layout";
import { FullScreenLoader } from "./cms/component";
// actions
import LanguageAction from "./core/action/app/LanguageAction";
import AppAction from "./core/action/app/AppAction";
import SiteSettingsAction from "./cms/action/app/SiteSettingsAction";
import VerboseResourcesAction from "./cms/action/app/VerboseResourcesAction";
import ClientMenuItemAction from "./cms/action/app/ClientMenuItemAction";
import SocialMediaAction from "./cms/action/app/SocialMediaAction";
// css
import './app.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'
import Lodash from "lodash";
import GotoTop from "./cms/component/GoToTop";

// constants
const cookies = new Cookies();

function App ( props ) {
    console.log("App.rendered", props);

    const history = useHistory();
    const location = useLocation();
    // const { language, categoryName, pageName, categoryId, pageId } = useParams();

    // const [isActive, setisActive] = useState(false);

    useEffect (
        () => {
            console.log("App.useEffect.1:", props);
            // if ( !isActive ) {
                props.doGetLanguage(['isActive eq true'], ['id asc']);
                // setisActive(true);
            // }

            return function cleanup() {
                props.doClean();
            }
        },
        []
    );

    useEffect (
        () => {
            console.log("App.useEffect.2: ");

            if ( !Lodash.isEmpty(props.currentLanguage) ) {
                props.doSetLanguage(props.currentLanguage);
                props.doGetSiteSetting([`languageId eq ${props.currentLanguage.id}`]);
                props.doGetVerboseResources();
                props.doGetMenuItem(props.token !== undefined ? null : ['isActive eq true'], ['order asc']);
                props.doGetSocialMedia([`languageId eq ${props.currentLanguage.id}`]);
            }
        },
        [props.currentLanguage]
    );

    useEffect(
        () => {
            console.log("App.useEffect.3: ", props.isAppLoading);
            props.doSetLoading(props.isAppLoading);
        },
        [props.isAppLoading]
    );

    useEffect(
        () => {
            console.log("App.useEffect.4: ");
            if ( props.languageChange ) {
                const language = props.languages.find(x => x.twoLetterISOName === props.languageChange);
                console.log("language.1:", language);
            }
        },
        [props.languageChange]
    );

    useEffect(
        () => {
            console.log("App.useEffect.5.1: ", history, location);
            if ( history.action === "PUSH") {
                console.log("App.useEffect.5.2: ", history, location);
                props.doGetLanguage(['isActive eq true'], ['id asc']);
                history.action = null;
            }
        },
        [ history.action ]
    );

    return (
        <React.Fragment>
            <FullScreenLoader isActive={props.isPageLoading} />
        {
            props.currentLanguage?.twoLetterISOName !== undefined ?
                <Switch>
                    <Route exact
                           key={"matched"}
                           path={[
                               "/", "/index", "/home",
                               "/:language([a-z]{2})", "/:language([a-z]{2})/", "/:language([a-z]{2})/index", "/:language([a-z]{2})/home",
                               "/:language([a-z]{2})/:categoryName/:categoryId",
                               "/:language([a-z]{2})/:categoryName/:pageName/:categoryId/:pageId"
                           ]}
                    >

                        <React.Fragment>
                            <Header />
                            <Switch>
                                <Route exact key={"homeRedirect"} path={["/", "/index", "/home"]}>
                                    <Redirect exact to={`/${props.currentLanguage?.twoLetterISOName}`} />
                                </Route>
                                <Route exact key={"home"} path={['/:language','/:language/', '/:language/index', '/:language/home']} component={Home} />
                                <Route exact key={"categories"} path={"/:language/:categoryName/:categoryId"} component={Category} />
                                <Route exact key={"pages"} path={"/:language/:categoryName/:pageName/:categoryId/:pageId"} component={Page} />
                            </Switch>
                            <Footer />
                            <GotoTop />
                        </React.Fragment>

                    </Route>
                    <Route exact key={"notMatched"} path={"*"} component={Error} />
                </Switch> : null
        }
        </React.Fragment>
    );

}

const mapStateToProps = function(state, props) {
    console.log("App.mapStateToProps", state, props);

    const pathnameSplit = window.location.pathname?.split("/");
    let currentLanguage = Lodash.get(pathnameSplit, "[1]", null);

    const token = cookies.get(CoreDefault.tokenKeyName, { path: "/" });

    const languages = state.CoreReducer.LanguageReducer.list.payload;
    const languagesIsLoading = state.CoreReducer.LanguageReducer.list.isLoading;
    const languageChange = state.CoreReducer.AppReducer.change.payload;

    const settings = Lodash.get(state.CMSReducer.SiteSettingsReducer.list.payload, "[0]");
    const settingsIsLoading = state.CMSReducer.SiteSettingsReducer.list.isLoading;

    const clientMenuItemsIsLoading = state.CMSReducer.ClientMenuItemsReducer.list.isLoading;
    const verboseResourcesIsLoading = state.CoreReducer.VerboseResourcesReducer.list.isLoading;

    // user local language resources
    const languageFromLocalStorage = localStorage.getItem('accept-language');
    const browserLanguage = navigator.language?.slice(0,2);

        // language from local storage ( user selected )
    if ( !Lodash.isEmpty(languageFromLocalStorage) ) {
        currentLanguage = languages?.find(x => x.twoLetterISOName === currentLanguage);
    }
        // language from browser ( browser default )
    if ( Lodash.isEmpty(currentLanguage) && !Lodash.isEmpty(browserLanguage) ) {
        currentLanguage = languages?.find(x => x.twoLetterISOName === browserLanguage);
    }
        // all language resources fail ( default )
    if ( Lodash.isEmpty(currentLanguage) ) {
        currentLanguage = Lodash.get(languages,"[0]");
    }
    localStorage.setItem("accept-language", currentLanguage?.twoLetterISOName);
    localStorage.setItem("accept-language-code", currentLanguage?.code);

    const isPageLoading = state.CoreReducer.AppReducer.appLoading.payload;

    const isLanguageLoading = Lodash.isEmpty(languages);
    const isSettingsLoading = Lodash.isEmpty(settings);
    const isAppLoading = languagesIsLoading || isLanguageLoading ||
        settingsIsLoading || isSettingsLoading ||
        clientMenuItemsIsLoading ||
        verboseResourcesIsLoading;
    
    console.log(
        "isAppLoading: ",
        languagesIsLoading, isLanguageLoading,
        settingsIsLoading, isSettingsLoading,
        clientMenuItemsIsLoading, verboseResourcesIsLoading
    );

    // console.table({
    //     languages: JSON.stringify(languages),
    //     languagesIsLoading,
    //     isLanguageLoading,

    //     settingsIsLoading,
    //     isSettingsLoading,

    //     clientMenuItemsIsLoading,

    //     verboseResourcesIsLoading,
    //     languageFromLocalStorage,
    //     browserLanguage,
    //     currentLanguage: JSON.stringify(currentLanguage),
    //     isPageLoading,
    //     isAppLoading
    // })

    return {
        token,

        languages,
        languagesIsLoading,
        languageChange,

        settings,
        settingsIsLoading,

        currentLanguage,

        isPageLoading,
        isAppLoading,
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("App.mapDispatchToProps ");

    const languageAction = new LanguageAction(dispatch);
    const siteSettingsAction = new SiteSettingsAction(dispatch);
    const verboseResourcesAction = new VerboseResourcesAction(dispatch);
    const appAction = new AppAction(dispatch);
    const clientMenuItemAction = new ClientMenuItemAction(dispatch);
    const socialMediaAction = new SocialMediaAction(dispatch);

    return {
        doGetLanguage: (filter, orderBy) => {
            languageAction.get({
                serviceData:{
                    filter: filter,
                    orderBy: orderBy
                }
            })
        },
        doGetSiteSetting: (filter, orderBy) => {
            siteSettingsAction.get({
                serviceData:{
                    filter: filter,
                    orderBy: orderBy
                }
            });
        },
        doGetVerboseResources: (filter, orderBy) => {
            verboseResourcesAction.get({
                serviceData:{
                    filter: filter,
                    orderBy: orderBy
                }
            })
        },
        doSetLanguage: ( language ) => {
            appAction.setLanguage( language );
        },
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
        doGetMenuItem: (filter, orderBy) => {
            clientMenuItemAction.get({
                serviceData:{
                    filter: filter,
                    orderBy: orderBy
                }
            })
        },
        doGetSocialMedia: (filter, orderBy) => {
            socialMediaAction.get({
                serviceData:{
                    filter: filter,
                    orderBy: orderBy
                }
            });
        },

        doClean: () => {

        }
    }
}

const Component = React.memo(App, (prevProps, nextProps) => {
    console.log("---- App.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    console.log("App.React.memo.1: ", prevProps.isPageLoading, nextProps.isPageLoading, prevProps.isPageLoading !== nextProps.isPageLoading);
    console.log("App.React.memo.2: ", prevProps.isAppLoading, nextProps.isAppLoading, prevProps.isAppLoading !==  nextProps.isAppLoading);
    console.log("App.React.memo.3: ", prevProps.currentLanguage?.id, nextProps.currentLanguage?.id, prevProps.currentLanguage?.id !== nextProps.currentLanguage?.id);

    if (
        prevProps.isPageLoading !== nextProps.isPageLoading ||
        prevProps.isAppLoading !==  nextProps.isAppLoading ||
        prevProps.currentLanguage?.id !== nextProps.currentLanguage?.id
    ) {
        console.log("App.doRender");
        return false;
    }

    console.log("App.doNotRender");
    return false;
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);