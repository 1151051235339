import ReadOnlyActionBase from "../../../core/action/ReadOnlyActionBase";
import ClientContentService from "../../service/ClientContentService";
import {
    DOWNLOAD,
    LIST
} from "../../../core/type/ActionProtocolTypes";
import { 
    FETCHING, 
    FETCH_CLEAN, 
    FETCH_FAIL, 
    FETCH_SUCCESS 
} from "../../../core/type/ActionResultTypes";
export { name } from '../../service/ClientContentService'

export default class ClientContentAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientContentService, pageName);
    }

    /**
     * Download
     * @param params { Object|null }
     * @param params.sourcePath { string|null }
     * @param params.key { string|null }
     * @param params.args { Object }
     */
     async download ( params) {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCHING}` });
        this._run( this.service.download.bind(this.service), Object.assign({}, params, {args:{...params.args, actionType: DOWNLOAD}}) );
    }

    /**
     * clean DOWNLOAD action
     */
    cleanDownload () {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_CLEAN}` });
    }

    callback200(response, args) {
        super.callback200(response, args);

        if ( args.actionType === DOWNLOAD ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_SUCCESS}`, payload: response });
        }
    }

    callback400(response, args) {
        super.callback400(response, args);

        if ( args.actionType === DOWNLOAD ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${LIST}${FETCH_FAIL}`, payload: null });
        }
    }

}