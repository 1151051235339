import ReadOnlyActionBase from "../../../../core/action/ReadOnlyActionBase";
import SocialMediaService from "../../../service/setting/SocialMediaService";
export { name } from '../../../service/setting/SocialMediaService'

export default class SiteSettingsAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, SocialMediaService, pageName);
    }

}