import React from "react";
import ContentLoader from "react-content-loader";

const phone = (
    <ContentLoader
        speed={2}
        width={"300px"}
        height={"120px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="15" rx="3" ry="3" width="90" height="90" />
        <rect x="100" y="15" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="47" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="80" rx="3" ry="3" width="100%" height="25" />
    </ContentLoader>
);

const tablet = (
    <ContentLoader
        speed={2}
        width={"300px"}
        height={"120px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="15" rx="3" ry="3" width="90" height="90" />
        <rect x="100" y="15" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="47" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="80" rx="3" ry="3" width="100%" height="25" />
    </ContentLoader>
);

const desktop = (
    <ContentLoader
        speed={2}
        width={"300px"}
        height={"120px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="15" rx="3" ry="3" width="90" height="90" />
        <rect x="100" y="15" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="47" rx="3" ry="3" width="100%" height="25" />
        <rect x="100" y="80" rx="3" ry="3" width="100%" height="25" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop} ) {

    if ( isPhone ) return phone;
    else if ( isTablet ) return tablet;
    else if ( isDesktop ) return desktop;

}