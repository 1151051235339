import React from 'react';
import {connect} from "react-redux";
import Loader from './components/loader'
import AppAction from "../../../core/action/app/AppAction";


const LanguageNavigationBarLayout2 = ({ payload, isLoading, value, doSetLoading }) => {
    console.log("LanguageNavigationBarLayout2.rendered: ",  payload, isLoading, value);

    return (
        <React.Fragment>
            <Loader isDesktop={true} />
        </React.Fragment>
    );
}


const mapStateToProps = function (state, props) {
    console.log("LanguageNavigationBarLayout2.mapStateToProps: ", state);

    const payload = state.CoreReducer.LanguageReducer.list.payload;
    const isLoading = state.CoreReducer.LanguageReducer.list.isLoading;

    const value = state.CoreReducer.AppReducer.appLanguage.payload;

    return {
        value,

        payload,
        isLoading,
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("LanguageNavigationBarLayout2.mapDispatchToProps");
    const appAction = new AppAction(dispatch);

    return {
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
    }
}

const Component = React.memo(LanguageNavigationBarLayout2, (prevProps, nextProps) => {
    console.log("---- LanguageNavigationBarLayout2.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    console.log("LanguageNavigationBarLayout2.doNotRender");
    return true;
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);