import { CMSDefault }	from '../CMSDefault';
import ReadOnlyOdataServiceBase from "../../core/service/ReadOnlyOdataServiceBase";


export const name = "WOXCBR";
export default class ClientCategoriesService extends ReadOnlyOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.clientCategories.prefix,
            name: name
        });
    }

}
