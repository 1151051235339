import {default as _ClientPagesAction}  from "../../../cms/action/service/ClientPagesAction";

export { name }  from "../../../cms/action/service/ClientPagesAction";
const pageName = "PAGE"

export default class ClientPagesAction extends _ClientPagesAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}