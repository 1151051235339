import React from 'react';
import MVBLink from "../../../component/MVBLink";
import {OuterItem1Type1, OuterItem1Type2} from "../../../component";

const Content = ({ id, contentId, isNavigationDestination, redirectType, language, languageVersion, payload, verboseResources }) => {
    const showAllText = verboseResources?.find(item => item.key === "ShowAll")?.value;

    return (
        <React.Fragment>
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                <div style={{flexGrow: 1}}>
                    <MVBLink
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                    >
                        <div className={"section-heading section-heading--left ck-content"} dangerouslySetInnerHTML={{__html: `${languageVersion?.description}`}} />
                    </MVBLink>
                </div>
                <div>
                    <MVBLink
                        title={showAllText}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                        className={"custom-btn custom-btn--medium custom-btn--style-2"}
                    />
                </div>
            </div>
            <div className="recipes">
                <div className="__inner">
                    <div className="row no-gutters">
                        <OuterItem1Type1 { ...payload?.[0] } language={language} />
                        <OuterItem1Type1 { ...payload?.[1] } language={language} />
                        <OuterItem1Type2 { ...payload?.[2] } language={language} />
                        <OuterItem1Type2 { ...payload?.[3] } language={language} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Content;