import React from "react";
import ContentLoader from "react-content-loader";

const phone = (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"250px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="158" height="158" />

        <rect x="170" y="0" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="25" rx="3" ry="3" width="300" height="20" />

        <rect x="170" y="70" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="95" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="120" rx="3" ry="3" width="300" height="20" />

        <rect x="170" y="165" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="190" rx="3" ry="3" width="300" height="20" />
    </ContentLoader>
);

const tablet = (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"250px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="158" height="158" />

        <rect x="170" y="0" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="25" rx="3" ry="3" width="300" height="20" />

        <rect x="170" y="70" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="95" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="120" rx="3" ry="3" width="300" height="20" />

        <rect x="170" y="165" rx="3" ry="3" width="300" height="20" />
        <rect x="170" y="190" rx="3" ry="3" width="300" height="20" />
    </ContentLoader>
);

const desktop = (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"250px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="158" height="158" />

        <rect x="170" y="0" rx="3" ry="3" width="350" height="20" />
        <rect x="170" y="25" rx="3" ry="3" width="350" height="20" />

        <rect x="170" y="70" rx="3" ry="3" width="350" height="20" />
        <rect x="170" y="95" rx="3" ry="3" width="350" height="20" />
        <rect x="170" y="120" rx="3" ry="3" width="350" height="20" />

        <rect x="170" y="165" rx="3" ry="3" width="350" height="20" />
        <rect x="170" y="190" rx="3" ry="3" width="350" height="20" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop} ) {

    if ( isPhone ) return phone;
    else if ( isTablet ) return tablet;
    else if ( isDesktop ) return desktop;

}