import React from 'react';
// enums
import LayoutTypeEnum from "../../enum/LayoutTypeEnum";
// layouts
import MNPG_001 from "../mnpg_001";
import MNPG_002 from "../mnpg_002";
import MNPG_003 from "../mnpg_003";
import MNPG_004 from "../mnpg_004";
import MNPG_005 from "../mnpg_005";

const Items = {
    MNPG_001,
    MNPG_002,
    MNPG_003,
    MNPG_004,
    MNPG_005,
}

const MainPageItem = ({ index, payload, verboseResources, layoutStyle, currentLanguage }) => {
    const layout = payload?.layouts?.find(item => item.layoutTypeName === LayoutTypeEnum.MAIN_PAGE);
    const Component = Items[layout?.layoutName];
    return Component ?
        <React.Fragment>
            <section className={`section ${layoutStyle || index%2===1?"section--gray-bg":""}`}>
                <div className="container">
                    <Component {...payload} index={index} verboseResources={verboseResources} currentLanguage={currentLanguage} />
                </div>
            </section>
        </React.Fragment> : null
}

export default MainPageItem;