import React from 'react';
import MVBLink from "../../../component/MVBLink";
import Slider from 'react-slick';
import {OuterItem4} from "../../../component";

const sliderSettings = {
    autoPlay: false,
    arrows: true,
    dots: true,
    speed: 1200,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <i className={"slick-prev"}><i className={"fontello-left-open"} /></i>,
    nextArrow: <i className={"slick-next"}><i className={"fontello-right-open"} /></i>,
    responsive:[
        {
            breakpoint: 575,
            settings:{
                arrows: false,
                dots: false,
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings:{
                arrows: false,
                dots: false,
                rows: 1,
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 991,
            settings:{
                rows: 1,
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1199,
            settings:{
                rows: 1,
                slidesToShow: 4,
                slidesToScroll: 1
            }
        }
    ]
};

const Content = ({ categoryId, categoryRouteName, contentId, isNavigationDestination, redirectType, language, languageVersion, payload, verboseResources }) => {
    const showAllText = verboseResources?.find(item => item.key === "ShowAll")?.value;
    const moreAboutText = verboseResources?.find(item => item.key === "MoreAbout")?.value;

    return (
        <React.Fragment>
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                <div style={{flexGrow: 1}}>
                    <MVBLink
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                    >
                        <div className={"section-heading section-heading--left ck-content"} dangerouslySetInnerHTML={{__html: `${languageVersion?.description}`}} />
                    </MVBLink>
                </div>
                <div>
                    <MVBLink
                        title={showAllText}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                        className={"custom-btn custom-btn--medium custom-btn--style-2"}
                    />
                </div>
            </div>
            <div className="goods goods--style-1 goods--slider">
                <Slider className="js-slick" {...sliderSettings}>
                    {
                        payload?.map(item => <OuterItem4 {...item} language={language} moreAboutText={moreAboutText} />)
                    }
                </Slider>
            </div>
        </React.Fragment>
    );
}

export default Content;