import ServiceBase from "../../core/service/ServiceBase";
import { ApiFetch } from "../../core/FetchRequest";
import { ApiProtocolEnum } from "../../core/enum/ApiProtocolEnum";
import { CMSDefault }	from '../CMSDefault';


export const name = "ZAKJEU";
export default class ClientSliderItemService extends ServiceBase {

    /**
     *
     */
    constructor () {
        super( {
            sourcePath: CMSDefault.endpointRoutes.contactUs.prefix,
            name: name
        });
    }

    /**
     *
     * @param model {Object}
     */
    async post( model ) {
        return ApiFetch( {
            method: ApiProtocolEnum.POST,
            sourcePath: this._sourcePath,
            body: model
        });
    }

}
