import React from 'react';
import {connect} from "react-redux";
import Lodash from 'lodash';
import {CoreDefault} from "../../../core/CoreDefault";

import Loader from './components/informationLoader';
import Informations from './components/informations';


const FooterInformations = ({ logoPath, siteName, address, phoneNumber, email, socialMedias, socialMediaIsLoading, isLoading, currentLanguage }) => (
    <React.Fragment>
        {
            isLoading ?
                <div className={"col-12 col-md col-lg-6"} >
                    <Loader isDesktop={true} />
                </div> :
                <Informations
                    logoUrl={logoPath}

                    siteName={siteName}
                    address={address}
                    phoneNumber={phoneNumber}
                    email={email}

                    socialMedia={socialMedias}
                    socialMediaIsLoading={socialMediaIsLoading}

                    language={currentLanguage?.twoLetterISOName}
                    isLoading={isLoading}
                />
        }
    </React.Fragment>
)

const mapStateToProps = function (state, props) {
    console.log("FooterInformations.mapStateToProps: ", state.CMSReducer);

    // const logoDocumentPath = Lodash.get(state.CMSReducer.SiteSettingsReducer.list, "payload.[0].logoDocumentPath", null);
    // const logoPath = `${CoreDefault.endpointRoutes.cdn}${logoDocumentPath}`;
    const logoPath = "/assets/img/logo/logo_128.png";

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;

    const siteName = Lodash.get(state.CMSReducer.SiteSettingsReducer.list, "payload.[0].name", null);
    const address = Lodash.get(state.CMSReducer.SiteSettingsReducer.list, "payload.[0].address", null);
    const phoneNumber = Lodash.get(state.CMSReducer.SiteSettingsReducer.list, "payload.[0].phoneNumber", null);
    const email = Lodash.get(state.CMSReducer.SiteSettingsReducer.list, "payload.[0].email", null);

    const socialMedias = state.CMSReducer.SocialMediaReducer.list.payload;
    const socialMediaIsLoading = state.CMSReducer.SocialMediaReducer.list.isLoading;

    const isLoading = state.CMSReducer.SiteSettingsReducer.list.isLoading;

    return {
        logoPath,

        siteName,
        address,
        phoneNumber,
        email,

        socialMedias,
        socialMediaIsLoading,

        currentLanguage,
        isLoading,
    }
}

const Component = React.memo(FooterInformations, (prevProps, nextProps) => {
    console.log("---- FooterInformations.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if (
        prevProps.isLoading !== nextProps.isLoading ||
        prevProps.socialMediaIsLoading !== nextProps.socialMediaIsLoading
    ) {
        console.log("FooterInformations.doRender");
        return false;
    }

    console.log("FooterInformations.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);