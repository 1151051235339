import React, {useEffect, useReducer} from 'react';
import { connect } from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Lodash from "lodash";
// actions
import ClientCategoriesAction, { name as clientCategoriesName } from "./actions/ClientCategoriesAction";
import ClientPagesAction, { name as clientPagesName } from "./actions/ClientPagesAction";
// layouts
import reducer, { initState, initializer } from '../../core/reducer/SimpleReducer';
// layouts
import ShowroomPage from "../../cms/layout/shwrm";
import InnerPage from "../../cms/layout/innr";
import ParallaxHeader from "../../cms/component/ParallaxHeader";
// others
import AppAction from "../../core/action/app/AppAction";
import {StringSearch} from "../../cms/component";
import AnalyticsExtension from '../../core/extension/AnalyticsExtension';

function Category ( props ) {
    console.log("Category.rendered: ", props);

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    // pages
    // const [clientPagesState, clientPagesDispatch] = useReducer(new reducer("CATEGORY", clientPagesName), initState, initializer);
    // const clientPagesAction = new ClientPagesAction(clientPagesDispatch);
    // categories
    const [clientCategoriesState, clientCategoriesDispatch] = useReducer(new reducer("CATEGORY", clientCategoriesName), initState, initializer);
    const clientCategoriesAction = new ClientCategoriesAction(clientCategoriesDispatch);

    useEffect(
        () => {
            console.log("Category.useEffect1: ", categoryId);
            if ( window.location?.href ) {
                AnalyticsExtension.sendPageView(window.location.href);
            }
            props.doSetLoading(true);
            clientCategoriesAction.details({ serviceData: categoryId });
            // clientPagesAction.get({
            //     serviceData: {
            //         filter: [
            //             `categoryId eq ${categoryId}`, "and",
            //             "isActive eq true", "and",
            //             "languageVersion ne null"
            //         ]
            //     },
            //     orderBy: ["issueDate desc"],
            //     top: 6,
            //     skip: 0
            // });
        },
        [categoryId, language]
    )

    useEffect(
        () => {
            console.log("Category.useEffect2: ", language);
            props.doChangeLanguage(language);
        },
        [language]
    )

    useEffect(
        () => {
            if ( !Lodash.isEmpty(clientCategoriesState.details.payload) ) {
                setTimeout(() => { props.doSetLoading(false); }, 300);
            }
        },
        [ clientCategoriesState.details.isLoading ]
    )
    
    return (
        <React.Fragment>
            <ParallaxHeader resources={clientCategoriesState.details.payload?.resources}
                            isLoading={clientCategoriesState.details.isLoading}
                            description={clientCategoriesState.details.payload?.languageVersion?.description}
            />
            <main role={"main"} style={{paddingTop: 0}}>
                <ShowroomPage payload={clientCategoriesState.details.payload} 
                              isLoading={clientCategoriesState.details.isLoading}
                              language={language}
                              verboseResources={props.verboseResources}
                />
                <InnerPage payload={clientCategoriesState.details.payload}
                           isLoading={clientCategoriesState.details.isLoading}
                           language={language}
                           currentLanguage={props.currentLanguage}
                           verboseResources={props.verboseResources}
                />
            </main>
        </React.Fragment>
    );
}

const mapStateToProps = function (state, props) {
    console.log("Category.mapStateToProps", state);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;
    const verboseResources = state.CoreReducer.VerboseResourcesReducer.list.payload;

    return {
        currentLanguage,
        verboseResources
    }
}

const mapDispatchToProps = function (dispatch, props) {
    console.log("Category.mapDispatchToProps");
    const appAction = new AppAction(dispatch);

    return {
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
        doChangeLanguage: ( language ) => {
            appAction.changeLanguage( language );
        },
    }
}

const Component = React.memo(Category, (prevProps, nextProps) => {
    console.log("---- Category.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( nextProps.currentLanguage ) {
        console.log("Category.doRender");
        return false;
    }

    console.log("Category.doNotRender");
    return true;
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);