import ReadOnlyActionBase from "../../../core/action/ReadOnlyActionBase";
import ClientPagesService from "../../service/ClientPagesService";
export { name } from '../../service/ClientPagesService'

export default class ClientPagesAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientPagesService, pageName);
    }

}