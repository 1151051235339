import { combineReducers } from 'redux';
import ReducerBase from '../ReducerBase';

// Static
const pageName = "APP";
const serviceName = "APP";

const baseReducer = new ReducerBase(pageName, serviceName);

export default combineReducers({
    ...baseReducer.APP_LANGUAGE(),
    ...baseReducer.APP_LOADING(),
    ...baseReducer.CHANGE(),
});
