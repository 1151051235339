import { EndpointRoute } from './EndpointRoute';

export const CMSDefault = {
    endpointRoutes: EndpointRoute,
    defaultRoutes: {},

    siteLogo: "/assets/img/logo/logo_512.png",

    siteLogo512: "/assets/img/logo/logo_512.png",
    siteLogo256: "/assets/img/logo/logo_256.png",
    siteLogo128: "/assets/img/logo/logo_128.png",
    siteLogo64: "/assets/img/logo/logo_64.png",
    siteLogo32: "/assets/img/logo/logo_32.png",

    defaultResourceTypes: {
        Parallax: "Parallax",
        Pattern: "Pattern",
        Slider: "Slider",
        OutterImage: "OutterImage",
        InnerImage: "InnerImage",
        Video: "Video",
        Image: "Image",
        Data: "Data",
    },

    defaultLayouts: {
        MenuItem: "MenuItem",
        MenuGroup: "MenuGroup",

        Slider: "Slider",
        SliderGroup: "SliderGroup",

        MainPage: "MainPage",
        MainPageGroup: "MainPageGroup",

        Inner: "Inner",
        Outter: "Outter",
        Showroom: "Showroom",
        Gallery: "Gallery"
    },

    defaultContentTypeName: {
        Page: "Page",
        Category: "Category"
    },

    monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]

};