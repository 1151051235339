import React from "react";
import { connect } from "react-redux";

// layout
import {MVBGoogleMaps, MVBContactForm} from "../../component";
import Item from "./components/item";
import {FaMapMarkedAlt, FaPhoneAlt, FaEnvelope} from "react-icons/fa";

const INNR_004 = ({ phoneText, addressText, emailText, phoneNumber, faxNumber, email, address, isLoading }) => (
    <main role={"main"} style={{paddingTop: 0}}>
        <section className="section">
            <div className="container">

                <div className="company-contacts  text-center">
                    <div className="__inner">
                        <div className="row justify-content-around">

                            <div className="col-12 col-md-4 col-lg-3">
                                <Item isLoading={isLoading} 
                                        title={addressText} 
                                        content={address} 
                                        icon={<FaMapMarkedAlt className={"__ico"} />} 
                                />
                            </div>

                            <div className="col-12 col-md-4 col-lg-3">
                                <Item isLoading={isLoading} 
                                        title={phoneText} 
                                        content={`${phoneNumber || ""}<br />${faxNumber || ""}`}
                                        icon={<FaPhoneAlt className={"__ico"} />}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-lg-3">
                                <Item isLoading={isLoading} 
                                        title={emailText} 
                                        content={email} 
                                        icon={<FaEnvelope className={"__ico"} />} 
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section className="section section--dark-bg  section--contacts">
            <MVBContactForm />

            <div className="row no-gutters">
                <MVBGoogleMaps />
            </div>
        </section>
    </main>
);

const mapStateToProps = function (state, props) {
    console.log("INNR_004.mapStateToProps: ", state);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;

    let phoneNumber = state.CMSReducer.SiteSettingsReducer?.list?.payload?.[0].phoneNumber,
        faxNumber = state.CMSReducer.SiteSettingsReducer?.list?.payload?.[0].faxNumber,
        email = state.CMSReducer.SiteSettingsReducer?.list?.payload?.[0].email,
        address = state.CMSReducer.SiteSettingsReducer?.list?.payload?.[0].address;

    const phoneText = state.CoreReducer.VerboseResourcesReducer.list.payload?.find(x=>x.key === "Phone")?.value;
    const addressText = state.CoreReducer.VerboseResourcesReducer.list.payload?.find(x=>x.key === "Address")?.value;
    const emailText = state.CoreReducer.VerboseResourcesReducer.list.payload?.find(x=>x.key === "Email")?.value;

    const isLoading = state.CMSReducer.SiteSettingsReducer?.list?.isLoading;

    return {
        currentLanguage,

        phoneNumber,
        faxNumber,
        email,
        address,

        phoneText,
        addressText,
        emailText,

        isLoading
    }
}

const Component = React.memo(INNR_004, (prevProps, nextProps) => {
    console.log("---- INNR_004.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.isLoading !== nextProps.isLoading || prevProps.currentLanguage) {
        console.log("INNR_004.doRender");
        return false;
    }

    console.log("INNR_004.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);