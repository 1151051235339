import React from "react";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import MVBImage from "../MVBImage";
import DateTimeExtension from "../../extension/DateTimeExtension";
import MVBLink from "../MVBLink";

export default function OuterItem4 ({ id, categoryId, categoryRouteName, contentId, isNavigationDestination, redirectType, language, resources, languageVersion, issueDate, moreAboutText }) {
    const _date = DateTimeExtension( issueDate, language );

    return (
        <div key={id} className="__item text-center">
            <figure className="__image">
                <MVBImage payload={resources} alt={languageVersion?.name} style={{paddingTop: "10px", paddingBottom: "10px"}} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
            </figure>
            <div className="__content">
                <h4 className="h6 __title h6__max-height-2-line text-left">
                    <MVBLink
                        title={languageVersion?.name}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={categoryId}
                        categoryRouteName={categoryRouteName}
                        contentId={id}
                        contentRouteName={languageVersion?.routeName}
                    />
                </h4>

                <div className="__category pb-5 text-left">{ _date?.year }</div>

                <MVBLink
                    title={moreAboutText}
                    isNavigationDestination={isNavigationDestination}
                    redirectUrl={languageVersion?.redirectUrl}

                    redirectType={redirectType}

                    languageCode={language}
                    categoryId={categoryId}
                    categoryRouteName={categoryRouteName}
                    contentId={id}
                    contentRouteName={languageVersion?.routeName}
                    className={"custom-btn custom-btn--medium custom-btn--style-2"}
                />
            </div>
        </div>
    );

}