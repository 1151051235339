import React from 'react';
import MVBNavLink from "../../MVBNavLink";
import PropTypes from "prop-types";

const Content = ({ language, title, payload }) => (
    <React.Fragment>
        <h5 className={"footer__item__title h6"}>
            { title }
        </h5>
        <ul>
            {
                payload?.map(item =>
                    <li key={item.id}>
                        <MVBNavLink
                            title={item?.languageVersion?.name}
                            isNavigationDestination={item.isNavigationDestination}
                            redirectUrl={item?.languageVersion?.redirectUrl}

                            redirectType={item?.redirectType}
                            activeClassName={'font-weight-bold'}

                            languageCode={language}
                            
                            categoryId={item?.categoryId}
                            categoryRouteName={item?.categoryRouteName}
                            contentId={item?.contentId}
                            contentRouteName={item?.languageVersion?.routeName}
                            contentTypeName={item?.contentTypeName}

                            isMatchActive={true}
                        />
                    </li>
                )
            }
        </ul>
    </React.Fragment>
);

Content.propTypes = {
    language: PropTypes.string.isRequired,

    title: PropTypes.string.isRequired,
    payload: PropTypes.array.isRequired,
}

Content.defaultProps = {
    language: "en-US",

    title: "",
    payload: [],
}

export default Content;