import React from "react";
import ContentLoader from "react-content-loader";

const phone = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
        <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
        <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
        <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
        <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
);

const tablet = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
        <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
        <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
        <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
        <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
);

const desktop = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={200}
        height={200}
        backgroundColor={isDarkMode ? "#e0e0e0": "#f3f3f3"}
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
        <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
        <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
        <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
        <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @param isDarkMode { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop, isDarkMode = false} ) {

    if ( isPhone ) return phone(isDarkMode);
    else if ( isTablet ) return tablet(isDarkMode);
    else if ( isDesktop ) return desktop(isDarkMode);

}