import React from 'react';
import Loader from './components/loader'
import Items from "./components/items";
import PropTypes from "prop-types";
import MVBLink from "../MVBLink";


const SubNavigationBarLayout1 = ({ payload, items, language, onClick }) => {
    console.log("SubNavigationBarLayout1.rendered: ", payload, items);
    
    // TODO: Buranın li tagi içine isActive nasıl koyulacak.

    return (
        <React.Fragment>
            {
                items.length > 0 ?
                    <ul className={"submenu"} style={{display: "block"}}>
                        {
                            items?.map(item => {
                                return <li key={item.id}>
                                    <MVBLink
                                        title={item?.languageVersion?.name}
                                        isNavigationDestination={item.isNavigationDestination}
                                        redirectUrl={item?.languageVersion?.redirectUrl}

                                        redirectType={item?.redirectType}

                                        languageCode={language}

                                        categoryId={item?.categoryId}
                                        categoryRouteName={item?.categoryRouteName}
                                        contentId={item?.contentId}
                                        contentRouteName={item?.languageVersion?.routeName}
                                        contentTypeName={item?.contentTypeName}

                                        isMatchActive={false}
                                        onClick={onClick}
                                    />
                                </li>
                            })
                        }
                    </ul> : null
            }
        </React.Fragment>
    );
}

SubNavigationBarLayout1.propTypes = {
    payload: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
}

SubNavigationBarLayout1.defaultProps = {
    payload: [],
    items: [],
    language: "",
}

export default SubNavigationBarLayout1;