import ReadOnlyActionBase from "../../../../core/action/ReadOnlyActionBase";
import SiteSettingsService from "../../../service/setting/SiteSettingsService";
export { name } from '../../../service/setting/SiteSettingsService'

export default class SiteSettingsAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, SiteSettingsService, pageName);
    }

}