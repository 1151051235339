import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import MVBNavLink from "../../MVBNavLink";


const verifyRouteName = (value) => value?.startsWith("/") ? value : `/${value}`;

const Items = ({ payload, items }) => {
    console.log("NavigationBarLayout1.Item: ", payload);

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    return (
        <React.Fragment>
            <nav id={"top-bar__navigation"} className={"top-bar__navigation navigation"} role={"navigation"}>
                <ul>
                    {
                        items.map( item => {
                            const _items = payload.filter(x=>x.parentId === item.id);
                            const _isActive = location.pathname === `/${language}${verifyRouteName(categoryName)}${verifyRouteName(categoryId)}`;
                            return (
                                <React.Fragment>
                                    <li key={item.id} className={`${_items.length>0?"has-submenu":''} ${_isActive?"active":''}`}>
                                        <MVBNavLink
                                            title={item?.languageVersion?.name}
                                            isNavigationDestination={item.isNavigationDestination}
                                            redirectUrl={item?.languageVersion?.redirectUrl}

                                            redirectType={item?.redirectType}
                                            activeClassName={'font-weight-bold'}

                                            languageCode={language}
                                            
                                            categoryId={item?.categoryId}
                                            categoryRouteName={item?.categoryRouteName}
                                            contentId={item?.contentId}
                                            contentRouteName={item?.languageVersion?.routeName}
                                            contentTypeName={item?.contentTypeName}

                                            isMatchActive={false}
                                        />
                                    </li>
                                </React.Fragment>
                            );
                        })
                    }
                </ul>
            </nav>
        </React.Fragment>
    );
}

Items.propTypes = {
    payload: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
}

Items.defaultProps = {
    payload: [],
    items: [],
}

export default Items;