import CoreReducerBase from './CoreReducerBase'
import {
    LIST,
    POST,
    PATCH,
    MANAGEMENT,
    DELETE,
    DETAILS,
    ACTION_SPECIFICATIONS,
    APP_LANGUAGE,
    APP_LOADING,
    CHANGE
} from '../type/ActionProtocolTypes';

export default class ReducerBase {

    constructor(pageName, serviceName) {
        this.serviceName = serviceName;
        this.pageName = pageName;
    }

    // default
    LIST() {
        return { list: CoreReducerBase( this.pageName, this.serviceName, LIST ) }
    }

    POST() {
        return { post: CoreReducerBase( this.pageName,  this.serviceName, POST ) }
    }

    PATCH() {
        return { patch: CoreReducerBase( this.pageName,  this.serviceName, PATCH ) }
    }

    MANAGEMENT() {
        return { management: CoreReducerBase( this.pageName,  this.serviceName, MANAGEMENT ) }
    }

    DELETE() {
        return { delete: CoreReducerBase( this.pageName,  this.serviceName, DELETE ) }
    }

    DETAILS() {
        return { details: CoreReducerBase( this.pageName,  this.serviceName, DETAILS ) }
    }

    ACTION_SPECIFICATIONS() {
        return { actionSpecifications: CoreReducerBase( this.pageName,  this.serviceName, ACTION_SPECIFICATIONS ) }
    }

    // others
    APP_LANGUAGE() {
        return { appLanguage: CoreReducerBase( this.pageName,  this.serviceName, APP_LANGUAGE ) }
    }

    APP_LOADING() {
        return { appLoading: CoreReducerBase( this.pageName,  this.serviceName, APP_LOADING, false, false, { payload: true } ) }
    }

    CHANGE () {
        return { change: CoreReducerBase( this.pageName, this.serviceName, CHANGE ) }
    }

}
