import ReadOnlyActionBase from "../../../../core/action/ReadOnlyActionBase";
import VerboseResourcesService from "../../../../core/service/resources/VerboseResourcesService";
export { name } from '../../../../core/service/resources/VerboseResourcesService'

export default class VerboseResourcesAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, VerboseResourcesService, pageName);
    }

}