import React from 'react';
import {connect} from "react-redux";
import Loader from './components/loader'
import Items from "./components/items";
import AppAction from "../../../core/action/app/AppAction";


const LanguageNavigationBarLayout1 = ({ payload, isLoading, value, doSetLoading }) => {
    console.log("LanguageNavigationBarLayout1.rendered: ",  payload, isLoading, value);

    return (
        <React.Fragment>
            {
                isLoading ?
                    <Loader isDesktop={true} /> :
                    <Items payload={payload} value={value} onClick={event => {  console.log("Langauge on Click"); doSetLoading(true) }} />
            }
        </React.Fragment>
    );
}


const mapStateToProps = function (state, props) {
    console.log("LanguageNavigationBarLayout1.mapStateToProps: ", state);

    const payload = state.CoreReducer.LanguageReducer.list.payload;
    const isLoading = state.CoreReducer.LanguageReducer.list.isLoading;

    const value = state.CoreReducer.AppReducer.appLanguage.payload;

    return {
        value,

        payload,
        isLoading,
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("LanguageNavigationBarLayout1.mapDispatchToProps");
    const appAction = new AppAction(dispatch);

    return {
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
    }
}

const Component = React.memo(LanguageNavigationBarLayout1, (prevProps, nextProps) => {
    console.log("---- LanguageNavigationBarLayout1.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if (
        prevProps.value?.id !== nextProps.value?.id ||
        prevProps.isLoading !== nextProps.isLoading
    ) {
        console.log("LanguageNavigationBarLayout1.doRender");
        return false;
    }

    console.log("LanguageNavigationBarLayout1.doNotRender");
    return true;
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);