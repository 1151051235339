import CoreActionBase from "../../CoreActionBase";
import LanguageService from "../../../service/language/LanguageService";


export default class LanguageServiceAction extends CoreActionBase {

    /**
     * LanguageServiceAction
     * @param dispatch { any }
     * @param pageName { string }
     */
    constructor( dispatch, pageName ) {
        super(dispatch, LanguageService, pageName);
    }

}