import React from 'react';
import PropTypes from "prop-types";
import Lodash from "lodash";
import {NavLink} from "react-router-dom";
import ContentTypeEnum from "../../enum/ContentTypeEnum"

const verifyRouteName = (value) => value.startsWith("/") ? value : `/${value}`;
const navlinkIsActive = (url, isMatchActive, match, location) => {
    const _pathname = location.pathname.split("/");
    if ( url === `/${_pathname[1]}/${_pathname[2]}/${_pathname[4]}` ) {
        return true;
    }
    else if ( (isMatchActive && match !== null) || match?.url === location.pathname ) {
        return true;
    }
    return false;
}

const MVBNavLink = ({ title, isNavigationDestination, redirectUrl, languageCode, categoryRouteName, categoryId, contentRouteName, contentId, routeName, redirectType, className, activeClassName, isMatchActive, onClick, contentTypeName }) => {
    const _redirectType = redirectType === "StayAtCurrentWebSite" || Lodash.isEmpty(redirectType) ? "_self" : "_blank";

    // isNavigationDestination === false
    if ( !isNavigationDestination ) {
        return <a style={{cursor: "pointer"}} to="#">{title}</a>
    }

    // redirect other locations
    else if ( !Lodash.isEmpty(redirectUrl) ) {
        return <NavLink className={className} to={redirectUrl} target={_redirectType} activeClassName={activeClassName}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || redirectUrl }
        </NavLink>
    }
    // this is category ( for menu links )
    else if ( contentTypeName === ContentTypeEnum.CATEGORY ) {
        const _url = `/${languageCode}${verifyRouteName(contentRouteName)}/${contentId}`;
        return <NavLink className={className} to={_url} target={_redirectType} activeClassName={activeClassName}
                        isActive={(match, location) => navlinkIsActive(_url, isMatchActive, match, location)}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title }
        </NavLink>
    }
    // this is page ( for menu links )
    // else if ( contentTypeName === ContentTypeEnum.PAGE ) {

    // }
    // this is page
    else if ( !Lodash.isEmpty(categoryRouteName) && !Lodash.isEmpty(contentRouteName) ) {
        const _url = `/${languageCode}${verifyRouteName(categoryRouteName)}${verifyRouteName(contentRouteName)}/${categoryId}/${contentId}`;
        return <NavLink className={className} to={_url} target={_redirectType} activeClassName={activeClassName}
                        isActive={(match, location) => navlinkIsActive(_url, isMatchActive, match, location)}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title }
        </NavLink>
    }
    // this is category (showroom)
    else if ( !Lodash.isEmpty(categoryRouteName) ) {
        const _url = `/${languageCode}${verifyRouteName(categoryRouteName)}/${categoryId}`;
        return <NavLink className={className} to={_url} target={_redirectType} activeClassName={activeClassName}
                        isActive={(match, location) => navlinkIsActive(_url, isMatchActive, match, location)}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title }
        </NavLink>
    }
    // this is full path ( page or category (showroom) )
    else if ( !Lodash.isEmpty(routeName) ) {
        const _url = `/${languageCode}${verifyRouteName(routeName)}`;
        return <NavLink className={className} to={_url} target={_redirectType} activeClassName={activeClassName}
                        isActive={(match, location) => navlinkIsActive(_url, isMatchActive, match, location)}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title }
        </NavLink>
    }
    // this is only language code
    else if ( !Lodash.isEmpty(languageCode) ) {
        const _url = `/${languageCode}`;
        return <NavLink className={className} to={_url} target={_redirectType} activeClassName={activeClassName}
                        isActive={(match, location) => navlinkIsActive(_url, isMatchActive, match, location)}
                        onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title }
        </NavLink>
    }

    return title || redirectUrl;
}

MVBNavLink.propTypes = {
    title: PropTypes.string.isRequired,

    isNavigationDestination: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string,

    languageCode: PropTypes.string.isRequired,
    categoryRouteName: PropTypes.string,
    categoryId: PropTypes.string,
    contentRouteName: PropTypes.string,
    contentId: PropTypes.string,
    routeName: PropTypes.string,

    redirectType: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string.isRequired,
    isMatchActive: PropTypes.bool,
}

MVBNavLink.defaultProps = {
    title: "",

    isNavigationDestination: "",
    redirectUrl: "",

    languageCode: "",
    categoryRouteName: "",
    categoryId: "",
    contentRouteName: "",
    contentId: "",
    routeName: "",

    redirectType: "_self",
    className: "",
    activeClassName: "",
    isMatchActive: false,
}

export default MVBNavLink;