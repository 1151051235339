import React, {useEffect, useReducer} from "react";
import Lodash from 'lodash';
import {useParams} from "react-router-dom";
// actions
import ClientMainPageItemsAction, { name as mainPageItemsServiceName } from "./actions/ClientMainPageItemsAction";
// reducers
import reducer, { initState, initializer } from '../../../core/reducer/SimpleReducer';
// components
import Loader from './components/loader';
import Content from './components/content';

export default function MNPG_001 ({ index, id, verboseResources, currentLanguage }) {
    console.log("MNPG_001.rendered: ");

    // constants
    const { language } = useParams();

    // mainPageItems
    const [mainPageItemsState, mainPageItemsDispatch] = useReducer(new reducer("MNPG_001", mainPageItemsServiceName), initState, initializer);
    const mainPageItemsAction = new ClientMainPageItemsAction(mainPageItemsDispatch);

    useEffect(
        () => {
            console.log("MNPG_001.useEffect.1: ", language);
            if ( !Lodash.isEmpty(id) ) {
                mainPageItemsAction.details({ serviceData: id });
            }

            return function cleanup() {

            }
        },
        [ currentLanguage ]
    );

    return (
        <div className="row align-items-center">
            {
                mainPageItemsState.details.isLoading ?
                    <Loader isDesktop={true} isDarkMode={index%2===1} /> :
                    <Content
                        leftContent={mainPageItemsState?.details.payload?.languageVersion?.description}
                        rightContent={mainPageItemsState?.details.payload?.languageVersion?.body}

                        {...mainPageItemsState?.details.payload}
                        //resources={mainPageItemsState?.details.payload?.resources}

                        language={language}
                        //languageVersion={mainPageItemsState?.details.payload?.languageVersion}

                        //routeName={mainPageItemsState?.details.payload?.routeName}

                        //redirectType={mainPageItemsState?.details.payload?.redirectType}
                        //isNavigationDestination={mainPageItemsState.details.payload?.isNavigationDestination}

                        verboseResources={verboseResources}
                    />
            }
        </div>
    );
}