import React from "react";
import PropTypes from "prop-types";
import MVBImage from "../MVBImage";
import MVBLink from "../MVBLink";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import DateTimeExtension from "../../extension/DateTimeExtension";

export default function ShowroomPageItem1 ({ id, categoryId, categoryRouteName, isNavigationDestination, resources, redirectType, issueDate, languageVersion, language }) {
    const _date = DateTimeExtension(issueDate, language);

    return (
        <div className="col-12 col-sm-6 col-lg-3  js-isotope__item  category-1">
            <div className="__item">
                <figure className="__image" style={{border: "2px solid #DADADA"}}>
                    <MVBImage payload={resources} className={"__image"} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />

                    <div className="__content">
                        <h5 className="__content__title">{_date?.year}</h5>
                        <span>{languageVersion?.name}</span>
                    </div>

                    <MVBLink
                        title={" "}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={categoryId}
                        categoryRouteName={categoryRouteName}
                        contentId={id}
                        contentRouteName={languageVersion?.routeName}
                        className={"__link"}
                    />
                </figure>
            </div>
        </div>
    );
}

ShowroomPageItem1.propTypes = {
    isNavigationDestination: PropTypes.bool.isRequired,
    resources: PropTypes.array.isRequired,
    routeName: PropTypes.string.isRequired,
    redirectType: PropTypes.string.isRequired,
    issueDate: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    languageVersion: PropTypes.object.isRequired,
}

ShowroomPageItem1.defaultProps = {
    isNavigationDestination: false,
    resources: [],
    routeName: null,
    redirectType: null,
    issueDate: null,
    language: null,
    languageVersion: null
}