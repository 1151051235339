export const EndpointRoute = {
	api: process.env.API,
	cdn: process.env.CDN,

	// odata
	language: {
		prefix: 'client-languages'
	},

	// api
	applicationDocuments: {
		prefix:'application-documents'
	}
}
