import React, {useState} from "react";
import PropTypes from "prop-types";

export default function StringSearch ({ onSubmit, onClear, disabled, buttonTitleFind, buttonTitleClear, searchPlaceholder }) {

    const [ text, setText ] = useState("");

    function submitOnClick(e) {
        e.preventDefault();
        onSubmit(text);
    }

    function clearOnClick(e) {
        e.preventDefault();

        setText("");
        onClear(null);
    }

    return (
        <section className="section section--no-pb">
            <div className="container">
                <div className="widget widget--search">
                    <form className="form--horizontal" action="#" method="get">
                        <div className="input-wrp">
                            <input
                                className="textfield"
                                name="FTS"
                                type="text"
                                placeholder={searchPlaceholder}
                                disabled={disabled}
                                value={text}
                                onChange={event => { setText(event.target.value) }}
                            />
                        </div>
                        <button
                            className={`custom-btn custom-btn--tiny ${disabled ? 'custom-btn--style-disabled' : 'custom-btn--style-1'}`}
                            type="submit"
                            role="button"
                            onClick={submitOnClick}
                            disabled={disabled}
                        >
                            {buttonTitleFind}
                        </button>

                        {
                            text?.length > 0 ?
                                <button
                                    className="custom-btn custom-btn--tiny custom-btn--style-2 ml-2"
                                    role="button"
                                    onClick={clearOnClick}
                                    disabled={disabled}
                                >
                                    {buttonTitleClear}
                                </button> :
                                null
                        }

                    </form>
                </div>
            </div>
        </section>
    );

}

StringSearch.propTypes = {
    buttonTitleFind: PropTypes.string.isRequired,
    buttonTitleClear: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,

    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,

    disabled: PropTypes.bool,
}

StringSearch.defaultProps = {
}