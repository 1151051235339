import { CMSDefault }	from '../../CMSDefault';
import ReadOnlyOdataServiceBase from "../../../core/service/ReadOnlyOdataServiceBase";


export const name = "ILWYRZ";
export default class SocialMediaService extends ReadOnlyOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.socialMedia.prefix,
            name: name
        });
    }

}
