import ActionBase from "../../../core/action/ActionBase";
import ContactUsService from "../../service/ContactUsService";
export { name } from '../../service/ContactUsService'

export default class ContactUsAction extends ActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ContactUsService, pageName);
    }

}