import React from "react";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import MVBLink from "../MVBLink";
import MVBImage from "../MVBImage";

/**
 * Outer Item 1 Type 1
 * Soldan resimli olan bölüm
 */
export const OuterItem1Type1 = ({ id, categoryId, categoryRouteName, isNavigationDestination, redirectType, resources, language, languageVersion }) => (
    <React.Fragment>
        {
            id ?
                <div key={id} className="col-12 col-lg-6">
                    <div className="__item">
                        <div className="row no-gutters align-items-center">
                            <div className="col-12 col-md-6 align-self-md-stretch">
                                <figure className="__image">
                                    <MVBImage payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
                                </figure>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="__content">
                                    <h3 className="__title h5">
                                        <MVBLink
                                            title={languageVersion?.name}
                                            isNavigationDestination={isNavigationDestination}
                                            redirectUrl={languageVersion?.redirectUrl}

                                            redirectType={redirectType}

                                            languageCode={language}
                                            categoryId={categoryId}
                                            categoryRouteName={categoryRouteName}
                                            contentId={id}
                                            contentRouteName={languageVersion?.routeName}
                                        />
                                    </h3>
                                    <div className={"ck-content"}
                                         dangerouslySetInnerHTML={{__html: languageVersion?.description}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
        }
    </React.Fragment>
);

/**
 * Outer Item 1 Type 2
 * Sağdan resimli olan bölüm
 */
export const OuterItem1Type2 = ({ id, categoryId, categoryRouteName, isNavigationDestination, redirectType, resources, language, languageVersion }) => (
    <React.Fragment>
        {
            id ?
                <div key={id} className="col-12 col-lg-6">
                    <div className="__item">
                        <div className="row flex-md-row-reverse no-gutters align-items-center">
                            <div className="col-12 col-md-6 align-self-md-stretch">
                                <figure className="__image">
                                    <MVBImage payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
                                </figure>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="__content">
                                    <h3 className="__title h5">
                                        <MVBLink
                                            title={languageVersion?.name}
                                            isNavigationDestination={isNavigationDestination}
                                            redirectUrl={languageVersion?.redirectUrl}

                                            redirectType={redirectType}

                                            languageCode={language}
                                            categoryId={categoryId}
                                            categoryRouteName={categoryRouteName}
                                            contentId={id}
                                            contentRouteName={languageVersion?.routeName}
                                        />
                                    </h3>
                                    <div className={"ck-content"}
                                         dangerouslySetInnerHTML={{__html: languageVersion?.description}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
        }
    </React.Fragment>
);