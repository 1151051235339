import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default function LoadMore ({ buttonTitleLoading, buttonTitleLoadMore, disabled, isLoading, onClick, Loader }) {
    function submitOnClick(e) {
        e.preventDefault();
        onClick();
    }

    return (
        <React.Fragment>
            {
                isLoading ? 
                    <div style={{ marginTop: "30px" }}>
                        <Loader isDesktop={true} />
                    </div> : 
                    <div className="text-center">
                        <Link
                            id="gallery-more-btn"
                            className={"custom-btn custom-btn--medium custom-btn--style-2"}
                            to={"#"}
                            onClick={submitOnClick}
                            disabled={!isLoading}
                        >
                            { buttonTitleLoadMore }
                        </Link>
                    </div>
            }
        </React.Fragment>
    );

}

LoadMore.propTypes = {
    buttonTitleLoadMore: PropTypes.string.isRequired,
    buttonTitleLoading: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,

    onClick: PropTypes.func.isRequired,

    Loader: PropTypes.element.isRequired,
}

LoadMore.defaultProps = {
}