import ActionBaseWithDispatcher from "./ActionBaseWithDispatcher";
import {
    FETCHING,
    FETCH_SUCCESS,
    FETCH_FAIL,
    FETCH_CLEAN
} from "../type/ActionResultTypes";
import {
    POST
} from "../type/ActionProtocolTypes";


export default class ActionBase extends ActionBaseWithDispatcher{

    /**
     * odata service GET action
     * @param params { Object|null }
     * @param params.sourcePath { string|null }
     * @param params.serviceData { OdataQueryOptionsModel }
     * @param params.args { Object }
     */
    async post( params) {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${POST}${FETCHING}` });
        this._run( this.service.post.bind(this.service), Object.assign({}, params, {args:{...params.args, actionType: POST}}) );
    }

    /**
     * clean POST action
     */
    cleanPost () {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${POST}${FETCH_CLEAN}` });
    }

    callback200(response, args) {
        super.callback200(response, args);

        if ( args.actionType === POST ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${POST}${FETCH_SUCCESS}`, payload: response.value });
        }
    }

    callback400(response, args) {
        super.callback400(response, args);

        if ( args.actionType === POST ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${POST}${FETCH_FAIL}`, payload: response.value });
        }
    }

    callback406(response, args) {
        super.callback406(response, args);

        if ( args.actionType === POST ) {
            this.dispatch({ type: `${this.pageName}${this.serviceName}${POST}${FETCH_FAIL}`, payload: response.value });
        }
    }

}

