import React, {useEffect, useReducer, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
// component
import Loader from './components/loader'
import Content from './components/content'
import {LoadMoreButton, StringSearch} from "../../component";
// action
import ClientPagesAction, { name as clientPagesName } from "./actions/ClientPagesAction";
// reducer
import reducer, { initState, initializer } from '../../../core/reducer/SimpleReducer';

const SHWRM_003 = ({ verboseResources, currentLanguage, appIsLoading }) => {
    console.log("SHWRM_003.rendered: ", verboseResources);
    const findText = verboseResources?.find(x=>x.key === "Find")?.value;
    const clearText = verboseResources?.find(x=>x.key === "Clear")?.value;
    const searchText = verboseResources?.find(x=>x.key === "Search")?.value;
    const loadMoreText = verboseResources?.find(x=>x.key === "LoadMore")?.value;
    const loadingText = verboseResources?.find(x=>x.key === "Loading")?.value;
    const readMoreText = verboseResources?.find(x=>x.key === "ReadMore")?.value;

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    //
    const [settings, setSettings] = useState({ itemsPerPage: 6, isPageLoaded: false, isLoadMore: false, isFind: false });
    const [filter, setFilter] = useState({ filter: [], order: ["issueDate desc"] });
    const [items, setItems] = useState([]);

    // pages
    const [clientPagesState, clientPagesDispatch] = useReducer(new reducer("SHWRM_003", clientPagesName), initState, initializer);
    const clientPagesAction = new ClientPagesAction(clientPagesDispatch);

    useEffect(
        () => {
            console.log("SHWRM_003.useEffect1: ", categoryId, language, currentLanguage.twoLetterISOName, settings.isLoadMore);

            if ( currentLanguage.twoLetterISOName === language ) {
                clientPagesAction.get({
                    serviceData: {
                        filter: [
                            `categoryId eq ${categoryId}`, 
                            "and", "isActive eq true", 
                            "and", "languageVersion ne null",
                            ...filter.filter,
                        ],
                        orderBy: filter.order,
                        top: settings.itemsPerPage,
                        skip: settings.isFind ? 0 : items.length
                    },
                });
            } else {
                console.log("SHWRM_003.useEffect1.clean ");
                setItems([]);
                setSettings(prevProps => ({ ...prevProps, isPageLoaded: false, isLoadMore: false, isFind: false }));
                setFilter(prevProps => ({ ...prevProps, filter: [] }));
            }
        },
        [categoryId, language, currentLanguage, filter]
    )

    useEffect(
        () => {
            console.log("SHWRM_003.useEffect2: ", categoryId, language, currentLanguage.twoLetterISOName, settings.isLoadMore);
            if ( currentLanguage.twoLetterISOName === language && settings.isLoadMore ) {
                clientPagesAction.get({
                    serviceData: {
                        filter: [
                            `categoryId eq ${categoryId}`, 
                            "and", "isActive eq true", 
                            "and", "languageVersion ne null",
                            ...filter.filter,
                        ],
                        orderBy: filter.order,
                        top: settings.itemsPerPage,
                        skip: settings.isFind ? 0 : items.length
                    },
                });
            }
        },
        [settings.isLoadMore]
    )

    useEffect(
        () => {
            console.log("SHWRM_003.useEffect3: ", clientPagesState.list.isLoading, clientPagesState.list.payload?.length, settings);
            if (clientPagesState.list.isLoading === false && clientPagesState.list.payload?.length >= 0) {
                if ( settings.isFind ) {
                    setItems(prevProps => ([ ...clientPagesState.list.payload ]));
                    setSettings(prevProps => ({ ...prevProps, isPageLoaded: true, isFind: false }));
                    clientPagesAction.cleanGet();
                } else {
                    if ( !settings.isFind && !settings.isLoadMore ) {
                        setItems(prevProps => ([ ...clientPagesState.list.payload ]));
                    } else {
                        setItems(prevProps => ([ ...prevProps, ...clientPagesState.list.payload ]));
                    }
                    setSettings(prevProps => ({ ...prevProps, isPageLoaded: true, isLoadMore: false }));
                    clientPagesAction.cleanGet();
                }
            }
        },
        [clientPagesState.list.payload]
    )

    return (
        <React.Fragment>
            <StringSearch
                buttonTitleFind={findText}
                buttonTitleClear={clearText}
                searchPlaceholder={searchText}
                onSubmit={value => {
                    setSettings(prevProps => ({...prevProps, isFind: true}));
                    const _filter = [
                        `contains(toLower(languageVersion/name), '${value.toLowerCase()}')`,
                        `contains(toLower(languageVersion/description), '${value.toLowerCase()}')`,
                        `contains(toLower(languageVersion/body), '${value.toLowerCase()}')`
                    ];
                    setFilter(prevProps => ({ ...prevProps, filter: ["and", `(${_filter.join(" or ")})`] }))
                }}
                onClear={value => {
                    setSettings(prevProps => ({...prevProps, isFind: true}));
                    setFilter(prevProps => ({ ...prevProps, filter: [] }))
                }}
                disabled={clientPagesState.list.isLoading}
            />
            <section className="section">
                <div className="container">
                    {
                        clientPagesState.list.isLoading && ( !settings.isPageLoaded || settings.isFind ) ?
                            <Loader isDesktop={true} /> :
                            <div className="posts posts--style-1">
                                <div className="__inner">
                                    <div className="row">
                                        <Content payload={items} 
                                                 language={language} 
                                                 readMoreText={readMoreText}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                    <LoadMoreButton isLoading={clientPagesState.list.isLoading}
                                    buttonTitleLoadMore={loadMoreText}
                                    buttonTitleLoading={loadingText}
                                    //disabled={clientPagesState.list.isLoading}
                                    onClick={() => {
                                        setSettings(prevProps => ({...prevProps, isLoadMore: true, isFind: false}));
                                    }}
                                    Loader={Loader}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = function (state, props) {
    console.log("SHWRM_003.mapStateToProps", state, props);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;
    const appIsLoading = state.CoreReducer.AppReducer.appLoading.payload;

    const verboseResources = state.CoreReducer.VerboseResourcesReducer.list.payload;
    const verboseResourcesisLoading = state.CoreReducer.VerboseResourcesReducer.list.isLoading;

    return {
        currentLanguage,
        appIsLoading,

        verboseResources,
        verboseResourcesisLoading,
    }
}

const Component = React.memo(SHWRM_003, (prevProps, nextProps) => {
    console.log("---- SHWRM_003.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( 
        prevProps.currentLanguage.id !== nextProps.currentLanguage.id ||
        prevProps.verboseResourcesisLoading !== nextProps.verboseResourcesisLoading
       ) {
        console.log("SHWRM_003.doRender");
        return false;
    }

    console.log("SHWRM_003.doNotRender");
    return true;
})

export default connect(mapStateToProps, null)(Component);