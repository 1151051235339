import { CMSDefault }	from '../CMSDefault';
import ReadOnlyOdataServiceBase from "../../core/service/ReadOnlyOdataServiceBase";


export const name = "YFHUZQ";
export default class ClientMainPageItemsService extends ReadOnlyOdataServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.clientMainPageItems.prefix,
            name: name
        });
    }

}
