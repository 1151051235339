import { combineReducers } from 'redux';
import ReducerBase from '../../ReducerBase';
import { name as serviceName } from '../../../service/language/LanguageService'

export default function LanguageReducer( pageName ) {
    const baseReducer = new ReducerBase(pageName, serviceName);

    return combineReducers({
        ...baseReducer.LIST()
    });
}
