import {default as _ContactUsAction}  from "../../../action/service/ContactUsAction";

export { name }  from "../../../action/service/ContactUsAction";
const pageName = "ContactUs"

export default class ContactUsAction extends _ContactUsAction {

    constructor(dispatch) {
        super(dispatch, pageName);
    }

}