import { CMSDefault }	from '../../../cms/CMSDefault';
import ApiServiceBase from "../ApiServiceBase";


export const name = "UUMKNE";
export default class VerboseResourcesService extends ApiServiceBase {

    /**
     *
     */
    constructor () {
        super({
            sourcePath: CMSDefault.endpointRoutes.verboseResources.prefix,
            name: name
        });
    }

}
