import React from 'react';
import PropTypes from "prop-types";
import Lodash from "lodash";
import {Link} from "react-router-dom";
import ContentTypeEnum from "../../enum/ContentTypeEnum"

const verifyRouteName = (value) => value.startsWith("/") ? value : `/${value}`;

const MVBLink = ({ title, isNavigationDestination, redirectUrl, languageCode, categoryRouteName, categoryId, contentRouteName, contentId, routeName, redirectType, onClick, className, contentTypeName, children, isButton }) => {
    const _redirectType = redirectType === "StayAtCurrentWebSite" || redirectType === "_self" || Lodash.isEmpty(redirectType) ? "_self" : "_blank";

    // isNavigationDestination === false
    if ( !isNavigationDestination ) {
        if ( isButton ) {
            return null;
        } else {
            return <a style={{"pointerEvents": "none", "cursor": "default"}} to="#">{ title || children }</a>;
        }
        
    }

    // other situations
    else if ( !Lodash.isEmpty(redirectUrl) ) {
        return <Link className={className}
                     to={redirectUrl}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || redirectUrl || children }
        </Link>
    }
    // this is category ( for menu links )
    else if ( contentTypeName === ContentTypeEnum.CATEGORY ) {
        const _url = `/${languageCode}${verifyRouteName(contentRouteName)}/${contentId}`;
        return <Link className={className} 
                     to={_url}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || children }
        </Link>
    }
    else if ( !Lodash.isEmpty(categoryRouteName) && !Lodash.isEmpty(contentRouteName) ) {
        return <Link className={className}
                     to={`/${languageCode}${verifyRouteName(categoryRouteName)}${verifyRouteName(contentRouteName)}/${categoryId}/${contentId}`}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || children }
        </Link>
    }
    else if ( !Lodash.isEmpty(categoryRouteName) ) {
        return <Link className={className}
                     to={`/${languageCode}${verifyRouteName(categoryRouteName)}/${categoryId}`}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || children }
        </Link>
    }
    else if ( !Lodash.isEmpty(routeName) ) {
        return <Link className={className}
                     to={`/${languageCode}${verifyRouteName(routeName)}`}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || children }
        </Link>
    }
    else if ( !Lodash.isEmpty(languageCode) ) {
        return <Link className={className}
                     to={`/${languageCode}`}
                     target={_redirectType}
                     onClick={(event) => { if ( onClick ) onClick(event) }}
        >
            { title || children }
        </Link>
    }

    return title || redirectUrl || children;
}

MVBLink.propTypes = {
    title: PropTypes.string.isRequired,

    isNavigationDestination: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string,

    languageCode: PropTypes.string.isRequired,
    categoryRouteName: PropTypes.string,
    categoryId: PropTypes.string,
    contentRouteName: PropTypes.string,
    contentId: PropTypes.string,
    routeName: PropTypes.string,

    redirectType: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isButton: PropTypes.bool,
}

MVBLink.defaultProps = {
    title: "",

    isNavigationDestination: "",
    redirectUrl: "",

    languageCode: "",
    categoryRouteName: "",
    categoryId: "",
    contentRouteName: "",
    contentId: "",
    routeName: "",

    redirectType: "_self",
    onClick: null,
    className: "",
    isButton: false,
}

export default MVBLink;