import LanguageServiceAction from '../service/language/LanguageServiceAction';


export default class LanguageAction extends LanguageServiceAction {

    constructor( dispatch ) {
        const pageName = "APP"
        super( dispatch, pageName )
    }

}
