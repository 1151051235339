import GoogleMapReact from 'google-map-react';
import {CoreDefault} from "../../../core/CoreDefault";


export default function MVBGoogleMaps( props ) {

    return (
        <div className={"col-md-6 map-container map-container--left"}>
            {/*<GoogleMapReact*/}
            {/*    class="g_map"*/}
            {/*    style={{minHeight: "255px"}}*/}
            {/*    bootstrapURLKeys={{ key: "AIzaSyC18L1VDXVL4bVUvaEGCnfCpFZ64oLKKsM" }}*/}
            {/*    defaultCenter={{ lat: props.lat||CoreDefault.googleMaps.defaultCenter.lat, lng: props.long||CoreDefault.googleMaps.defaultCenter.long }}*/}
            {/*    defaultZoom={props.zoom||CoreDefault.googleMaps.defaultZoom}*/}
            {/*/>*/}

            <iframe
                scrolling="no"
                marginHeight="255px"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%C3%9Cniversiteler%20Mah.%20Dumlup%C4%B1nar%20Bulvar%C4%B1,%20No:%20161+(ECORCCFS)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                frameBorder="0"
                className="g_map"
            />
        </div>
    );

}