import React from "react";
import ContentLoader from "react-content-loader";

const phone = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="300" height="400" />

        <rect x="330" y="0" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="25" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="50" rx="3" ry="3" width="30%" height="20" />

        <rect x="330" y="90" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="115" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="140" rx="3" ry="3" width="100%" height="20" />

        <rect x="330" y="180" rx="3" ry="3" width="200" height="50" />
    </ContentLoader>
);

const tablet = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="300" height="400" />

        <rect x="330" y="0" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="25" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="50" rx="3" ry="3" width="30%" height="20" />

        <rect x="330" y="90" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="115" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="140" rx="3" ry="3" width="100%" height="20" />

        <rect x="330" y="180" rx="3" ry="3" width="200" height="50" />
    </ContentLoader>
);

const desktop = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"400px"}
        backgroundColor={isDarkMode ? "#e0e0e0": "#f3f3f3"}
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="300" height="400" />

        <rect x="330" y="0" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="25" rx="3" ry="3" width="30%" height="20" />
        <rect x="330" y="50" rx="3" ry="3" width="30%" height="20" />
        
        <rect x="330" y="90" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="115" rx="3" ry="3" width="100%" height="20" />
        <rect x="330" y="140" rx="3" ry="3" width="100%" height="20" />

        <rect x="330" y="180" rx="3" ry="3" width="200" height="50" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @param isDarkMode { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop, isDarkMode = false} ) {

    if ( isPhone ) return phone(isDarkMode);
    else if ( isTablet ) return tablet(isDarkMode);
    else if ( isDesktop ) return desktop(isDarkMode);

}