import React, {useEffect, useReducer} from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
// layouts - components
import Skeleton from "./components/Skeleton";
import Item1 from "./components/Item1";
import SlickButtonComponent from "./components/SlickButtonComponent";
// defaults
import { settings } from './default'
// reducers
import reducer, { initState, initializer } from '../../../core/reducer/SimpleReducer';
// actions
import CarouselAction, { name as serviceName } from "./action";


function Carousel (props) {
    console.log("Carousel.rendered: ", props);

    // constants
    // const { language } = useParams();

    const [state, dispatch] = useReducer(new reducer("carousel", serviceName), initState, initializer);
    const carouselAction = new CarouselAction(dispatch);

    useEffect(
        () => {
            if ( props.currentLanguage?.id > 0 ) {
                carouselAction.get({
                    serviceData: {
                        orderBy: ['order asc'],
                        filter: ['isActive eq true', "and", "languageVersion ne null"]
                    }
                })
            }
        },
        [props.currentLanguage]
    )

    return (
        <React.Fragment>
            {
                state.list.isLoading ?
                    <Skeleton isDesktop={true} /> :
                    <Slider
                        id={"start-screen"}
                        className={"start-screen start-screen--style-4 js-slick"}
                        {...settings}
                        prevArrow={<SlickButtonComponent><i className={"fontello-left-open"} /></SlickButtonComponent>}
                        nextArrow={<SlickButtonComponent><i className={"fontello-right-open"}/></SlickButtonComponent>}
                    >
                        {
                            state.list.payload?.map(item => (
                               <Item1
                                    {...item}
                                    languageCode={props.currentLanguage.twoLetterISOName}
                                />
                            ))
                        }
                    </Slider>
            }
        </React.Fragment>
    );

}

const mapStateToProps = function (state) {
    console.log("Carousel.mapStateToProps: ", state);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;

    return {
        currentLanguage
    }
}

const Component = React.memo(Carousel,(prevProps, nextProps) => {
    console.log("---- Carousel.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.currentLanguage?.id !== nextProps.currentLanguage?.id ) {
        console.log("Carousel.doRender");
        return false;
    }

    console.log("Carousel.doNotRender");
    return true;
})

export default connect(mapStateToProps, null)(Component);