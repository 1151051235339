import React from "react";
import PropTypes from "prop-types";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import MVBLink from "../MVBLink";
import MVBImage from "../MVBImage";
import DateTimeExtension from "../../extension/DateTimeExtension";

export default function OuterItem2 ({ id, categoryId, categoryRouteName, contentId, isNavigationDestination, redirectType, language, resources, languageVersion, issueDate, readMoreText }) {
    const _date = DateTimeExtension( issueDate, language );

    return (
        <React.Fragment>
            {
                id ?
                    <div key={id} className="col-12 col-sm-6 col-lg-4">
                        <div className="__item __item--preview">
                            <figure className="__image">
                                <MVBImage payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
                            </figure>

                            <div className="__content">

                                <h3 className="__title h5">
                                    <MVBLink
                                        title={languageVersion?.name}
                                        isNavigationDestination={isNavigationDestination}
                                        redirectUrl={languageVersion?.redirectUrl}

                                        redirectType={redirectType}

                                        languageCode={language}
                                        categoryId={categoryId}
                                        categoryRouteName={categoryRouteName}
                                        contentId={id}
                                        contentRouteName={languageVersion?.routeName}
                                    />
                                </h3>
                                <div className={"ck-content"} dangerouslySetInnerHTML={{__html: languageVersion?.description}}/>
                                <MVBLink
                                    title={readMoreText}
                                    isNavigationDestination={isNavigationDestination}
                                    redirectUrl={languageVersion?.redirectUrl}

                                    redirectType={redirectType}

                                    languageCode={language}
                                    categoryId={categoryId}
                                    categoryRouteName={categoryRouteName}
                                    contentId={id}
                                    contentRouteName={languageVersion?.routeName}
                                    className={"custom-btn custom-btn--tiny custom-btn--style-1"}
                                    isButton={true}
                                />
                            </div>

                            {
                                _date ?
                                    <span className="__date-post"><strong>{_date?.day}</strong>{_date?.monthName} {_date?.year}</span> :
                                    null
                            }
                        </div>
                    </div> : null
            }
        </React.Fragment>
    );
}