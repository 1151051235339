import { CoreDefault }		from '../../CoreDefault';
import CoreOdataServiceBase	from '../CoreOdataServiceBase';


export const name = "LEKJVI";
export default class LanguageService extends CoreOdataServiceBase {
	
	/**
	 *
	 */
	constructor () {
		super({
			sourcePath: CoreDefault.endpointRoutes.language.prefix,
			name: name
		});
	}
	
}
