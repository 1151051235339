import React from 'react';
import ResourceTypeEnum from "../../../enum/ResourceTypeEnum";
import { ParallaxBanner } from "react-scroll-parallax";
import { CoreDefault } from '../../../../core/CoreDefault';

const Content = ({ resources, description, speed }) => {
    let imageSrc = resources?.find(item => item.resourceTypeName === ResourceTypeEnum.PARALLAX)?.path;
    imageSrc = imageSrc ? `${CoreDefault.endpointRoutes.cdn}${imageSrc}` : null;

    return (
        <React.Fragment>
            <ParallaxBanner
                layers={[{ amount: 1, image: imageSrc, expanded: true }]}
                className={"hero"}
                style={{height: "inherit"}}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7 ck-content" dangerouslySetInnerHTML={{ __html: description }}/>
                    </div>
                </div>
            </ParallaxBanner>
        </React.Fragment>
    )
}

export default Content;