import React from "react";
import PropTypes from "prop-types";
import MVBImage from "../MVBImage";
import MVBLink from "../MVBLink";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";

export default function ShowroomPageItem2 ({ id, categoryId, categoryRouteName, isNavigationDestination, resources, redirectType, languageVersion, language }) {
    return (
        <div className="col-12 col-sm-6 col-lg-4 js-isotope__item category-1">
            <div className="__item">
                <figure className="__image __country-flag">
                    <MVBImage payload={resources} className={"__image"} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
                    {/* <img src={props.image.src} alt={props.image.alt} /> */}

                    <div className="__content">
                        <span>{languageVersion?.name}</span>
                    </div>

                    <MVBLink
                        title={" "}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={categoryId}
                        categoryRouteName={categoryRouteName}
                        contentId={id}
                        contentRouteName={languageVersion?.routeName}
                        className={"__link"}
                    />
                </figure>

                <div className="__contry-flag-title text-center">{ languageVersion?.name }</div>
            </div>
        </div>
    );
}

ShowroomPageItem2.propTypes = {
    isNavigationDestination: PropTypes.bool.isRequired,
    resources: PropTypes.array.isRequired,
    routeName: PropTypes.string.isRequired,
    redirectType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    languageVersion: PropTypes.object.isRequired,
}

ShowroomPageItem2.defaultProps = {
    isNavigationDestination: false,
    resources: [],
    routeName: null,
    redirectType: null,
    language: null,
    languageVersion: null
}