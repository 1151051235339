import React from "react";
import { Header_001, Header_002 } from "../index";


const Header = ( props ) => {
    console.log("Header.rendered", props);

    return (
        <React.Fragment>
            <Header_001 />
        </React.Fragment>
    );
}

export default Header;