import React from "react";

import Loader from './components/loader';
import Content from './components/content';
import "./style.scss"

const INNR_005 = ({ resources, languageVersion, isLoading, language, currentLanguage, verboseResources }) => {
    const labelNoDataToShow = verboseResources?.find(item => item.key === "NoDataToShow")?.value;
    const labelPleaseSelectToSeeData = verboseResources?.find(item => item.key === "PleaseSelectToSeeData")?.value;
    const labelClearAll = verboseResources?.find(item => item.key === "ClearAll")?.value;
    const labelShowAll = verboseResources?.find(item => item.key === "ShowAll")?.value;
    const labelDimension = verboseResources?.find(item => item.key === "Dimension")?.value;
    const labelLoading = verboseResources?.find(item => item.key === "Loading")?.value;
    
    return (
        <main role={"main"} style={{paddingTop: 0}}>
            <section className={"section"}>
                <div className={"container"}>
                    {
                        isLoading ?
                            <Loader isDesktop={true} /> :
                            <Content isLoading={isLoading} 
                                     language={language} 
                                     resources={resources} 
                                     currentLanguage={currentLanguage}
                                     labelNoDataToShow={labelNoDataToShow}
                                     labelPleaseSelectToSeeData={labelPleaseSelectToSeeData}
                                     labelClearAll={labelClearAll}
                                     labelShowAll={labelShowAll}
                                     labelDimension={labelDimension}
                                     labelLoading={labelLoading}
                            />
                    }
                </div>
            </section>
        </main>
    );
}

export default INNR_005;

// import React, { createRef, useCallback, useEffect, useState } from "react";
// import { connect } from "react-redux";
// import { Link } from "react-router-dom";

// //
// import { CoreDefault } from "../../../core/CoreDefault";
// // layout
// import {Loading} from "../index";
// // action
// import ApplicationDocumentAction from "../../../cms/action/Layout/LayoutType/Inner/INNR005/ApplicationDocumentAction";
// import ClientPagesAction from "../../../cms/action/Layout/LayoutType/Inner/INNR005/ClientPagesAction";
// // static
// import Highcharts from "highcharts";
// import HighchartsExporting from 'highcharts/modules/exporting'
// import HighchartsExportData from 'highcharts/modules/export-data'
// import HighchartsReact from "highcharts-react-official";
// import './style.scss'
// import {CMSDefault} from "../../CMSDefault";
// HighchartsExporting(Highcharts);
// HighchartsExportData(Highcharts);


// function INNR_005 (props) {
//     console.log("INNR_005.rendered: ", props);

//     const refHighCharts = createRef();

// 	const [ options, setOptions ] = useState({
// 		disabled: false,
// 		loading: false,
// 		showMessage: false
// 	});

//     const [ dimensionOptions, setDimensionOptions ] = useState([]);
//     const [ dimensionTypeOptions, setDimensionTypeOptions ] = useState([]);
//     const [ dimension, setDimension ] = useState("");
//     const [ dimensionName, setDimensionName ] = useState("");

//     useEffect(
// 		() => {
// 			console.log("INNR_005.useEffect1");

// 			props.doShowLoadingMessage();
// 			// if (window?.location?.pathname) ReactGA.pageview(window.location.pathname);
// 			// console.log("GoogleAnalytics.INNR_005: ", window.location.pathname);

// 			if ( props.contentTypeName === CMSDefault.defaultContentTypeName.Category ) {
// 				let index = props.categoryPayload.resources.find(x => x.name === "index.json");
// 				if ( index ) {
// 					props.doGetDocument({
// 						serviceData: { url: `${CoreDefault.endpointRoutes.cdn}${index.path}` },
// 						args: {
// 							source: props.componentKey,
// 							dataType: "index"
// 						}
// 					});
// 				}
// 			} else if ( props.contentTypeName === CMSDefault.defaultContentTypeName.Page ) {

// 			}

// 			return function cleanup () {
// 				props.doClean();
// 			}
// 		},
// 		[]
// 	);

// 	useEffect(
// 		() => {
// 			console.log("INNR_005.useEffect2: ", props.indexPayload);

// 			if ( props.indexPayload && dimensionOptions.length === 0 ) {
// 				try {
// 					let keys = Object.keys(props.indexPayload);
// 					let dimensionOptions = keys.map(x => <option key={x} value={`${x}`}>{props.indexPayload[x].name}</option>);
// 					dimensionOptions = [ <option key={"selectDimension"} value={""}>Please Select</option>, ...dimensionOptions ];
// 					setDimensionOptions(dimensionOptions);
// 				} catch (e) {}

// 				setTimeout(() => {
// 					props.doHideLoadingMessage()
// 				}, 300);
// 			}
// 			else if ( props.valuePayload ) {
// 				setOptions((prevState) => ({ ...prevState, disabled: false, loading: false, showMessage: false }));
// 			}

// 			// return function cleanup () {
// 			// 	props.doCleanDocument();
// 			// }

// 		},
// 		[ props.indexIsLoading, props.valueIsLoading ]
// 	);

//     const onDimensionChange = useCallback(
// 		(e) => {
// 			if ( e.target.value.length > 0 ) {
// 				let values = props.indexPayload[e.target.value].value
// 				let keys = Object.keys(values);
// 				let dimensionTypesOptions = keys.map(x => <option key={x} value={`${x}`}>{values[x]}</option>);
// 				dimensionTypesOptions = [ <option key={"selectDimensionType"} value={""}>Please Select</option>, ...dimensionTypesOptions ];
// 				setDimensionTypeOptions(dimensionTypesOptions);
// 				document.getElementById("dimensionType").classList.remove("d-none");
// 				setDimension(e.target.value);
// 				setDimensionName(props.indexPayload[e.target.value].name);
// 			}
// 		},
// 		[ props.indexPayload, dimensionOptions ]
// 	);
// 	const onDimensionTypeChange = useCallback(
// 		(e) => {
// 			setOptions((prevState) => ({ ...prevState, disabled: true, loading: true, showMessage: false }));

// 			let element = document.getElementsByClassName("highcharts-data-table");
// 			if ( element.length > 0 ) {
// 				element[0].parentNode.removeChild(element[0]);
// 			}

// 			let dimensionType = e.target.value;
// 			let index = props.categoryPayload.resources.find(x => x.name === `${dimension}-${dimensionType}.json`);
// 			if ( index ) {
// 				props.doGetDocument({
// 					serviceData: { url: `${CoreDefault.endpointRoutes.cdn}${index.path}` },
// 					args: {
// 						source: props.componentKey,
// 						dataType: "value"
// 					}
// 				});
// 			} else {
// 				setOptions((prevState) => ({ ...prevState, disabled: true, loading: false, showMessage: true }));

// 			}

// 		},
// 		[ dimension, props ]
// 	);

// 	const removeUnnecessaryLine = function () {

// 		const interval = setInterval(() => {
// 			const element = document.getElementsByClassName("highcharts-credits");
// 			if ( element?.length > 0 ) {
// 				element[0].remove();
// 				clearInterval(interval);
// 			}
// 		}, 500);

// 	}

// 	function toggle_chart_series( status ) {
// 		if ( refHighCharts.current ) {
// 			for(let i=0; i<refHighCharts.current.chart.series.length; i++) {
// 				if( status )
// 					refHighCharts.current.chart.series[i].show();
// 				else
// 					refHighCharts.current.chart.series[i].hide();
// 			}
// 		}
// 	}

// 	const renderData = useCallback(
// 		() => {
// 			if ( options.loading === true ) {
// 				return (
// 					<div id="hc-container">
// 						<div id="chart-container">
// 							<Loading isCenter={true} />
// 						</div>
// 					</div>
// 				);
// 			}

// 			if ( !props.valuePayload || (props.valuePayload && props.valuePayload.length === 0) || options.showMessage ) {
// 				return (
// 					<div id="hc-container">
// 						<div id="chart-container">
// 							<div className="container">
// 								<div className="row">
// 									<div className="col-12">
// 										<h5 className="text-center">
// 											{props.labelPleaseSelectToSeeData}
// 										</h5>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				);
// 			}

// 			removeUnnecessaryLine();
// 			contentId
// 			return (
// 				<React.Fragment>
// 					<div className={"py-4"}>
// 						<Link className="custom-btn custom-btn--tiny custom-btn--style-1 mr-2" to={"#"} onClick={(e) => { e.preventDefault(); toggle_chart_series(false); }}>{props.labelClearAll}</Link>
// 						<Link className="custom-btn custom-btn--tiny custom-btn--style-1" to={"#"} onClick={(e) => { e.preventDefault(); toggle_chart_series(true); }}>{props.labelShowAll}</Link>
// 					</div>

// 					<div id="hc-container" className={"scrolling-container"}>
// 						<div id="chart-container" className={"chart-container"}>
// 							<HighchartsReact
// 								ref={refHighCharts}
// 								options={{
// 									chart: {
// 										type:"spline"
// 									},
// 									title: {
// 										text: props.valuePayload['title'].replaceAll("/", "\\")
// 									},
// 									subtitle: {
// 										text: ''
// 									},
// 									yAxis: {
// 										title: {
// 											text: ['yAxis']['title']
// 										},
// 										gridLineWidth: 1
// 									},
// 									xAxis: {
// 										accessibility: {
// 											rangeDescription: props.valuePayload['xAxis']['title']
// 										},
// 										type: "category",
// 										categories: props.valuePayload['xAxis']['categories'],
// 										gridLineWidth: 1
// 									},
// 									loading: {
// 										hideDuration: 0,
// 										showDuration: 0,
// 										style: {
// 											backgroundColor: '#ffffff',
// 										}
// 									},
// 									exporting:{
// 										showTable: true
// 									},
// 									tooltip: {
// 										formatter: function() {
// 											let point = this.point,
// 												series = this.series,
// 												pointIndex = point.index,
// 												text,
// 												unit, flag_description;

// 											unit = series.userOptions.data[pointIndex][2];
// 											flag_description = series.userOptions.data[pointIndex][3];

// 											text = '<span style="color:' +
// 												this.color + '">\u25CF</span> ' +
// 												series.name +
// 												': <b>' +
// 												this.y + " " + unit+
// 												'</b><br/>';

// 											text += flag_description;

// 											return text;
// 										}
// 									},

// 									legend: {
// 										layout: 'vertical',
// 										itemMarginBottom:7,
// 										align: 'left',
// 										verticalAlign: 'middle',
// 										itemStyle: {
// 											fontSize: '14px',
// 										}
// 									},

// 									series: props.valuePayload['series'],

// 									responsive: {
// 										rules: [{
// 											condition: {
// 												maxWidth: 500
// 											},
// 											chartOptions: {
// 												legend: {
// 													layout: 'horizontal',
// 													align: 'center',
// 													verticalAlign: 'bottom'
// 												}
// 											}
// 										}]
// 									}
// 								}}
// 								highcharts={Highcharts}
// 							/>
// 						</div>
// 					</div>
// 				</React.Fragment>
// 			);
// 		},
// 		[ props.valuePayload, props.labelPleaseSelectToSeeData, options ]
// 	);

//     return (
// 		<React.Fragment>
// 			{/* <ParallaxHeader
// 				src={props.parallax?.src}
// 				description={props.parallax?.description}
// 			/> */}

// 			<main role={"main"}>
// 				<section className="section">
// 					<div className="container">
// 						<div className="row">
// 							<div className="col-12 col-lg-12 col-xl-12 mb-6">
// 								<div className="row">
// 									<div id={"dimension"} className="col-md-4">
// 										<label>{props.labelDimension}</label>
// 										<select className="custom-select d-block w-100" onChange={onDimensionChange}>
// 											{
// 												dimensionOptions.length > 0 ? dimensionOptions : <option value="">Loading...</option>
// 											}
// 										</select>
// 									</div>
// 									<div id={"dimensionType"} className="col-md-6 d-none">
// 										<label>{dimensionName}</label>
// 										<select className="custom-select d-block w-100" onChange={onDimensionTypeChange}>
// 											{
// 												dimensionTypeOptions.length > 0 ? dimensionTypeOptions : <option value="">Loading...</option>
// 											}
// 										</select>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="col-12 col-lg-12 col-xl-12 mt-6">
// 								{ renderData() }
// 							</div>
// 						</div>
// 					</div>
// 				</section>
// 			</main>
// 		</React.Fragment>
//     );
// }

// const mapStateToProps = function (state, props) {
// 	console.log("INNR_005.mapStateToProps: ", state, props);

// 	let indexPayload = null, indexIsLoading = null, valuePayload = null, valueIsLoading = null;

// 	if ( state.CMSReducer.INNR005Reducer.indexPayload ) {
// 		indexPayload = state.CMSReducer.INNR005Reducer.indexPayload;
// 		indexIsLoading = state.CMSReducer.INNR005Reducer.indexIsLoading;
// 	}

// 	if ( state.CMSReducer.INNR005Reducer.valuePayload ) {
// 		valuePayload = state.CMSReducer.INNR005Reducer.valuePayload;
// 		valueIsLoading = state.CMSReducer.INNR005Reducer.valueIsLoading;
// 	}

// 	const labelDimension = state.CoreReducer.VerboseResourcesReducer?.payload?.Dimension;
// 	const labelPleaseSelectToSeeData = state.CoreReducer.VerboseResourcesReducer?.payload?.PleaseSelectToSeeData;

// 	const labelClearAll = state.CoreReducer.VerboseResourcesReducer?.payload?.ClearAll;
// 	const labelShowAll = state.CoreReducer.VerboseResourcesReducer?.payload?.ShowAll;

// 	return {
// 		// index
// 		indexIsLoading,
// 		indexPayload,
// 		// value
// 		valueIsLoading,
// 		valuePayload,
// 		// other
// 		labelDimension,
// 		labelPleaseSelectToSeeData,
// 		labelClearAll,
// 		labelShowAll
// 	}
// }

// const mapDispatchToProps = function (dispatch) {
// 	console.log("INNR_005.mapDispatchToProps");

//     const applicationDocumentAction = new ApplicationDocumentAction(dispatch);

// 	return {
// 		doGetDocument: (params) => { applicationDocumentAction.download(params) },
// 		doClean: () => {
// 			applicationDocumentAction.clean();
// 		},
// 	}
// }

// const Component = React.memo(INNR_005, (prevProps, nextProps) => {
// 	console.log("---- INNR_005.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

// 	if ( prevProps.indexIsLoading !== nextProps.indexIsLoading || prevProps.valueIsLoading !== nextProps.valueIsLoading ) {
// 		console.log("INNR_005.doRender");
// 		return false;
// 	}

// 	console.log("INNR_005.doNotRender");
// 	return true;
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Component);