import React from "react";
import ContentLoader from "react-content-loader";

const phone = (
    <ContentLoader
        rtl
        speed={2}
        width={"100%"}
        height={"80px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="10" rx="3" ry="3" width="290" height="1" />

        <rect x="0" y="20" rx="3" ry="3" width="150" height="40" />
        <rect x="160" y="20" rx="3" ry="3" width="150" height="40" />
        {/*<rect x="320" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="480" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="640" y="20" rx="3" ry="3" width="150" height="40" />*/}
    </ContentLoader>
);

const tablet = (
    <ContentLoader
        rtl
        speed={2}
        width={"100%"}
        height={"80px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="10" rx="3" ry="3" width="290" height="1" />

        <rect x="0" y="20" rx="3" ry="3" width="150" height="40" />
        <rect x="160" y="20" rx="3" ry="3" width="150" height="40" />
        {/*<rect x="320" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="480" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="640" y="20" rx="3" ry="3" width="150" height="40" />*/}
    </ContentLoader>
);

const desktop = (
    <ContentLoader
        rtl
        speed={2}
        width={"100%"}
        height={"80px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="10" rx="3" ry="3" width="290" height="1" />

        <rect x="0" y="20" rx="3" ry="3" width="150" height="40" />
        <rect x="160" y="20" rx="3" ry="3" width="150" height="40" />
        {/*<rect x="320" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="480" y="20" rx="3" ry="3" width="150" height="40" />*/}
        {/*<rect x="640" y="20" rx="3" ry="3" width="150" height="40" />*/}
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop} ) {

    if ( isPhone ) return phone;
    else if ( isTablet ) return tablet;
    else if ( isDesktop ) return desktop;

}