import React from "react";

export const pageName = "carousel";

export const settings = {
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
};