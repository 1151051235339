import React from 'react';
import Helmet from 'react-helmet';
// enums
import LayoutTypeEnum from "../../enum/LayoutTypeEnum";
// layouts
import SHWRM_001 from "../shwrm_001";
import SHWRM_002 from "../shwrm_002";
import SHWRM_003 from "../shwrm_003";
import INNR_004 from "../innr_004";

const Items = {
    SHWRM_001,
    SHWRM_002,
    SHWRM_003,
    INNR_004,
}

const ShowroomPage = ({ payload, verboseResources }) => {
    const layout = payload?.layouts?.find(item => item.layoutTypeName === LayoutTypeEnum.SHOWROOM);
    const Component = Items[layout?.layoutName];
    return Component ? 
        <React.Fragment>
            <Helmet>
                <title>{ payload.languageVersion?.name }</title>
                {/*<meta name="description" content={} />*/}
                {/*<meta name="twitter:card" content="summary_large_image" />*/}
                {/*<meta name="twitter:site" content="@user" />*/}
                {/*<meta name="twitter:creator" content="@user" />*/}
                {/*<meta name="twitter:title" content="Pets - Products" />*/}
                {/*<meta name="twitter:description" content="Best Products for your pet" />*/}
                {/*<meta name="twitter:image" content="url_to_image"/>*/}
                {/*<meta property="og:title" content="Pets - Products" />*/}
                {/*<meta property="og:description" content="Best Products for your pet" />*/}
                {/*<meta property="og:image" content="url_to_image"/>*/}
                {/*<meta property="og:url" content="pets.abc" />*/}
                {/*<meta property="og:site_name" content="Pets - Products" />*/}
                {/*<meta property="og:locale" content="en_US" />*/}
                {/*<meta property="og:type" content="article" />*/}
                {/*<meta property="fb:app_id" content="ID_APP_FACEBOOK" />*/}
            </Helmet>
            <Component verboseResources={verboseResources} /> 
        </React.Fragment> : null
}

export default ShowroomPage;