import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Footer_001, Footer_002 } from '../index'

const Footer = ( props ) => {
    console.log("Footer.rendered", props);

    return (
        <React.Fragment>
            <Footer_001 />
        </React.Fragment>
    );
}

const mapStateToProps = function(state, props) {
    console.log("Footer.mapStateToProps", state, props);

    return {

    }
}

const Component = React.memo(Footer, (prevProps, nextProps) => {
    console.log("---- Footer.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.isLoading !== nextProps.isLoading ) {
        console.log("Footer.doRender");
        return false;
    }

    console.log("Footer.doNotRender");
    return true;
})

export default connect(mapStateToProps, null)(Component);