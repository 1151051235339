import ReadOnlyActionBase from "../../../core/action/ReadOnlyActionBase";
import ClientCategoriesService from "../../service/ClientCategoriesService";
export { name } from '../../service/ClientCategoriesService'

export default class ClientPagesAction extends ReadOnlyActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientCategoriesService, pageName);
    }

}