import { ApiFetch }       	from '../FetchRequest';
import { ApiProtocolEnum }	from '../enum/ApiProtocolEnum';


export default class ServiceBase {

    /**
     *
     * @param params
     * @param params.sourcePath { string }
     * @param params.language { string }
     * @param params.name { string }
     */
    constructor ({sourcePath, language, name}) {
        this._sourcePath = sourcePath
        this._lang = language;
        this.name = name
    }

}

