import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory, useLocation } from 'react-router';
// components
import { Carousel, HomeSection } from "../../cms/component";
import { MainPageItem } from "../../cms/layout";
// actions
import ClientMainPageItemsAction from "../../cms/action/screen/home/ClientMainPageItemsAction";
//
import AppAction from "../../core/action/app/AppAction";
import AnalyticsExtension from "../../core/extension/AnalyticsExtension";


function Home (props) {
    console.log("Home.rendered", props);

    const history = useHistory();
    const location = useLocation();

    useEffect(
        () => {
            if ( window.location?.href ) {
                AnalyticsExtension.sendPageView(window.location.href);
            }
            
            return function cleanup() {
                props.doSetLoading(true);
            }
        },
        []
    )

    useEffect(
        () => {
            if ( props.currentLanguage?.twoLetterISOName !== props?.match?.params?.language) {
                props.doChangeLanguage(props?.match?.params?.language);
            }
        },
        [props.match]
    )

    useEffect(
        () => {
            props.doGetMainPageItems( ['order asc'], ['isActive eq true'] );
        },
        [props.currentLanguage]
    )

    return (
        <main role="main">
            <HomeSection backgroundColor={"gray"} accessory={<span className="scroll-discover" />} >
                <Carousel />
            </HomeSection>
            {
                props.payload?.map((item, index) => <MainPageItem payload={item} index={index} verboseResources={props.verboseResources} currentLanguage={props.currentLanguage} /> )
            }
        </main>
    )
}

const mapStateToProps = function (state, props) {
    console.log("Home.mapStateToProps", state);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;

    const payload = state.CMSReducer.ClientMainPageItemsReducer.list.payload;
    const payloadIsLoading = state.CMSReducer.ClientMainPageItemsReducer.list.payload;

    const verboseResources = state.CoreReducer.VerboseResourcesReducer.list.payload;
    const verboseResourcesIsLoading = state.CoreReducer.VerboseResourcesReducer.list.payload;

    const isLoading = payloadIsLoading || verboseResourcesIsLoading;

    return {
        currentLanguage,

        payload,
        verboseResources,

        isLoading,
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("Home.mapDispatchToProps");
    const clientMainPageItemsAction = new ClientMainPageItemsAction(dispatch);
    const appAction = new AppAction(dispatch);

    return {
        doGetMainPageItems: (order, filter) => {
            clientMainPageItemsAction.get({
                serviceData:{
                    orderBy: order,
                    filter: filter
                }
            })
        },
        doChangeLanguage: ( language ) => {
            appAction.changeLanguage( language );
        },
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
    }
}

const Component = React.memo(Home,(prevProps, nextProps) => {
    console.log("---- Home.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if (
        prevProps.currentLanguage?.id !== nextProps.currentLanguage?.id ||
        nextProps.currentLanguage?.twoLetterISOName !== nextProps?.match?.params?.language ||
        prevProps.isLoading !== nextProps.isLoading
    ) {
        console.log("Home.doRender");
        return false;
    }

    console.log("Home.doNotRender");
    return true;
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);
