import React from 'react';
import {connect} from "react-redux";
import Loader from './components/loader'
import Items from "./components/items";

import AppAction from "../../../core/action/app/AppAction";

const NavigationBarLayout1 = ({ payload, isLoading, doSetLoading }) => {
    console.log("NavigationBarLayout1.rendered: ", payload, isLoading);

    return (
        <React.Fragment>
            {
                isLoading || payload === null ?
                    <Loader isDesktop={true} /> :
                    <Items payload={payload} items={payload?.filter(x=>x.parentId === null)} onClick={event => { doSetLoading(true); }} />
            }
        </React.Fragment>
    );
}

const mapStateToProps = function (state, props) {
    console.log("NavigationBarLayout1.mapStateToProps: ", state);

    const payload = state.CMSReducer.ClientMenuItemsReducer.list.payload?.filter(x=>x.mapLayoutName === "MNGRP_001");
    const isLoading = state.CMSReducer.ClientMenuItemsReducer.list.isLoading;

    return {
        payload,
        isLoading
    }
}

const mapDispatchToProps = function (dispatch) {
    console.log("NavigationBarLayout1.mapDispatchToProps");
    const appAction = new AppAction(dispatch);

    return {
        doSetLoading: ( state ) => {
            appAction.setLoading( state );
        },
    }
}

const Component = React.memo(NavigationBarLayout1, (prevProps, nextProps) => {
    console.log("---- NavigationBarLayout1.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.isLoading !== nextProps.isLoading ) {
        console.log("NavigationBarLayout1.doRender");
        return false;
    }

    console.log("NavigationBarLayout1.doNotRender");
    return true;
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);