export const EndpointRoute = {
	// setting
	siteSetting: {
		prefix: 'client-site-settings'
	},
	socialMedia: {
		prefix: 'client-social-medias'
	},

	clientContents: {
		prefix: 'client-contents'
	},
	clientMainPageItems: {
		prefix: 'client-main-page-items'
	},
	clientMenuItems: {
		prefix: 'client-menu-items'
	},
	clientPages: {
		prefix: 'client-pages'
	},
	clientSliderItems: {
		prefix: 'client-slider-items'
	},
	clientCategories: {
		prefix: 'client-categories'
	},

	contactUs: {
		prefix: "contact-us"
	},
	
	verboseResources: {
		prefix: "resources/VerboseResources"
	}
}
