import React from 'react';
import {ShowroomPageItem2} from "../../../component";

const Content = ({ payload, language }) => {
    return (
        <React.Fragment>
            {
                payload.map(item => <ShowroomPageItem2 {...item} language={language} /> )
            }
        </React.Fragment>
    );
}

export default Content;