import { ApiFetch }    		from '../FetchRequest';
import { ApiProtocolEnum } 	from '../enum/ApiProtocolEnum';
import CoreOdataServiceBase from './CoreOdataServiceBase';

export default class ReadOnlyOdataServiceBase extends CoreOdataServiceBase{

	/**
	 * Details
	 * @param key {string}
	 */
	async details(key) {
		return ApiFetch( {
			method: ApiProtocolEnum.GET,
			sourcePath: `${this._sourcePath}/${key}/details`,
		});
	}

}

