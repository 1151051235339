import React from 'react';
import MVBImage from "../../../component/MVBImage";
import ResourceTypeEnum from "../../../enum/ResourceTypeEnum";

const Content = ({ resources, languageVersion }) => {
    return (
        <React.Fragment>
            <div className="col-12 col-md-4 col-lg-3">
                <aside className="sidebar" style={{border: "1px solid #DADADA"}}>
                    <div className="widget widget--banner">
                        <MVBImage payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.INNER_IMAGE} className={"img-fluid"} />
                    </div>
                </aside>
            </div>

            <div className="col-12 my-6 d-md-none"/>

            <div className="col-12 col-md-8 col-lg-9">
                <div className="content-container">
                    <div className="posts">
                        <div className="__item ck-content" dangerouslySetInnerHTML={{__html: languageVersion?.body}}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Content;