import CoreActionBase from "../../../core/action/CoreActionBase";
import ClientMenuItemService from "../../service/ClientMenuItemService";
export { name } from '../../service/ClientMenuItemService'

export default class ClientMenuItemAction extends CoreActionBase {

    constructor( dispatch, pageName ) {
        super(dispatch, ClientMenuItemService, pageName);
    }

}