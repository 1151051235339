import React from "react";
import ContentLoader from "react-content-loader";

const phone = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"420px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="50" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="10" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="115" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="385" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="435" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="400" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="510" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="770" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="820" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="780" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="890" y="430" rx="3" ry="3" width="150" height="40" />
    </ContentLoader>
);

const tablet = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"420px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="50" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="10" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="115" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="385" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="435" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="400" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="510" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="770" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="820" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="780" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="890" y="430" rx="3" ry="3" width="150" height="40" />
    </ContentLoader>
);

const desktop = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"500px"}
        backgroundColor={isDarkMode ? "#e0e0e0": "#f3f3f3"}
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="50" />
        <rect x="940" y="0" rx="3" ry="3" width="200" height="50" />

        <rect x="0" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="50" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="10" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="115" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="385" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="435" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="400" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="510" y="430" rx="3" ry="3" width="150" height="40" />

        <rect x="770" y="100" rx="3" ry="3" width="370" height="200" />
        <rect x="820" y="310" rx="3" ry="3" width="270" height="30" />
        <rect x="780" y="345" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="365" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="385" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="405" rx="3" ry="3" width="350" height="15" />
        <rect x="890" y="430" rx="3" ry="3" width="150" height="40" />

    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @param isDarkMode { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop, isDarkMode = false} ) {

    if ( isPhone ) return phone(isDarkMode);
    else if ( isTablet ) return tablet(isDarkMode);
    else if ( isDesktop ) return desktop(isDarkMode);

}