import React from "react";

import Loader from './components/loader'

const INNR_001 = ({ isLoading, languageVersion }) => {
    return (
        <main role={"main"} style={{paddingTop: 0}}>
            <section className={"section"}>
                <div className={"container ck-content"}>
                    {
                        isLoading ?
                            <Loader isDesktop={true} /> :
                            <div className={"raw-html-embed"} dangerouslySetInnerHTML={{__html: languageVersion?.body}}/>
                    }
                </div>
            </section>
        </main>
    );
}

export default INNR_001;