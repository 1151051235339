import ServiceBase from "./ServiceBase";
import { ApiFetch }       	from '../FetchRequest';
import { ApiProtocolEnum }	from '../enum/ApiProtocolEnum';

export default class CoreOdataServiceBase extends ServiceBase {

	/**
	 * Get
	 * @param queryOptions { OdataQueryOptionsModel }
	 * @returns {AxiosPromise<any>}
	 */
	async get( queryOptions) {
		return ApiFetch( {
			method: ApiProtocolEnum.GET,
			sourcePath: this._sourcePath,
			queryOptions: queryOptions
		});
	}

}

