import {
    ResultStatusEnum
} from "../enum/ResultStatusEnum";


export default class ActionBaseWithDispatcher {

    /**
     * ActionBaseWithDispatcher
     * @param dispatch { any }
     * @param service { any }
     * @param pageName { string }
     */
    constructor ( dispatch, service, pageName ) {
        this.dispatch = dispatch;
        this.service = new service(dispatch);
        this.serviceName = this.service.name
        this.pageName = pageName;
    }

    
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callback200( response, args ) {}
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callback201( response, args ) {}
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callback400( response, args ) {}
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callback401( response, args ) {
        // this.dispatch([
        //     {
        //         type: ACCOUNT_LOGIN_FETCH_FAIL,
        //     },
        //     {
        //         type: CORE_LOADING_MESSAGE,
        //         payload: false
        //     }
        // ]);
    }
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callback406( response, args ) {}
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callbackOther( response, args ) {}
    /**
     *
     * @param response { Object }
     * @param args { Object }
     * @param args.actionType { string }
     */
    callbackError( response, args ) {
    }


    /**
     *
     * @param service { Function }
     * @param params { Object|null }
     * @param params.serviceData { Object|undefined }
     * @param params.args { Object }
     * @param params.args.actionType { string }
     */
    _run( service, params) {
        service( params?.serviceData )
            .then(response => {
                // console.log("ActionBaseWithDispatcher._run.params: ", response);
                const status = response.data?.status ? `d${response.data?.status}` : `h${response.status}`;
                switch (status) {

                        // from data
                    case `d${ResultStatusEnum.Success}`:
                    case `h${ResultStatusEnum.Success}`:
                        this.callback200(response.data, params?.args);
                        break;
                    case `d${ResultStatusEnum.Created}`:
                    case `h${ResultStatusEnum.Created}`:
                        this.callback201(response.data, params?.args);
                        break;
                    case `d${ResultStatusEnum.BadRequest}`:
                    case `h${ResultStatusEnum.BadRequest}`:
                        this.callback400(response.data, params?.args);
                        break;
                    case `d${ResultStatusEnum.Unauthorized}`:
                    case `h${ResultStatusEnum.Unauthorized}`:
                        this.callback401(response.data, params?.args);
                        break;
                    case `d${ResultStatusEnum.NotAcceptable}`:
                    case `h${ResultStatusEnum.NotAcceptable}`:
                        this.callback406(response.data, params?.args);
                        break;

                        //
                    default:
                        this.callbackOther(response, params?.args);

                }

                return Promise.resolve();
            })
            .catch(error => {

                console.error("ActionBaseWithDispatcher.error: ", JSON.stringify(error, null, 4));

                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.error(message);
                // notify({message: message, width:0, position: { at:"top right", of:"body", offset:"-182 112" }}, 'error', 10000);

                this.callbackError(message, params?.args);

                return Promise.resolve();
            });
    }

}
