import React from "react";

import Loader from './components/loader'
import Content from './components/content'

const INNR_002 = ({ isLoading, resources, languageVersion }) => {
    return (
        <main role={"main"} style={{paddingTop: 0}}>
            <section className={"section"}>
                <div className={"container"}>
                    <div className={"row"}>
                        {
                            isLoading ?
                                <Loader isDesktop={true} /> :
                                <Content languageVersion={languageVersion} resources={resources} />
                        }
                    </div>
                </div>
            </section>
        </main>
    );
}

export default INNR_002;