import {combineReducers} from "redux";
import CoreReducerBase, { initState as _initState } from './CoreReducerBase'
import {DELETE, DETAILS, LIST, MANAGEMENT, PATCH, POST} from "../type/ActionProtocolTypes";

export default function reducer( pageName, serviceName ) {
    return combineReducers({
        list: CoreReducerBase(pageName, serviceName, LIST),
        post: CoreReducerBase(pageName, serviceName, POST),
        patch: CoreReducerBase(pageName, serviceName, PATCH),
        management: CoreReducerBase(pageName, serviceName, MANAGEMENT),
        delete: CoreReducerBase(pageName, serviceName, DELETE),
        details: CoreReducerBase(pageName, serviceName, DETAILS),
    });
}

export const initState = {
    list: _initState,
    post: _initState,
    patch: _initState,
    management: _initState,
    delete: _initState,
    details: _initState,
}

export const initializer = value => { return value };