import {
    APP_LANGUAGE,
    APP_LOADING,
    CHANGE
} from "../../type/ActionProtocolTypes";

import {
    FETCHING,
    FETCH_SUCCESS,
    FETCH_FAIL,
    FETCH_CLEAN, TOGGLE
} from "../../type/ActionResultTypes";

export default class AppAction {

    /**
     * AppAction
     * @param dispatch { Function }
     */
    constructor( dispatch ) {
        this.dispatch = dispatch;
        this.pageName = "APP";
        this.serviceName = "APP"
    }

    /**
     * set app language
     * @param language { string }
     */
    changeLanguage ( language ) {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${CHANGE}${FETCH_SUCCESS}`,
            payload: language
        });
    }

    /**
     * set app language
     * @param language { object }
     */
    setLanguage ( language ) {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${APP_LANGUAGE}${FETCH_SUCCESS}`,
                payload: language
            });
    }

    /**
     * clean app language
     */
    cleanLanguage () {
        this.dispatch({ type: `${this.pageName}${this.serviceName}${APP_LANGUAGE}${FETCH_CLEAN}`,
            });
    }

    /**
     * set app loading
     * @param state { boolean }
     */
    setLoading ( state ) {
        this.dispatch(
            {
                type: `${this.pageName}${this.serviceName}${APP_LOADING}${FETCH_SUCCESS}`,
                payload: state
            }
        );
    }

    /**
     * toggle app loading
     */
    toggleLoading () {
        this.dispatch(
            {
                type: `${this.pageName}${this.serviceName}${APP_LOADING}${TOGGLE}`,
            }
        );
    }

    /**
     * clean app loading
     */
    cleanLoading () {
        this.dispatch(
            {
                type: `${this.pageName}${this.serviceName}${APP_LOADING}${FETCH_CLEAN}`,
            }
        );
    }

}