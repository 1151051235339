import React, {useEffect, useReducer, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
// component
import Loader from './components/loader'
import Content from './components/content'
// action
import ClientPagesAction, { name as clientPagesName } from "./actions/ClientPagesAction";
// reducer
import reducer, { initState, initializer } from '../../../core/reducer/SimpleReducer';

const SHWRM_002 = ({ verboseResources, currentLanguage, appIsLoading }) => {
    console.log("SHWRM_002.rendered: ", verboseResources);

    const history = useHistory();
    const location = useLocation();
    const { language, categoryName, pageName, categoryId, pageId } = useParams();

    //
    const [settings, setSettings] = useState({ itemsPerPage: 6, isPageLoaded: false, isLoadMore: false, isFind: false });
    const [filter, setFilter] = useState({ filter: [], order: ["name desc"] });
    const [items, setItems] = useState([]);

    // pages
    const [clientPagesState, clientPagesDispatch] = useReducer(new reducer("SHWRM_002", clientPagesName), initState, initializer);
    const clientPagesAction = new ClientPagesAction(clientPagesDispatch);

    useEffect(
        () => {
            console.log("SHWRM_002.useEffect1: ", categoryId, language, currentLanguage.twoLetterISOName, settings.isLoadMore);

            if ( currentLanguage.twoLetterISOName === language ) {
                clientPagesAction.get({
                    serviceData: {
                        filter: [
                            `categoryId eq ${categoryId}`, 
                            "and", "isActive eq true", 
                            "and", "languageVersion ne null",
                            ...filter.filter,
                        ],
                        orderBy: filter.order
                    },
                });
            } else {
                setSettings(prevProps => ({ ...prevProps, isPageLoaded: false }));
                setFilter(prevProps => ({ ...prevProps, filter: [] }));
                setItems([]);
            }
        },
        [categoryId, language, currentLanguage, filter]
    )

    useEffect(
        () => {
            console.log("SHWRM_002.useEffect2: ", categoryId, language, currentLanguage.twoLetterISOName, settings.isLoadMore);
            if ( currentLanguage.twoLetterISOName === language && settings.isLoadMore ) {
                clientPagesAction.get({
                    serviceData: {
                        filter: [
                            `categoryId eq ${categoryId}`, 
                            "and", "isActive eq true", 
                            "and", "languageVersion ne null",
                            ...filter.filter,
                        ],
                        orderBy: filter.order,
                        top: settings.itemsPerPage,
                        skip: settings.isFind ? 0 : items.length
                    },
                });
            }
        },
        [settings.isLoadMore]
    )

    useEffect(
        () => {
            console.log("SHWRM_002.useEffect3: ", clientPagesState.list.isLoading, clientPagesState.list.payload?.length, settings);
            if (clientPagesState.list.payload?.length >= 0) {
                if ( settings.isFind ) {
                    setItems(prevProps => ([ ...clientPagesState.list.payload ]));
                    setSettings(prevProps => ({ ...prevProps, isPageLoaded: true, isFind: false }));
                } else {
                    setItems(prevProps => ([ ...prevProps, ...clientPagesState.list.payload ]));
                    setSettings(prevProps => ({ ...prevProps, isPageLoaded: true, isLoadMore: false }));
                }
                clientPagesAction.cleanGet();
            }
        },
        [clientPagesState.list.isLoading]
    )

    return (
        <React.Fragment>
            <section className="section">
                <div className="container">
                    {
                        clientPagesState.list.isLoading && ( !settings.isPageLoaded || settings.isFind ) ?
                            <Loader isDesktop={true} /> :
                            <div className="gallery gallery--style-1">
                                <div className="__inner">
                                    <div className="row">
                                        <Content payload={items} 
                                                 language={language}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = function (state, props) {
    console.log("SHWRM_002.mapStateToProps", state, props);

    const currentLanguage = state.CoreReducer.AppReducer.appLanguage.payload;
    const appIsLoading = state.CoreReducer.AppReducer.appLoading.payload;

    const verboseResources = state.CoreReducer.VerboseResourcesReducer.list.payload;
    const verboseResourcesisLoading = state.CoreReducer.VerboseResourcesReducer.list.isLoading;

    return {
        currentLanguage,
        appIsLoading,

        verboseResources,
        verboseResourcesisLoading,
    }
}

const Component = React.memo(SHWRM_002, (prevProps, nextProps) => {
    console.log("---- SHWRM_002.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( 
        prevProps.currentLanguage.id !== nextProps.currentLanguage.id ||
        prevProps.verboseResourcesisLoading !== nextProps.verboseResourcesisLoading
       ) {
        console.log("SHWRM_002.doRender");
        return false;
    }

    console.log("SHWRM_002.doNotRender");
    return true;
})

export default connect(mapStateToProps, null)(Component);