import React from "react";

import {default as NB_001} from "../NavigationBarLayout1";
import {default as NB_002} from "../NavigationBarLayout2";

const Items = {
    NB_001,
    NB_002,
}

const NavigationBar = ({ layout }) => {
    const Component = Items[layout.layoutName];
    return Component ? <Component /> : null
}

export default NavigationBar;