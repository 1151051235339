import { combineReducers } from 'redux';
import ReducerBase from "../../ReducerBase";
import { name as serviceName } from '../../../service/resources/VerboseResourcesService'


export default function VerboseResourcesReducer( pageName ) {
    const baseReducer = new ReducerBase(pageName, serviceName);

    return combineReducers({
        ...baseReducer.LIST()
    });
}
