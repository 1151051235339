import React from 'react';

import {default as LNB_001} from "../LanguageNavigationBarLayout1";
import {default as LNB_002} from "../LanguageNavigationBarLayout2";

const Items = {
    LNB_001,
    LNB_002,
}

const LanguageNavigationBar = ({ layout }) => {
    const Component = Items[layout.layoutName];
    return Component ? <Component /> : null
}

export default LanguageNavigationBar;