import React, {useEffect, useReducer} from "react";
import {useParams} from "react-router-dom";
import Lodash from "lodash";
// component
import Loader from "./components/loader";
import Content from "./components/content";
// actions
import ClientMainPageItemsAction, { name as mainPageItemsServiceName } from "./actions/ClientMainPageItemsAction";
import ClientPagesAction, { name as pagesServiceName } from "./actions/ClientPagesAction";
// reducers
import reducer, {initializer, initState} from "../../../core/reducer/SimpleReducer";
// enums
import ContentTypeEnum from "../../enum/ContentTypeEnum";

export default function MNPG_002 ({ index, id, verboseResources, currentLanguage }) {
    console.log("MNPG_002.rendered");

    // constants
    const { language } = useParams();

    // mainPageItems
    const [mainPageItemsState, mainPageItemsDispatch] = useReducer(new reducer("MNPG_002", mainPageItemsServiceName), initState, initializer);
    const mainPageItemsAction = new ClientMainPageItemsAction(mainPageItemsDispatch);
    // categories
    // const [categoriesState, categoriesDispatch] = useReducer(new reducer("MNPG_002", categoriesServiceName), initState, initializer);
    // const categoriesAction = new ClientCategoriesAction(categoriesDispatch);
    // pages
    const [pagesState, pagesDispatch] = useReducer(new reducer("MNPG_002", pagesServiceName), initState, initializer);
    const pagesAction = new ClientPagesAction(pagesDispatch);

    useEffect(
        () => {
            console.log("MNPG_002.useEffect.1: ");
            if ( !Lodash.isEmpty(id) ) {
                mainPageItemsAction.details({ serviceData: id });
            }

            return function cleanup() {

            }
        },
        [ currentLanguage ]
    )

    useEffect(
        () => {
            console.log("MNPG_002.useEffect.2: ", mainPageItemsState);
            if ( mainPageItemsState.details?.payload?.contentTypeName === ContentTypeEnum.CATEGORY ) {
                pagesAction.get({
                    serviceData: {
                        filter: [`categoryId eq ${mainPageItemsState.details?.payload?.contentId}`, "and", "isActive eq true", "and", "languageVersion ne null"],
                        orderBy: ["issueDate desc"],
                        top: 4
                    }
                });
            }
        },
        [mainPageItemsState.details.isLoading]
    )

    return (
        <React.Fragment>
            {
                mainPageItemsState.details?.isLoading || pagesState.list?.isLoading ?
                    <Loader isDesktop={true} isDarkMode={index%2===1} /> :
                    <Content
                        {...mainPageItemsState.details?.payload}
                        language={language}
                        payload={pagesState.list?.payload}
                        verboseResources={verboseResources}
                    />
            }
        </React.Fragment>
    );

}