import React from "react";
import ContentLoader from "react-content-loader";

const phone = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="360" height="200" />
        <rect x="390" y="0" rx="3" ry="3" width="360" height="200" />
        <rect x="780" y="0" rx="3" ry="3" width="360" height="200" />
    </ContentLoader>
);

const tablet = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"40px"}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="360" height="200" />
        <rect x="390" y="0" rx="3" ry="3" width="360" height="200" />
        <rect x="780" y="0" rx="3" ry="3" width="360" height="200" />
    </ContentLoader>
);

const desktop = (isDarkMode) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={"400px"}
        backgroundColor={isDarkMode ? "#e0e0e0": "#f3f3f3"}
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="370" height="200" />
        <rect x="50" y="210" rx="3" ry="3" width="270" height="30" />
        <rect x="10" y="245" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="265" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="285" rx="3" ry="3" width="350" height="15" />
        <rect x="10" y="305" rx="3" ry="3" width="350" height="15" />
        <rect x="115" y="330" rx="3" ry="3" width="150" height="40" />

        <rect x="385" y="0" rx="3" ry="3" width="370" height="200" />
        <rect x="435" y="210" rx="3" ry="3" width="270" height="30" />
        <rect x="400" y="245" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="265" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="285" rx="3" ry="3" width="350" height="15" />
        <rect x="400" y="305" rx="3" ry="3" width="350" height="15" />
        <rect x="510" y="330" rx="3" ry="3" width="150" height="40" />

        <rect x="770" y="0" rx="3" ry="3" width="370" height="200" />
        <rect x="820" y="210" rx="3" ry="3" width="270" height="30" />
        <rect x="780" y="245" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="265" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="285" rx="3" ry="3" width="350" height="15" />
        <rect x="780" y="305" rx="3" ry="3" width="350" height="15" />
        <rect x="890" y="330" rx="3" ry="3" width="150" height="40" />
    </ContentLoader>
);

/**
 *
 * @param isPhone { boolean }
 * @param isTablet { boolean }
 * @param isDesktop { boolean }
 * @param isDarkMode { boolean }
 * @returns {JSX.Element}
 */
export default function ({isPhone, isTablet, isDesktop, isDarkMode = false} ) {

    if ( isPhone ) return phone(isDarkMode);
    else if ( isTablet ) return tablet(isDarkMode);
    else if ( isDesktop ) return desktop(isDarkMode);

}