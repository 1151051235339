import React from "react";
import PropTypes from "prop-types";
import MVBImage from "../MVBImage";
import MVBLink from "../MVBLink";
import ResourceTypeEnum from "../../enum/ResourceTypeEnum";
import DateTimeExtension from "../../extension/DateTimeExtension";

export default function ShowroomPageItem3 ({ id, categoryId, categoryRouteName, isNavigationDestination, resources, redirectType, issueDate, languageVersion, language, readMoreText }) {
    console.log("ShowroomPageItem3: ", readMoreText)
    const _date = DateTimeExtension(issueDate, language);

    return (
        <div className="col-12 col-sm-6 col-lg-4">
            <div className="__item __item--preview">
                <figure className="__image">
                    <MVBImage payload={resources} alt={languageVersion?.name} resourceTypeName={ResourceTypeEnum.OUTER_IMAGE} />
                </figure>

                <div className="__content">
                    <h3 className="__title h5">
                        <MVBLink
                            title={languageVersion?.name}
                            isNavigationDestination={isNavigationDestination}
                            redirectUrl={languageVersion?.redirectUrl}

                            redirectType={redirectType}

                            languageCode={language}
                            categoryId={categoryId}
                            categoryRouteName={categoryRouteName}
                            contentId={id}
                            contentRouteName={languageVersion?.routeName}
                        />
                    </h3>
                    <p className={"ck-content"} dangerouslySetInnerHTML={{__html: languageVersion?.description}} />
                    <MVBLink
                        title={readMoreText}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={categoryId}
                        categoryRouteName={categoryRouteName}
                        contentId={id}
                        contentRouteName={languageVersion?.routeName}
                        className={"custom-btn custom-btn--tiny custom-btn--style-1"}
                        isButton={true}
                    />
                </div>

                <span className="__date-post"><strong>{_date?.day}</strong>{_date?.monthName} ${_date?.year}</span>
            </div>
        </div>
    );

}

ShowroomPageItem3.propTypes = {
    isNavigationDestination: PropTypes.bool.isRequired,
    resources: PropTypes.array.isRequired,
    routeName: PropTypes.string,
    redirectType: PropTypes.string,
    issueDate: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    languageVersion: PropTypes.object.isRequired,
}

ShowroomPageItem3.defaultProps = {
    isNavigationDestination: false,
    resources: [],
    routeName: null,
    redirectType: null,
    language: null,
    languageVersion: null
}