import React, { createRef, useState, useEffect, useCallback, useReducer } from "react";
import Lodash from "lodash";
// actions
import ClientContentAction, { name as clientContentName } from "../actions/ClientContentAction";
// reducer
import reducer, { initState, initializer } from '../../../../core/reducer/SimpleReducer';
//
import Loader from './statisticLoader'
// static
import Highcharts from "highcharts";
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsReact from "highcharts-react-official";
import {CMSDefault} from "../../../CMSDefault";
import {CoreDefault} from "../../../../core/CoreDefault";
import { Link } from "react-router-dom";
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const Content = ({ resources, languageVersion, isLoading, language, currentLanguage, labelDimension, labelNoDataToShow, labelPleaseSelectToSeeData, labelClearAll, labelShowAll, labelLoading }) => {

    const [clientContentState, clientContentDispatch] = useReducer(new reducer("PAGE", clientContentName), initState, initializer);
    const clientContentAction = new ClientContentAction(clientContentDispatch);

    const refHighCharts = createRef();
    
	const [ options, setOptions ] = useState({
		disabled: true, 
		loading: true, 
		showMessage: false,
	});

    // dimension
    const [ dimensionPayload, setDimensionPayload ] = useState([]);
    const [ dimensionOptions, setDimensionOptions ] = useState([]);
    // dimensionType
    const [ dimensionTypePayload, setDimensionTypePayload ] = useState([]);
    const [ dimensionTypeOptions, setDimensionTypeOptions ] = useState([]);
    // value
    const [ statisticPayload, setStatisticPayload ] = useState([]);
    const [ dimension, setDimension ] = useState({});
	const [ dimensionId, setDimensionId ] = useState(null);
    const [ dimensionType, setDimensionType ] = useState({});
	const [ dimensionTypeId, setDimensionTypeId ] = useState(null);

    useEffect(
        () => {
            const _resource = resources?.find(item => item.name === `index-${currentLanguage?.twoLetterISOName}.json`);
            if ( _resource ) {
                clientContentAction.download({
                    serviceData: { url: `${CoreDefault.endpointRoutes.cdn}${_resource.path}` },
                })
            }

			return function cleanup () {
                clientContentAction.cleanDownload();
			}
        },
        [ ]
    )

	useEffect(
		() => {
            if ( Lodash.isEmpty(dimensionOptions) && !Lodash.isEmpty(clientContentState.list.payload) ) {
				try {
					let keys = Object.keys(clientContentState.list.payload);
					let dimensionOptions = keys.map(x => <option key={x} value={`${x}`}>{clientContentState.list.payload[x].name}</option>);
					dimensionOptions = [ <option key={"selectDimension"} value={""}>{labelPleaseSelectToSeeData}</option>, ...dimensionOptions ];
					setDimensionOptions(dimensionOptions);
                    setDimensionPayload(clientContentState.list.payload);
					clientContentAction.cleanDownload();
				} catch (e) {}
			} 
			else if ( !Lodash.isEmpty(clientContentState.list.payload) ) {
                try {
                    setStatisticPayload(clientContentState.list.payload);
					clientContentAction.cleanDownload();
				} catch (e) {}
            }
		},
		[ clientContentState.list.isLoading ]
	);

    const onDimensionChange = useCallback(
		(e) => {
			if ( e.target.value.length > 0 ) {
                const _dimension = dimensionPayload[e.target.value];
                setDimension(_dimension);
				setDimensionId(e.target.value);
				setDimensionType({});
				setDimensionTypeId("");
				setStatisticPayload([]);
				
                try {
					let keys = Object.keys(_dimension.value);
					let dimensionTypeOptions = keys.map(x => <option key={x} value={`${x}`}>{_dimension.value[x]}</option>);
					dimensionTypeOptions = [ <option key={"selectDimension"} value={""}>{labelPleaseSelectToSeeData}</option>, ...dimensionTypeOptions ];
					setDimensionTypeOptions(dimensionTypeOptions);
                    setDimensionTypePayload(_dimension.value);

					const dimension=document.getElementById("dimensionTypeSelect");
					dimension.value = "";
				} catch (e) {}

			    document.getElementById("dimensionType").classList.remove("d-none");
			}
		},
		[ dimensionPayload ]
	)

	const onDimensionTypeChange = useCallback(
		(e) => {
			setOptions(prevState => ({ ...prevState, disabled: true, loading: true, showMessage: false }));
			
			const _value = e.target.value;
			const _dimensionType = dimensionTypePayload[_value];
			setDimensionType(_dimensionType);
			setDimensionTypeId(_value);
			setStatisticPayload([]);

			const _resource = resources.find(x => x.name === `${dimensionId}-${_value}-${currentLanguage?.twoLetterISOName}.json`);
			if ( _resource ) {
				clientContentAction.download({
                    serviceData: { url: `${CoreDefault.endpointRoutes.cdn}${_resource.path}` },
                });
			}
		},
		[ resources, dimension, currentLanguage ]
	)

	const removeUnnecessaryLine = function () {

		const interval = setInterval(() => {
			const element = document.getElementsByClassName("highcharts-credits");
			if ( element?.length > 0 ) {
				element[0].remove();
				clearInterval(interval);
			}
		}, 500);

	}

	function toggle_chart_series( status ) {
		if ( refHighCharts.current ) {
			for(let i=0; i<refHighCharts.current.chart.series.length; i++) {
				if( status )
					refHighCharts.current.chart.series[i].show();
				else
					refHighCharts.current.chart.series[i].hide();
			}
		}
	}

	const renderData = useCallback(
		() => {
			if ( Lodash.isEmpty(statisticPayload) ) {
				return null;
			}

			removeUnnecessaryLine();

			return (
				<React.Fragment>
					<div className={"py-4"}>
						<Link className="custom-btn custom-btn--tiny custom-btn--style-1 mr-2" to={"#"} onClick={(e) => { e.preventDefault(); toggle_chart_series(false); }}>{labelClearAll}</Link>
						<Link className="custom-btn custom-btn--tiny custom-btn--style-1" to={"#"} onClick={(e) => { e.preventDefault(); toggle_chart_series(true); }}>{labelShowAll}</Link>
					</div>

					<div id="hc-container" className={"scrolling-container"}>
						<div id="chart-container" className={"chart-container"}>
							<HighchartsReact
								ref={refHighCharts}
								options={{
									chart: {
										type:"spline"
									},
									title: {
										text: statisticPayload['title'].replaceAll("/", "\\")
									},
									subtitle: {
										text: ''
									},
									yAxis: {
										title: {
											text: ['yAxis']['title']
										},
										gridLineWidth: 1
									},
									xAxis: {
										accessibility: {
											rangeDescription: statisticPayload['xAxis']['title']
										},
										type: "category",
										categories: statisticPayload['xAxis']['categories'],
										gridLineWidth: 1
									},
									loading: {
										hideDuration: 0,
										showDuration: 0,
										style: {
											backgroundColor: '#ffffff',
										}
									},
									exporting:{
										showTable: true
									},
									tooltip: {
										formatter: function() {
											let point = this.point,
												series = this.series,
												pointIndex = point.index,
												text,
												unit, flag_description;

											unit = series.userOptions.data[pointIndex][2];
											flag_description = series.userOptions.data[pointIndex][3];

											text = '<span style="color:' +
												this.color + '">\u25CF</span> ' +
												series.name +
												': <b>' +
												this.y + " " + unit+
												'</b><br/>';

											text += flag_description;

											return text;
										}
									},

									legend: {
										layout: 'vertical',
										itemMarginBottom:7,
										align: 'left',
										verticalAlign: 'middle',
										itemStyle: {
											fontSize: '14px',
										}
									},

									series: statisticPayload['series'],

									responsive: {
										rules: [{
											condition: {
												maxWidth: 500
											},
											chartOptions: {
												legend: {
													layout: 'horizontal',
													align: 'center',
													verticalAlign: 'bottom'
												}
											}
										}]
									}
								}}
								highcharts={Highcharts}
							/>
						</div>
					</div>
				</React.Fragment>
			);
		},
		[ statisticPayload ]
	);

    return (
		<React.Fragment>
            <div className="row">
                <div className="col-12 col-lg-12 col-xl-12 mb-6">
                    <div className="row">

                        <div id={"dimension"} className="col-md-4">
                            <label>{labelDimension}</label>
                            <select id="dimension" className="custom-select d-block w-100 eco-shadow" onChange={onDimensionChange}>
                                {
                                    dimensionOptions.length > 0 ? dimensionOptions : <option value="">{labelLoading}...</option>
                                }
                            </select>
                        </div>

                        <div id={"dimensionType"} className="col-md-6 d-none">
                            <label>{dimension.name}</label>
                            <select id="dimensionTypeSelect" className="custom-select d-block w-100 eco-shadow" onChange={onDimensionTypeChange}>
                                {
                                    dimensionTypeOptions.length > 0 ? dimensionTypeOptions : <option value="">{labelLoading}...</option>
                                }
                            </select>
                        </div>

                    </div>
                </div>
                <div className="col-12 col-lg-12 col-xl-12 mt-6">
                    {
                        (!Lodash.isEmpty(dimensionTypePayload) && clientContentState.list.isLoading && <div className={"text-center"}><Loader isDesktop={true} /></div>) || 
						((Lodash.isNaN(parseInt(dimensionId)) || Lodash.isNaN(parseInt(dimensionTypeId))) && <div className={"text-center"}><h5>{labelPleaseSelectToSeeData}</h5></div>) ||
						(Lodash.isEmpty(statisticPayload) && <div className={"text-center"}><h5>{labelNoDataToShow}</h5></div>)
                    }
                    {
						!Lodash.isEmpty(statisticPayload) && renderData()
					}
                </div>
            </div>
		</React.Fragment>
    );
}

export default Content;