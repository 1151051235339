import React from 'react';
import PropTypes from 'prop-types';

import Loader from './loader';

const Item = ({ isLoading, title, icon, content }) => (
    <React.Fragment>
        {
            isLoading ?
                <Loader isDesktop={true} /> : 
                <div className={"__item"}>
                    { icon }
                    <h4 className={"__title"}>{ title }</h4>
                    <p dangerouslySetInnerHTML={{__html: content}} />
                </div>
        }
    </React.Fragment>
)

Item.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    content: PropTypes.string.isRequired,
}

Item.defaultProps = {
}

export default Item;