import React from 'react';
import MVBLink from "../../../component/MVBLink";
import {OuterItem2} from "../../../component";

const Content = ({ categoryId, categoryRouteName, contentId, isNavigationDestination, redirectType, language, languageVersion, payload, verboseResources }) => {
    const showAllText = verboseResources?.find(item => item.key === "ShowAll")?.value;
    const readMoreText = verboseResources?.find(item => item.key === "ReadMore")?.value;

    return (
        <React.Fragment>
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                <div style={{flexGrow: 1}}>
                    <MVBLink
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                    >
                        <div className={"section-heading section-heading--left ck-content"}
                             dangerouslySetInnerHTML={{__html: `${languageVersion?.description}`}}/>
                    </MVBLink>
                </div>
                <div>
                    <MVBLink
                        title={showAllText}
                        isNavigationDestination={isNavigationDestination}
                        redirectUrl={languageVersion?.redirectUrl}

                        redirectType={redirectType}

                        languageCode={language}
                        categoryId={contentId}
                        categoryRouteName={languageVersion?.routeName}
                        className={"custom-btn custom-btn--medium custom-btn--style-2"}
                    />
                </div>
            </div>

            <div className="posts posts--style-1">
                <div className="__inner">
                    <div className="row">
                        <OuterItem2 {...payload?.[0]} language={language} readMoreText={readMoreText} />
                        <OuterItem2 {...payload?.[1]} language={language} readMoreText={readMoreText} />
                        <OuterItem2 {...payload?.[2]} language={language} readMoreText={readMoreText} />
                        <OuterItem2 {...payload?.[3]} language={language} readMoreText={readMoreText} />
                        <OuterItem2 {...payload?.[4]} language={language} readMoreText={readMoreText} />
                        <OuterItem2 {...payload?.[5]} language={language} readMoreText={readMoreText} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Content;