import React from "react";
import SocialMediaLoader from './socialMediaLoader';
import {FaInstagram, FaTwitter, FaFacebook, FaLinkedin, FaYoutube} from "react-icons/fa"

const SocialMediaItems = {
    "Twitter": ( link ) => <a key={"twitter"} href={link} target={"_blank"}><FaTwitter /></a>,
    "Facebook": ( link ) => <a key={"facebook"} href={link} target={"_blank"}><FaFacebook /></a>,
    "LinkedIn": ( link ) => <a key={"linkedin"} href={link} target={"_blank"}><FaLinkedin /></a>,
    "Instagram": ( link ) => <a key={"instagram"} href={link} target={"_blank"}><FaInstagram /></a>,
    "YouTube": ( link ) => <a key={"youtube"} href={link} target={"_blank"}><FaYoutube /></a>,
}

const SocialMediaLinks = ({ payload, isLoading }) => (
    <React.Fragment>
        <div className={"social-btns"} >
            {
                isLoading ?
                    <SocialMediaLoader isDesktop={true} /> :
                    payload?.map(x => SocialMediaItems[x.typeName](x.link))
            }
        </div>
    </React.Fragment>
)

export default SocialMediaLinks;