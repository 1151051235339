import { combineReducers } from 'redux';
import ReducerBase from "../../../../core/reducer/ReducerBase";
import { name as serviceName } from '../../../service/setting/SocialMediaService'


export default function SocialMediaReducer( pageName ) {
    const baseReducer = new ReducerBase(pageName, serviceName);

    return combineReducers({
        ...baseReducer.LIST()
    });
}
