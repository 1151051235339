import { combineReducers } from "redux";

import AppReducer from "./app/AppReducer";
import LanguageReducer from "./app/LanguageReducer";
import VerboseResourcesReducer from "./app/VerboseResourcesReducer";

export default combineReducers({
    AppReducer,
    ...LanguageReducer,
    ...VerboseResourcesReducer
});
