import React from 'react';
import LogoLoader from './LogoLoader'
import PropTypes from "prop-types";

const Logo = ({ payload, alt, isLoading, language }) => {
    console.log("Logo: ", isLoading, payload, alt);
    return (
        <React.Fragment>
            {
                isLoading ?
                    <LogoLoader isDesktop={true} /> :
                    <a style={{float: "left"}} className={"top-bar__logo site-logo"} href={`/${language}`}>
                        <img className={"img-fluid"} src={payload} alt={alt} />
                    </a>
            }
        </React.Fragment>
    );
}

Logo.propTypes = {
    payload: PropTypes.string.isRequired,
    alt: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
}

Logo.defaultProps = {
    payload: "",
    alt: "",
    isLoading: true
}

export default Logo;