// default
export const LIST                   = "LI";
export const POST                   = "PO";
export const PATCH                  = "PA";
export const MANAGEMENT             = "MA";
export const DELETE                 = "DE";
export const DETAILS                = "DT";
export const ACTION_SPECIFICATIONS  = "AS";

// others
export const APP_LANGUAGE           = "ALA";
export const APP_LOADING            = "ALO";
export const DOWNLOAD               = "DL"
export const UPLOAD                 = "UL"

//
export const CHANGE                 = "CH"