import React from 'react';
import {connect} from "react-redux";
import Content from './components/content';
import Loader from './components/loader';
import Lodash from "lodash";
import {CMSDefault} from "../../CMSDefault";


const FooterLinks = ({ language, title, payload, isLoading }) => (
    <React.Fragment>
        <div className={"col-6 col-sm-8 text-right"}>
            {
                isLoading ?
                    <Loader isDesktop={true} /> :
                    <Content language={language} title={title} payload={payload} />
            }
        </div>
    </React.Fragment>
);


const mapStateToProps = function (state, props) {
    console.log("FooterLinks.mapStateToProps: ", props);

    const language = state.CoreReducer.AppReducer.appLanguage.payload?.twoLetterISOName;

    const payload = state.CMSReducer.ClientMenuItemsReducer.list.payload?.filter(x => x.parentId !== null && x.mapLayoutName === "MNGRP_002" && x.languageVersion !== null );
    const _titlePayload = state.CMSReducer.ClientMenuItemsReducer.list.payload?.find(x=>x.mapLayoutName === "MNGRP_002" && x.parentId === null);
    const title = _titlePayload?.languageVersion?.name;

    const isLoading = state.CMSReducer.ClientMenuItemsReducer.list.isLoading;

    return {
        language,

        title,
        payload,
        isLoading
    }
}

const Component = React.memo(FooterLinks, (prevProps, nextProps) => {
    console.log("---- FooterLinks.React.memo ----\n", "prevProps: ", prevProps, "nextProps: ", nextProps);

    if ( prevProps.isLoading !== nextProps.isLoading) {
        console.log("FooterLinks.doRender");
        return false;
    }

    console.log("FooterLinks.doNotRender");
    return true;
});

export default connect(mapStateToProps, null)(Component);