import React from "react";
import PropTypes from "prop-types";


const SubNavigationBar = ({ Component, payload, items, language, onNlick }) => {
    console.log("SubNavigationBar.rendered", Component, payload, items, language);

    return (
        <React.Fragment>
            <Component payload={payload} items={items} language={language} onNlick={onNlick} />
        </React.Fragment>
    );

}

SubNavigationBar.propTypes = {
    Component: PropTypes.func.isRequired,
    payload: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired
}

SubNavigationBar.defaultProps = {
    Component: null,
    payload: [],
    items: [],
}

export default SubNavigationBar;